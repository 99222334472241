import React from "react";
import { Input, Table } from "antd";
export default function KeyValue({ headers = [], updateHeader }) {
  const columns = [
    {
      title: 'Parameter',
      key: 'parameter',
      render: (val, record, index) => (
        <Input
          size='small'
          value={record.headerKeyName}
          onChange={event => {
            updateHeader(index, event.target.value, null);
          }}
        />
      )
    },
    {
      title: 'Value',
      key: 'value',
      render: (val, record, index) => (
        <Input
          size='small'
          value={record.headerValue}
          onChange={event => {
            updateHeader(index, null, event.target.value);
          }}
        />
      )
    },
  ]
  let dataSource = Array.isArray(headers) ? headers : []
  return (
    <Table
      bordered
      size='small'
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  );
}
