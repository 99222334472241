import React from 'react'

import { v4 as uuid } from 'uuid'


let data=[{
    "enableInboundHook": true,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
      "additionalProp1": true,
      "additionalProp2": true,
      "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string"
  },
  {
    "enableInboundHook": false,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
      "additionalProp1": true,
      "additionalProp2": true,
      "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string2"
  },
  {
    "enableInboundHook": true,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
      "additionalProp1": true,
      "additionalProp2": true,
      "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string3"
  },
  {
    "enableInboundHook": false,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
      "additionalProp1": true,
      "additionalProp2": true,
      "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string4"
  }]
  export default data