import axios from "axios";
import moment from 'moment'
import headers, { associate } from "../api/Headers";
import {
    getBusinessCapabilityListUrl,
    addBusinessCapabilityUrl,
    updateBusinessCapability,
    getBusinessCapabilityById,
    deleteCapabilityURL,
    getAllBsnsInitiativesForBsnsCapability,
    getAllInitiativeForCapability,
    deAssociateBsnsCapabilityToBsnsInitiative,
    getAllFeaturesForCapability,
    getAllApplicationForCapability,
    deAssociateBsnsApplicationToCapability

} from '../api/urls';
import { notification } from "antd";

export const getBusinessCapabilityList = (clientId) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_CAPABILITY_SHOW_LOADER' });
    await axios
        .get(getBusinessCapabilityListUrl(clientId), { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
            dispatch({
                type: 'BUSINESS_CAPABILITY_LIST',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
        });
};
export const addBussinessCapability = (inputData) => async (dispatch) => {
    console.log(inputData)
    dispatch({ type: 'BUSINESS_CAPABILITY_SHOW_LOADER' });
    dispatch({ type: 'BUSINESS_CAPABILITY_ERROR' });

    await axios
        .post(addBusinessCapabilityUrl(), inputData, { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
            dispatch({ type: 'BUSINESS_CAPABILITY_SUCCESS' });

            notification.success({
                message: 'Successfully added business capabilities.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'BUSINESS_CAPABILITY_ERROR' });
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
        });
};
export const getBussinessCapabilityById = (businessCapabilityId) => async (dispatch) => {
    // dispatch({ type: 'BUSINESS_CAPABILITY_SHOW_LOADER' });
    await axios
        .get(getBusinessCapabilityById(businessCapabilityId), { Headers })
        .then(function (data) {

            // dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
            dispatch({
                type: 'SET_BUSINESS_CAPABILITY',
                payload: data.data
            });
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            // dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
        });
};
export const updateBussinessCapability = (inputData) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_CAPABILITY_SHOW_LOADER' });
    await axios
        .post(updateBusinessCapability(), inputData, { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
            notification.success({
                message: 'Successfully updated business capability.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
        });
};
export const deleteCapability = (inputdata) => async (dispatch) => {

    dispatch({ type: 'BUSINESS_CAPABILITY_SHOW_LOADER' });

    await axios
        .delete(deleteCapabilityURL() + inputdata, { Headers })
        .then(function (response) {
            if (response.data) {
                notification.success({
                    message: 'Deleted Successfully'
                })
                // toast.success("Successfully deleted web service.");
            } else {
                notification.error({
                    message: 'Error while Deleting'
                })
                return
            }
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
            dispatch(getBusinessCapabilityList(1));
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            notification.error({
                message: 'Error while Deleting'
            })
            dispatch({ type: 'BUSINESS_CAPABILITY_HIDE_LOADER' });
        });
};
export const getAllBsnsInitiativesForBsnsCapabilityList = (id) => async (dispatch) => {
    await axios
        .get(getAllBsnsInitiativesForBsnsCapability(id), { headers })
        .then(function (data) {
            dispatch({
                type: 'BUSINESS_INITIATIVE_FOR_CAPABILITY',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
        });
};
export const getAllFeaturesForCapabilityList = (id) => async (dispatch) => {
    console.log(id)
    await axios
        .get(getAllFeaturesForCapability(id), { Headers })
        .then(function (data) {
            dispatch({
                type: 'BUSINESS_FEATURE_FOR_CAPABILITY',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
        });
};
export const associateApplicationToBsnsCapability = (id, key) => async (dispatch) => {
    await axios
        .post(getAllApplicationForCapability(id), key, { headers: associate })
        .then(function (data) {
            dispatch(getBussinessCapabilityById(id))

            notification.success({
                message: 'Successfully Associated.'
            })
        })
        .catch(function (error) {
            notification.success({
                message: 'error'
            })
        });

    
    // getBussinessCapabilityById(id)

};
export const associateInitiativeToBsnsCapability = (id, key) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_CAPABILITY_ERROR' });

    await axios
        .post(getAllInitiativeForCapability(id), key, { headers: associate })
        .then(function (data) {
            dispatch(getAllBsnsInitiativesForBsnsCapabilityList(id));

            notification.success({
                message: 'Successfully Associated.'
            })
            dispatch({ type: 'BUSINESS_CAPABILITY_SUCCESS' });

        })
        .catch(function (error) {
            notification.error({
                message: 'error'
            })
            dispatch({ type: 'BUSINESS_CAPABILITY_ERROR' });

            console.log(error)
        });
    // getBussinessCapabilityById(id)

};
export const deAssociateBsnsCapabilityToBsnsInitiativeFxn = (id, inputdata) => async (dispatch) => {

    await axios
        .post(deAssociateBsnsCapabilityToBsnsInitiative(id), inputdata, { headers: associate })
        .then(function (response) {
            dispatch(getAllBsnsInitiativesForBsnsCapabilityList(id));
            if (response.data) {
                notification.success({
                    message: 'Deassociated Successfully'
                })
                dispatch({ type: 'BUSINESS_CAPABILITY_SUCCESS' });
                // toast.success("Successfully deleted web service.");
            } else {
                notification.error({
                    message: 'Error while Deassociating'
                })
                return
            }
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            notification.error({
                message: 'Error while Deassociating'
            })
        });
};
export const deAssociateApplicationToBsnsCapbility = (id, inputdata) => async (dispatch) => {

    await axios
        .post(deAssociateBsnsApplicationToCapability(id), inputdata, { headers: associate })
        .then(function (response) {
            dispatch(getBussinessCapabilityById(id));
            if (response.data) {
                notification.success({
                    message: 'Deassociated Successfully'
                })
                dispatch({ type: 'BUSINESS_CAPABILITY_SUCCESS' });
                // toast.success("Successfully deleted web service.");
            } else {
                notification.error({
                    message: 'Error while Deassociating'
                })
                return
            }
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            notification.error({
                message: 'Error while Deassociating'
            })
        });
};