import React, { PureComponent, useEffect, useState } from 'react'
import {
    Button,
    Card,
    Tag,
    Menu,
    Checkbox,
    Dropdown
} from 'antd'
import async from 'async'
import styles from './styles.less'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { FlowChartWithState } from "@mrblenny/react-flow-chart"
import { timeStampFormat } from '../../settings'
import { sample } from './sample.js'

export default function FlowChart(props) {
    const dispatch = useDispatch()

    // const { flowChartLoader } = useSelector(
    //     (state) => ({
    //         flowChartLoader: state.flowChart.flowChartLoader
    //     })
    // );
    useEffect(() => {
        // dispatch(getAllBusinessInitiative(1))
    }, [])

    return (
        <FlowChartWithState config={{ readonly: true }} initialValue={sample} />
    )
}
