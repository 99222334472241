import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Button, Popconfirm, Form } from 'antd';

const EditableContext = React.createContext();

export const EditableFormRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async e => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

// import React, { PureComponent } from 'react'
// import moment from 'moment'
// import { Table, Input, DatePicker, Popconfirm, Form } from 'antd';

// const EditableContext = React.createContext();

// const EditableRow = ({ form, index, ...props }) => (
//     <EditableContext.Provider value={form}>
//         <tr {...props} />
//     </EditableContext.Provider>
// );

// export const EditableFormRow = Form.create()(EditableRow);

// export class EditableCell extends React.Component {
//     state = {
//         editing: false,
//     };

//     toggleEdit = () => {
//         const editing = !this.state.editing;
//         this.setState({ editing }, () => {
//             if (editing) {
//                 this.input.focus();
//             }
//         });
//     };

//     save = e => {
//         const { record, handleSave } = this.props;
//         this.form.validateFields((error, values) => {
//             if (error && error[e.currentTarget.id]) {
//                 return;
//             }
//             this.toggleEdit();
//             handleSave({ ...record, ...values });
//         });
//     };

//     renderCell = form => {
//         this.form = form;
//         const { children, dataIndex, record, title } = this.props;
//         const { editing } = this.state;
//         return editing ? (<>
//             <Form.Item style={{ margin: 0, padding: 0 }}>
//                 {form.getFieldDecorator(dataIndex, {
//                     initialValue: record[dataIndex],
//                 })(
//                     <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
//                 )}
//             </Form.Item>
//         </>
//         ) : (
//                 <div
//                     className="editable-cell-value-wrap"
//                     style={{ paddingRight: 24 }}
//                     onClick={this.toggleEdit}
//                 >
//                     {children}
//                 </div>
//             );
//     };

//     render() {
//         const {
//             editable,
//             dataIndex,
//             title,
//             record,
//             index,
//             handleSave,
//             children,
//             ...restProps
//         } = this.props;
//         return (
//             <td {...restProps}>
//                 {editable ? (
//                     <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
//                 ) : (
//                         children
//                     )}
//             </td>
//         );
//     }
// }

