import React, { useEffect, useState } from 'react';
import { Icon, Button, Card, Tag, DatePicker } from 'antd';
import formatBytes from '../../components/_utils/formBytes.js';
import BulkUpload from '../../components/BulkUpload';
import styles from './styles.less';
import _ from 'lodash';
import moment from 'moment';
import { TableComp } from 'sz-react-utils4';
import { timeStampFormat } from '../../settings';
import 'ant-design-pro/dist/ant-design-pro.css'; // Import whole style
import sizeof from 'object-sizeof';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWSRequestHistoryForEnv,
  uploadBulkRequest
} from '../actions/environmentActions';

const { RangePicker } = DatePicker;

function WebHistory() {
  const [state, setState] = useState({
    searchText: '',
    searchInput: '',
    dateFilters: {},
    showBulkUploadModal: false
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const toggleBulkUploadModal = () => {
    setState({ ...state, showBulkUploadModal: !state.showBulkUploadModal });
  };
  const uploadBulk = (data) => {
    console.log('uploadBulk', data);
    dispatch(uploadBulkRequest(data));
  };

  const { environmentHistory, loading } = useSelector((state) => ({
    environmentHistory: state.environment.environmentHistory,
    loading: state.environment.environmentLoader
  }));
  const { rmiWSEnvInfos, rmiwsInfo } = environmentHistory;

  useEffect(() => {
    dispatch(getWSRequestHistoryForEnv(location.pathname.split('/')[2]));
    // handleSearch(selectedKeys, confirm)
  }, [location]);

  const getColumnDateSearchProps = (dataIndex) => ({
    filterDropdown: (pro) => {
      let { setSelectedKeys, selectedKeys, confirm, clearFilters } = pro;
      return (
        <div
          style={{
            padding: '8px',
            borderRadius: '4px',
            backgroundColor: '#ffffff',
            boxShadow: '0 2px 8px rgba(0, 0, 0, .15)'
          }}>
          <RangePicker
            className={`search-value-${dataIndex}`}
            style={{ width: 250, marginBottom: 8, display: 'block' }}
            onChange={(date) => {
              setSelectedKeys({
                $gte: date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                $lt: date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
              });
            }}
          />

          <div style={{ flex: 1, justifyContent: 'flex-end' }}>
            <Button
              className={`search-${dataIndex}`}
              type="primary"
              onClick={() => {
                let dateFilters = _.clone(state.dateFilters);
                console.log(state.dateFilters);

                dateFilters[dataIndex] = true;
                console.log(dataIndex, state.dateFilters);
                setState({ ...state, dateFilters }, () => {
                  console.log(dateFilters);
                  handleSearch(selectedKeys, confirm);
                  confirm();
                });
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}>
              Search
            </Button>
            <Button
              className={`reset-${dataIndex}`}
              onClick={() => {
                let dateFilters = _.clone(state.dateFilters);

                dateFilters[dataIndex] = false;

                this.setState({
                  dateFilters
                });
                clearFilters();
              }}
              size="small"
              style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: (x) => {
      let { dateFilters } = state;
      let filtered = dateFilters && dateFilters[dataIndex];
      return (
        <Icon
          className={`filter-menu-${dataIndex}`}
          type="search"
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      );
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.focus())
      }
    }
  });
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState({ ...state, searchText: selectedKeys[0] });
  };

  // const { dispatch } = this.props
  const badgeColors = [
    { type: 'GET', color: 'green' },
    { type: 'POST', color: 'blue' },
    { type: 'PUT', color: 'purple' },
    { type: 'DELETE', color: 'red' },
    { type: 'PURGE', color: 'volcano' },
    { type: 'COPY', color: 'geekblue' },
    { type: 'OPTIONS', color: 'gold' },
    { type: 'VIEW', color: 'magenta' }
  ];

  const tableDataColoumns = [
    {
      title: 'Request Date',
      key: 'requestDate',
      dataIndex: 'requestDate',
      render: (val, record) => {
        return <Tag>{moment(val).format(timeStampFormat)}</Tag>;
      },
      ...getColumnDateSearchProps('requestDate')

      // fixed: 'left'
    },

    {
      title: 'Response Date',
      key: 'responseDate',
      dataIndex: 'responseDate',
      render: (val, record) => {
        return <Tag>{moment(val).format(timeStampFormat)}</Tag>;
      },
      ...getColumnDateSearchProps('responseDate')

      // fixed: 'left'
    },
    {
      title: 'Status',
      key: 'responseStatusCode',
      dataIndex: 'responseStatusCode',
      render: (val) => {
        return val === '200' ? (
          <Tag icon={<Icon type="checked" color="green" />} color="green">
            {val}-OK
          </Tag>
        ) : (
          <Tag icon={<Icon type="checked" color="red" />} color="red">
            {val}-NOT OK
          </Tag>
        );
      },
      filters: [
        {
          text: '200-OK',
          value: '200-OK'
        },
        {
          text: '400-NOT-OK',
          value: '400-NOT-OK'
        }
      ],
      onFilter: (value, record) => {
        return record.rmiWSBodyType.bodyTypeName.indexOf(value) === 0;
      }
    },
    {
      title: 'Duration',
      key: 'duration',
      dataIndex: 'duration',
      render: (val, record) => {
        const requestDate = moment(record.requestDate);
        const responseDate = moment(record.requestDate);

        return responseDate.diff(requestDate);
      }
    },
    {
      title: 'Request Size',
      key: 'requestSize',
      dataIndex: 'requestSize',
      render: (val, record) => {
        return formatBytes(
          sizeof(record.requestData) +
            sizeof(record.requestURL) +
            sizeof(record.rmiWSEnvRequestHeaderList)
        );
      }
    },
    {
      title: 'Response Size',
      key: 'responseSize',
      dataIndex: 'responseSize',
      render: (val, record) => {
        return formatBytes(sizeof(record.responseData));
      }
    }
  ];
  const renderBodyType = (badgeColors, bodyType) => {
    console.log('call');
    let index = _.findIndex(badgeColors, (item) => item.type === bodyType);
    return <Tag color={badgeColors[index].color}>{bodyType}</Tag>;
  };
  return (
    <Card
      id={'webHistory'}
      title={
        rmiwsInfo &&
        rmiwsInfo.wsName && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {rmiwsInfo.wsName} Data History{' '}
              {rmiwsInfo &&
                rmiwsInfo.rmiWSOperationType &&
                rmiwsInfo.rmiWSOperationType.operationName &&
                renderBodyType(
                  badgeColors,
                  rmiwsInfo.rmiWSOperationType.operationName
                )}
              ({rmiWSEnvInfos && rmiWSEnvInfos.length}){' '}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: '30%'
              }}>
              <Button type="primary" onClick={() => toggleBulkUploadModal()}>
                {'Bulk Upload Request'}
              </Button>
              <Button type="primary">{'New Request'}</Button>
              {/* <Button>{'Save and Close'}</Button> */}
            </div>
          </div>
        )
      }>
      <TableComp
        columns={tableDataColoumns}
        bordered
        dataSource={rmiWSEnvInfos}
        loading={loading}
        pagination={{
          pageSizeOptions: ['10', '25', '50', '100', '500'],
          showSizeChanger: true
        }}
      />
      <BulkUpload
        show={state.showBulkUploadModal}
        toggle={toggleBulkUploadModal}
        uploadBulkRequest={uploadBulk}
      />
    </Card>
  );
}

export default WebHistory;
