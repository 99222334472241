import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Tabs,
  Modal,
  Input,
  Space,
  Form,
  Tag,
  Card,
  Upload,
  message
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

// import AuthenticationTypes from "./AuthenticationTypes";
import Body from './Body';
import Headers from './Headers';
import Parameters from './Parameters';
import getStatus from './Status';
import './WebServiceTester.less';
const { TabPane } = Tabs;
export default function WebServiceTester({
  isOpen,
  className,
  selectedWS,
  metaData: { bodyTypes },
  request,
  handleRequestChange,
  toggleModal,
  isUrlEditable,
  webServiceLoader,
  response,
  clearResponse,
  clearAll,
  testWebService
}) {
  const [form] = Form.useForm();

  const updateHeader = (index, newKey, newValue) => {
    const headersArr = Array.from(request.rmiWSEnvRequestHeaderList);
    if (newKey === null) {
      headersArr[index].headerValue = newValue;
    } else {
      headersArr[index].headerKey = newKey;
    }
    handleRequestChange({ rmiWSEnvRequestHeaderList: headersArr });
  };

  const removeHeader = (index, newKey, newValue) => {
    let headersArr = Array.from(request.rmiWSEnvRequestHeaderList);
    headersArr = headersArr.filter(
      (header) => header.headerKey !== headersArr[index].headerKey
    );
    handleRequestChange({ rmiWSEnvRequestHeaderList: headersArr });
  };

  const testWebServiceInstance = async () => {
    try {
      const data = await form.validateFields();

      if (!data.errorFields) {
        let req = {
          envName: '',
          requestURL: '',
          requestData: 'string',
          rmiWSEnvRequestHeaderList: [],
          rmiWSEnvRequestAuthenticationList: [],
          rmiWSInfo: selectedWS,
          parameters: {}
        };
        testWebService({
          ...req,
          ...request
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const setBodyType = (bodyType) => {
    handleRequestChange({
      bodyType: bodyType.bodyTypeName
    });
  };

  const displayResponse = (response) => {
    if ((!request.id || !request.envId) && response && response.data) {
      handleRequestChange({
        id: response.data.id,
        envId: response.data.envId
      });
    }
    try {
      if (response && response.data && response.data.responseData) {
        return JSON.stringify(JSON.parse(response.data.responseData), null, 2);
      } else {
        return '';
      }
    } catch (error) {
      console.log('error', error);
      return `Some thing went wrong.\n  ${response.data.responseData}`;
    }
  };
  const changeURL = (requestURL, fromParams) => {
    if (
      fromParams &&
      (!requestURL ||
        !requestURL.requestURL ||
        requestURL.requestURL.split('?').length !== 2)
    ) {
      return;
    }
    handleRequestChange(requestURL);
  };
  const title = (
    <Space>
      {[
        selectedWS ? selectedWS.wsName : '',
        <Tag color="success">
          {selectedWS && selectedWS.rmiWSOperationType
            ? selectedWS.rmiWSOperationType.operationName
            : ''}
        </Tag>
      ]}
    </Space>
  );

  const uploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  form.setFieldsValue({ ...request });
  return (
    <Modal
      visible={isOpen}
      className={className}
      title={title}
      width={'70%'}
      onCancel={toggleModal}
      footer={false}
      forceRender={true}>
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <Space direction="vertical">
          <Form form={form}>
            <Row>
              <Col span={4}>
                {' '}
                <Form.Item
                  name="envName"
                  rules={[{ required: true, message: 'Please input a value' }]}>
                  <Input
                    placeholder="Environment Name"
                    value={request.envName}
                    disabled={!isUrlEditable}
                    onChange={(event) => {
                      handleRequestChange({ envName: event.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="requestURL"
                  rules={[{ required: true, message: 'Please input a value' }]}>
                  <Input
                    placeholder="URL"
                    disabled={!isUrlEditable}
                    value={request.requestURL}
                    onChange={(event) => {
                      changeURL({ requestURL: event.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Input
                    type="text"
                    id="healthcheckURL"
                    placeholder="Enter Healthcheck URL"
                    value={request.healthcheckURL}
                    onChange={(event) =>
                      handleRequestChange({
                        healthcheckURL: event.target.value
                      })
                    }
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="parameters"
              type="card"
              className="card-container">
              <TabPane tab="parameters" key="parameters" forceRender={true}>
                <Card bordered>
                  <Parameters
                    request={request}
                    handleRequestChange={changeURL}
                  />
                </Card>
              </TabPane>
              <TabPane tab="headers" key="headers">
                <Card bordered>
                  <Headers
                    headers={request.rmiWSEnvRequestHeaderList}
                    updateHeader={updateHeader}
                    removeHeader={removeHeader}
                  />
                </Card>
              </TabPane>
              {bodyTypes && (
                <TabPane tab="body" key="body">
                  <Card bordered>
                    <Body
                      bodyTypes={bodyTypes}
                      setBodyType={setBodyType}
                      request={request}
                      handleRequestChange={handleRequestChange}
                    />
                  </Card>
                </TabPane>
              )}
            </Tabs>
            <div style={{ textAlign: 'right' }}>
              <Space>
                <Upload {...uploadProps}>
                  <Button style={{ marginRight: '10px' }}>
                    <UploadOutlined /> Add certificate
                  </Button>
                </Upload>
              </Space>
              <Space>
                <Button type="primary" onClick={() => testWebServiceInstance()}>
                  Test
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    clearAll();
                  }}>
                  Clear All
                </Button>
                <Button onClick={() => clearResponse()}>Clear Response</Button>
                <Button onClick={() => toggleModal()} danger>
                  Exit
                </Button>
              </Space>
            </div>
          </Form>

          <Card
            size="small"
            title={
              <strong>
                Response :{' '}
                {response && response.data && response.data.responseStatusCode
                  ? getStatus(response.data.responseStatusCode)
                  : ''}
              </strong>
            }
            loading={webServiceLoader}>
            <div
              className={`alert response-container ${
                response.status === 200 ? `alert-success` : `alert-danger`
              }`}
              role="alert">
              <pre>{displayResponse(response)}</pre>
            </div>
          </Card>
        </Space>
      </div>
    </Modal>
  );
}
