import React, { PureComponent, useEffect, useState } from 'react'
import {
    Button,
    Card,
    Tag,
    Menu,
    Checkbox,
    Dropdown
} from 'antd'
import async from 'async'
import { getPushPathWrapper } from '../../routes'
import styles from './styles.less'
import _ from 'lodash'
import moment from 'moment'
import {
    SettingOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAllBusinessInitiative,
    deleteBusinessInitiative
} from '../actions/businessInitiativeAction';
import { timeStampFormat } from '../../settings'
import { TableComp } from 'sz-react-utils4'

export default function AllBusinessInitiative(props) {
    const dispatch = useDispatch()

    const { businessInitiativeList, loading } = useSelector(
        (state) => ({
            businessInitiativeList: state.businessInitiative.businessInitiativeList,
            loading: state.businessInitiative.initiativeLoader
        })
    );

    const [tableData, setMainState] = useState([])

    useEffect(() => {
        dispatch(getAllBusinessInitiative(1))
    }, [])

    useEffect(() => {
        let data = []
        async.forEach(businessInitiativeList, (doc, cb) => {
            if (!doc.expiryDate) {
                data.push(doc)
            }
            cb()
        }, () => {
            setMainState(data)
        })

    }, [businessInitiativeList])

    const badgeColors = [
        { type: 'GET', color: 'green' },
        { type: 'POST', color: 'blue' },
        { type: 'PUT', color: 'purple' },
        { type: 'DELETE', color: 'red' },
        { type: 'PURGE', color: 'volcano' },
        { type: 'COPY', color: 'geekblue' },
        { type: 'OPTIONS', color: 'gold' },
        { type: 'VIEW', color: 'magenta' }
    ]
    const deleteInitiative = (record) => {
        dispatch(deleteBusinessInitiative(record.id)).then(() => {
            dispatch(getAllBusinessInitiative(1))
        })
    }

    const tableDataColoumns = [
        {
            title: 'Initiative Name',
            key: 'initiativeName',
            dataIndex: 'initiativeName',
            fixed: 'left'
        },
        {
            title: 'Initiative Elevator Statement',
            key: 'initiativeElevatorStatement',
            dataIndex: 'initiativeElevatorStatement',
        },
        {
            title: 'End Date',
            key: 'actualEnddate',
            dataIndex: 'actualEndDate',
            render: (val, record) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            },
        },
        {
            title: 'Creation Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val, record) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            },
        },
        {
            title: 'Expiry Date',
            key: 'expiryDate',
            dataIndex: 'expiryDate',
            render: (val, record) => {
                return val && <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        },
        {
            title: 'Inception Date',
            key: 'inceptionDt',
            dataIndex: 'inceptionDt',
            render: (val) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        },
        {
            title: 'Funded',
            key: 'isFunded',
            dataIndex: 'isFunded',
            render: (val) => {
                return (
                    <div style={{ color: val ? 'green' : 'red' }} >{val ? 'Yes' : 'No'} </div>
                )
            }
        },
        {
            title: 'Planned End Date',
            key: 'plannedEndDt',
            dataIndex: 'plannedEndDt',
            render: (record, val) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        },
        {
            title: 'Total Funding Amount(Approved)',
            key: 'totalApprovedFundingAmount',
            dataIndex: 'totalApprovedFundingAmount',
        },
        {
            title: 'Total Funding Amount(Requested)',
            key: 'totalFundingRequestAmount',
            dataIndex: 'totalFundingRequestAmount',
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="0" onClick={() => {
                            dispatch(
                                getPushPathWrapper('updateBusinessInitiative', { id: record.id })
                            )
                        }}>
                            Edit Business Initiative
                  </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="1"
                            onClick={() => deleteInitiative(record)}>
                            Delete Initiative
                     </Menu.Item>
                    </Menu>
                )
                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <SettingOutlined />
                    </Dropdown>
                )
            },
            fixed: 'right'
        }
    ]

    const onChange = (e) => {
        let data = []
        if (e.target.checked) {
            async.forEach(businessInitiativeList, (doc, cb) => {
                if (!doc.expiryDate) {
                    data.push(doc)
                }
                cb()
            }, () => {
                setMainState(data)
            })
        } else {
            setMainState(businessInitiativeList)
        }
    }
    return (
        <Card
            id={'allBusinessInitiative'}
            title={'All Business Initiatives ' + `(${tableData ? tableData.length : 0})`}
            extra={<Button type={'primary'} onClick={() => {
                dispatch(
                    getPushPathWrapper('addBusinessInitiative')
                )
            }}>Add Business Initiative</Button>}
        >
            <div className={styles.checkbox}>
                <Checkbox defaultChecked onChange={onChange}>Active Records</Checkbox>
            </div>

            <TableComp
                style={styles.test}
                columns={tableDataColoumns}
                bordered
                dataSource={tableData}
                loading={loading}
                pagination={{
                    // current: 1,
                    defaultPageSize: 100,
                    pageSizeOptions: ['10', '25', '50', '100', '500'],
                    showSizeChanger: true,
                }}
                extraProps={{ scroll: { x: true } }}
            />
        </Card>
    )
}
