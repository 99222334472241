import axios from "axios";
import {
  getAllBusinessComponentURL,
  getBusinessComponentURL,
  updateBusinessComponentUrl,
  addNewBusinessComponent,
  getDeleteBusinessComponentURL
} from "../api/BusinessComponentsURLs";
import { notification } from 'antd'
export const getBusinessComponentListData = id => async dispatch => {
  dispatch({ type: "SHOW_LOADER" });
  await axios
    .get(getAllBusinessComponentURL()+id, { Headers })
    .then(function (data) {
      dispatch({ type: "HIDE_LOADER" });
      dispatch({
        type: "ALL_COMPONENTS_LIST_DATA",
        payload: data.data
      });
    })
    .catch(function (error) {
      dispatch({ type: "HIDE_LOADER" });
    })
};

export const addBusinessComponent = (inputData) => async (dispatch) => {
  console.log(inputData)
  dispatch({ type: 'SHOW_LOADER' });
  dispatch({ type: 'ERROR_CHECKER' });

  await axios
    .post(addNewBusinessComponent(), inputData, { Headers })
    .then(function (data) {
      dispatch({ type: 'HIDE_LOADER' });
      dispatch({ type: 'SUCCESS_CHECKER' });
      notification.success({
        message: 'Successfully added Business Component.'
      })
    })
    .catch(function (error) {
      notification.error({
        message: JSON.stringify(error)
      })
      dispatch({ type: 'ERROR_CHECKER' });
      dispatch({ type: 'HIDE_LOADER' });
    });
};
export const updateBusinessComponent = (inputData) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER' });
  await axios
    .post(updateBusinessComponentUrl(), inputData, { Headers })
    .then(function (data) {
      dispatch({ type: 'HIDE_LOADER' });
      notification.success({
        message: 'Successfully updated Business Component.'
      })
    })
    .catch(function (error) {
      console.log('error', error);
      notification.error({
        message: JSON.stringify(error)
      })
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'HIDE_LOADER' });
    });
};
export const deleteBusinessComponent = (applicationId,inputdata) => async (dispatch) => {

  dispatch({ type: 'SHOW_LOADER' });

  await axios
    .delete(getDeleteBusinessComponentURL() + inputdata, { Headers })
    .then(function (response) {
      if (response.data) {
        notification.success({
          message: 'Deleted Successfully'
        })
        // toast.success("Successfully deleted web service.");
      } else {
        notification.error({
          message: 'Error while Deleting'
        })
        return
      }
      dispatch({ type: 'HIDE_LOADER' });
      dispatch(getBusinessComponentListData(applicationId));
    })
    .catch(function (error) {
      notification.error({
        message: 'Error while Deleting'
      })
      dispatch({ type: 'HIDE_LOADER' });
    });
};
export const getBusinessComponent = (id) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER' });
  await axios
      .get(getBusinessComponentURL() + id, { Headers })
      .then(function (data) {
          dispatch({ type: 'HIDE_LOADER' });
          dispatch({
              type: 'SET_BUSINESS_COMPONENT',
              payload: data.data
          });
      })
      .catch(function (error) {
          console.log('error', error);
          notification.error({
              message: JSON.stringify(error)
          })
          // toast.error(JSON.stringify(error));
          dispatch({ type: 'HIDE_LOADER' });
      });
};