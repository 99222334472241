import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Store, { history } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './containers/app';
import Amplify from 'aws-amplify';
import config from './aws-exports';

import './index.css';
const { store, persistor } = Store();

const target = document.querySelector('#root');
Amplify.configure(config);
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);
