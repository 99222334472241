import React, { Component, Suspense, lazy } from 'react';
import { Route, Link, Redirect, Switch } from 'react-router-dom';

import memoizeOne from 'memoize-one';

import AuthLayout from '../../layouts/AuthLayout';
import menuData, { getUrlPath } from '../../routes';
import Exception from '../../components/Exception';
import BasicLayout from '../../layouts/BasicLayout';
// import Login from '../../containers/login'
// import Register from '../../containers/register'
import '../../index.css';
import _ from 'lodash';
import { pageTitle } from '../../settings';
import { loadReCaptcha } from 'react-recaptcha-google';
import { Auth } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

Auth.currentAuthenticatedUser({
  bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
})
  .then((user) => {
    console.log('user', user);
    localStorage.setItem(
      'jwtToken',
      user.getSignInUserSession().getAccessToken().getJwtToken()
    );
    Auth.currentSession()
      .then((data) => console.log('currentSession', data))
      .catch((err) => console.log(err));
  })
  .catch((err) => console.log(err));

const Exp = () => (
  <Exception
    type="404"
    desc={'You Seems lost !!'}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
);
const Exp403 = () => (
  <Exception
    type="403"
    desc={"Sorry You Don't have access to this area !!"}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
);

class BasicLayoutWrapper extends Component {
  render() {
    const { menuData, component, path } = this.props;
    let user =
      localStorage.getItem('user') != 'undefined'
        ? JSON.parse(localStorage.getItem('user'))
        : null;

    /* if (!user) {
       return (<Redirect to="/login"/>)
     }*/

    /*
      if (menuItem.authority !== undefined && menuItem.authority.indexOf(user.userType) === -1) {
        console.log('this user should not be here ', path)
        return <Exp403/>
      }


      if (!menuItem.title) {
        menuItem.title = !!menuItem.name ? menuItem.name : 'Untitled'
      }*/

    let menuItem = _(menuData)
      .thru(function (coll) {
        return _.union(coll, _.map(coll, 'children'));
      })
      .flatten()
      .find({ path: path });

    if (!menuItem.title) {
      menuItem.title = !!menuItem.name ? menuItem.name : 'Untitled';
    }

    return (
      <BasicLayout
        location={window.location}
        title={pageTitle}
        pageTitle={`${menuItem.title} - App Visualizer.`}
        menuData={menuData}>
        {!!component ? (
          <Suspense fallback={<div>Loading...</div>}>
            <this.props.component {...this.props} />
          </Suspense>
        ) : (
          <Exp />
        )}
      </BasicLayout>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem('token'),
      user:
        localStorage.getItem('user') != 'undefined'
          ? JSON.parse(localStorage.getItem('user'))
          : null
    };
  }

  componentDidMount() {
    loadReCaptcha();

    window.onUsersnapCXLoad = function (api) {
      api.init();
    };
    var script = document.createElement('script');
    script.async = 1;
    script.src =
      'https://widget.usersnap.com/load/84fdc7b1-7113-4666-a1c2-8804bc3a06d3?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  render() {
    const { user } = this.state;

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <AmplifySignOut />
        <Switch>
          {menuData.map((item, key) => {
            if (!item.children) {
              return (
                <Route
                  exact
                  path={item.path}
                  key={item.path}
                  render={(props) => {
                    return (
                      <BasicLayoutWrapper
                        component={item.component}
                        path={item.path}
                        user={user}
                        menuData={menuData}
                        {...props}
                      />
                    );
                  }}
                />
              );
            }
          })}

          {menuData.map((item, key) => {
            if (item.children) {
              return item.children.map((child, k) => {
                return (
                  <Route
                    exact
                    path={child.path}
                    key={child.path}
                    render={(route) => {
                      return (
                        <BasicLayoutWrapper
                          component={child.component}
                          path={child.path}
                          user={user}
                          menuData={menuData}
                        />
                      );
                    }}
                  />
                );
              });
            }
          })}

          {/* <Route exact path="/login" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <Login/>
            </AuthLayout>
          )
        }}/>

        <Route exact path="/register" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <Register/>
            </AuthLayout>
          )
        }}/> */}


          {/* <Route exact path="/register" render={(route) => {
          return (
            <AuthLayout
              location={window.location}
              menuData={menuData}>
              <Register/>
            </AuthLayout>
          )
        }}/> */}

          <Route
            exact
            path="/"
            render={(route) => {
              return <Redirect to="/dashboard" />;
            }}
          />
        </Switch>
      </Suspense>
    );
  }
}

export default withAuthenticator(App, {});
// export default App;

