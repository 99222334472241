import { apiUrl } from '../../settings';

export const getAllBusinessEpicsURL =  () => {
  return apiUrl + "getAllBusinessEpicsForBsnsFeature?businessFeatureId="
};
export const getDeleteBusinessEpicsURL =  () => {
  return apiUrl + "deleteApplication?applicationId=";
};

export const addNewBusinessEpic =  () => {
  return apiUrl + "addNewBusinessEpic";
};

export const updateBusinessEpicUrl =  () => {
  return apiUrl + "updateBusinessEpic";
};
export const getBusinessEpicsURL =  () => {
  return apiUrl + "getBusinessEpic?businessEpicId="
};


