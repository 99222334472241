import { apiUrl } from "../../settings";

export const getBusinessCapabilityListUrl = (clientId) => {
    return apiUrl + 'getAllBusinessCapabilities?clientId=' + clientId;
};
export const addBusinessCapabilityUrl = () => {
    return apiUrl + 'addNewBusinessCapability';
};

export const updateBusinessCapability = (clientId) => {
    return apiUrl + 'updateBusinessCapability';
};

export const getBusinessCapabilityById = (businessCapabilityId) => {
    return apiUrl + 'getBusinessCapability?businessCapabilityId=' + businessCapabilityId;
};
export const deleteCapabilityURL = () => {
    return apiUrl + 'deleteBusinessCapability?businessCapabilityId=';
};
export const getAllBsnsInitiativesForBsnsCapability = (businessCapabilityId) => {
    return apiUrl + 'getAllBsnsInitiativesForBsnsCapability?businessCapabilityId=' + businessCapabilityId;
};
export const deAssociateBsnsCapabilityToBsnsInitiative = (businessCapabilityId) => {
    return apiUrl + 'deAssociateBsnsCapabilityToBsnsInitiative?businessCapabilityId='+businessCapabilityId
};
export const getAllFeaturesForCapability = (businessCapabilityId) => {
    return apiUrl + 'getAllFeaturesForCapability?businessCapabilityId=' + businessCapabilityId;
};
export const getAllApplicationForCapability = (businessCapabilityId) => {
    return apiUrl + 'associateApplicationToBsnsCapbility?businessCapabilityId=' + businessCapabilityId;
};
export const getAllInitiativeForCapability = (businessCapabilityId) => {
    return apiUrl + 'associateBsnsCapabilityToBsnsInitiative?businessCapabilityId=' + businessCapabilityId;
};
export const deAssociateBsnsApplicationToCapability = (businessCapabilityId) => {
    return apiUrl + 'deAssociateApplicationToBsnsCapbility?businessCapabilityId=' + businessCapabilityId;
};