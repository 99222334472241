import axios from "axios";
import moment from 'moment'
import { getEnvironmentListUrl } from "../api/environemntUrl";
import Headers from "../api/Headers";
import {
    getMetaDataURL,
    createWebServiceURL,
    updateWebServiceURL,
    deleteWebServiceURL,
    getWebServiceInstancesURL,
    getWSRequestHistoryForEnvURL,
    testWebServiceURL,
    saveWebServiceURL,
    deleteEnvWebServiceURL,
    deleteWebServiceRequestResponseURL,
    deleteRequestsURL,
    uploadBulkRequestURL,
} from '../api/environemntUrl';
import { notification } from "antd";
export const getEnvironmentList = (getWSListForEnv) => async (dispatch) => {

    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .get(getEnvironmentListUrl(getWSListForEnv), { Headers })
        .then(function (data) {
            console.log(data.data)
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            dispatch({
                type: 'ENVIRONMENT_LIST',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const getMetaData = () => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .get(getMetaDataURL(), { Headers })
        .then(function (data) {
            // dispatch({ type: "ENVIRONMENT_HIDE_LOADER" });
            dispatch({
                type: 'ENVIRONMENT_META_DATA',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const createWebService = (inputdata) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    console.log('createWebService', JSON.stringify(inputdata));
    await axios
        .post(createWebServiceURL(), inputdata, { Headers })
        .then(function (response) {
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            dispatch({ type: 'ENVIRONMENT_LIST' });
            // toast.success("Successfully created web service.");
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const updateWebService = (inputdata) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .post(updateWebServiceURL(), inputdata, { Headers })
        .then(function (response) {
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            dispatch({ type: 'ENVIRONMENT_LIST' });
            // toast.success("Successfully updated web service.");
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const deleteWebService = (inputdata) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .delete(deleteWebServiceURL() + inputdata, { Headers })
        .then(function (response) {
            console.log(response)
            if (response.data) {
                notification.success({
                    message: 'Deleted Successfully'
                })
                // toast.success("Successfully deleted web service.");
            } else {
                notification.error({
                    message: 'Error while Deleting'
                })
                return

                // toast.success("Something went wrong in deleting web service.");
            }
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            dispatch({ type: 'ENVIRONMENT_LIST' });
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const getWebServiceInstances = (id) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .get(getWebServiceInstancesURL(id), { Headers })
        .then(function (data) {
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            dispatch({
                type: 'ENVIRONMENT_INSTANCES',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const getWSRequestHistoryForEnv = (id) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .get(getWSRequestHistoryForEnvURL(id), { Headers })
        .then(function (data) {
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            dispatch({
                type: 'ENVIRONMENT_HISTORY',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const clearResponse = () => (dispatch) => {
    dispatch({ type: 'CLEAR_RESPONSE' });
};

export const testWebService = (inputdata) => async (dispatch) => {
    dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
    console.log('testWebService', JSON.stringify(inputdata));
    // inputdata.requestDate = moment().format("YYYY-MM-DD hh:mm:ss");
    inputdata.requestDate = moment().format();
    delete inputdata.responseDate;
    delete inputdata.responseData;
    delete inputdata.responseStatusCode;
    await axios
        .post(testWebServiceURL(), inputdata, { Headers })
        .then(function (response) {
            dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
            dispatch({
                type: 'ENVIRONMENT_RESPONSE_RECVD',
                payload: response,
            });
            notification.success({
                message: "Successfully sent web service."
            })
            dispatch(getEnvironmentList(inputdata.rmiWSInfo.id))
            // toast.success("Successfully sent web service.");
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: "Test web service failed: " + JSON.stringify(error)
            })
            // toast.error("Test web service failed: " + JSON.stringify(error));
            dispatch({
                type: 'ENVIRONMENT_RESPONSE_RECVD',
                payload: error,
            });
            dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
        });
};

export const saveWebService = (inputdata) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .post(saveWebServiceURL(), inputdata, { Headers })
        .then(function (response) {
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
            // toast.success("Successfully saved web service.");
            dispatch(getWebServiceInstances(inputdata.rmiWSInfo.id));
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const deleteEnvWebService = (instanceEnvId, wsId) => async (
    dispatch
) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    await axios
        .delete(deleteEnvWebServiceURL(instanceEnvId), { Headers })
        .then(function (response) {
            if (response.data) {
                notification.success({
                    message: 'Successfully deleted web service instance.'
                })
                dispatch(getEnvironmentList(wsId))
                // toast.success("Successfully deleted web service instance.");
            } else {
                notification.error({
                    message: 'Something went wrong in deleting web service instance.'
                })
                // toast.success("Something went wrong in deleting web service instance.");
            }
            dispatch(getWebServiceInstances(wsId));
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const deleteWebServiceRequestResponse = (request) => async (
    dispatch
) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    console.log('deleteWebServiceRequestResponse', request);
    await axios
        .post(deleteWebServiceRequestResponseURL(), request, { Headers })
        .then(function (response) {
            if (response.data) {
                // toast.success("Successfully deleted web service request.");
            } else {
                // toast.success("Something went wrong in deleting web service history.");
            }
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const deleteRequests = (requests) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    console.log('deleteRequests', requests);
    await axios
        .post(deleteRequestsURL(), requests, { Headers })
        .then(function (response) {
            if (response.data) {
                // toast.success("Successfully deleted.");
            } else {
                // toast.success("Something went wrong in deletion.");
            }
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};

export const uploadBulkRequest = (requests) => async (dispatch) => {
    dispatch({ type: 'ENVIRONMENT_SHOW_LOADER' });
    console.log('uploadBulkRequest', requests);
    await axios
        .post(uploadBulkRequestURL(), requests, { Headers })
        .then(function (response) {
            if (response.data) {
                // toast.success("Successfully uploaded requests.");
            } else {
                // toast.success("Something went wrong in uploading requests.");
            }
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'ENVIRONMENT_HIDE_LOADER' });
        });
};
