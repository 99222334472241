
import React from 'react'
import { v4 as uuid } from 'uuid'
export const rmiWSAuthenticationInfos = [
  {
    "authenticationDefaultValue": "string",
    "authenticationKey": "string",
    "authenticationTypeId": "string",
    "id": uuid(),
    "wsInfoId": "string"
  },
  {
    "authenticationDefaultValue": "string1",
    "authenticationKey": "string1",
    "authenticationTypeId": "string1",
    "id": uuid(),
    "wsInfoId": "string1"
  }
]