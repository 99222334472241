import React, { useEffect, useState } from "react";
import KeyValue from "./KeyValue";
import { Col, Radio, Button, Space, Row } from "antd";
import { parseUrl, parseKeyValueToURL, parseJsonToURL } from "./parseURL.js";
import AceEditor from "react-ace";

export default function Parameters({ request, handleRequestChange }) {
  const [parametersView, setParametersView] = useState("bulkedit");
  const [parametersError, setParametersError] = useState(false);
  const [keyIndex, setKeyIndex] = useState(1);
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    if (instance) {
      instance.refresh();
    }
  });

  const getKeyValueView = url => {
    try {
      return parseUrl(url, "");
    } catch {
      console.log("something went wrong.");
      return "error";
    }
  };
  const getBulkeditView = (url, view) => {
    try {
      return JSON.stringify(parseUrl(url, view));
    } catch {
      console.log("something went wrong.");
      return "error";
    }
  };

  const updateHeader = (index, key, value) => {
    console.log(
      "parseKeyValueToURL",
      parseKeyValueToURL(request.requestURL, index, key, value)
    );
    handleRequestChange(
      {
        requestURL: parseKeyValueToURL(request.requestURL, index, key, value)
      },
      true
    );
  };
  const addHeaderRow = () => {
    let newRow = `&key${keyIndex}=value`;
    setKeyIndex(keyIndex + 1);
    if (request.requestURL.split("?").length === 1) {
      newRow = "?key1=value";
    }
    handleRequestChange(
      {
        requestURL: request.requestURL + newRow
      },
      true
    );
  };
  const updateBulkEdit = value => {
    try {
      console.log(
        "parseJsonToURL",
        parseJsonToURL(request.requestURL, JSON.parse(value))
      );
      handleRequestChange(
        {
          requestURL: parseJsonToURL(request.requestURL, JSON.parse(value))
        },
        true
      );
      setParametersError(false);
    } catch {
      setParametersError(true);
      console.log("Something went wrong.");
    }
  };
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Radio.Group defaultValue="bulkedit" onChange={(e) => {
          setParametersView(e.target.value)
        }} buttonStyle="solid">
          <Radio.Button value="bulkedit">Bulk Edit</Radio.Button>
          <Radio.Button value="keyvalue">Key-Value</Radio.Button>
        </Radio.Group>
        <div>
          {parametersView === "keyvalue" && (
            <Button
              className="right ml-2"
              onClick={() => {
                addHeaderRow();
              }}
            >
              Add Row
            </Button>
          )}
        </div>
      </div>
      {parametersView === "bulkedit" && (
        <AceEditor
          value={getBulkeditView(request.requestURL, "bulkedit")}
          mode="javascript"
          height='200px'
          width='unset'
          onChange={(value) => {
            console.log("editor", value);
            updateBulkEdit(value);
          }}
        />
      )}
      {parametersView === "keyvalue" && (
        <KeyValue
          headers={getKeyValueView(request.requestURL)}
          updateHeader={updateHeader}
        />
      )}
      {parametersError && "Invalid data!!"}
    </Space>
  );
}
