import React, { useEffect, useState } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import featureList from './sampleBusinessFeature.js';
import notificationHookList from './sampleNotificationHook.js';
import { Form, Card, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { addBussinessCapability } from '../actions/businessCapabilityActions';
import {
    getAllBusinessInitiative
} from '../../BusinessInitiative/actions/businessInitiativeAction';
import {
    getCustomerApplicationListData
} from '../../CustomerApplications/actions/CustomerApplicationsListAction';
import {
    getBussinessFeatureListForCapability
} from '../../BusinessFeatures/actions/businessFeatureActions';

import { formItemLayout } from '../../modules/utils';
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import styles from './styles.less'
import { getAllCustomerApplicationsForClientURL } from '../../CustomerApplications/api/CustomerApplicationsWSURLs';
const clientId = '1'

const businessCapabilityAdd = () => {
    const location = useLocation();

    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { businessCapabilityLoader, businessInitiativeList, customerApplicationList, businessFeatureList, successFullyAdded } = useSelector(
        (state) => ({
            businessCapabilityLoader: state.businessCapability.businessCapabilityLoader,
            successFullyAdded: state.businessCapability.successFullyAdded,
            businessInitiativeList: state.businessInitiative.businessInitiativeList,
            customerApplicationList: state.customerServices.candidateApplicationDataList,
            businessFeatureList: state.businessFeatures.businessFeatureList,


        })
    );
    const [state, setState] = useState({
        initiativeOptions: [],
        applicationOptions: [],
        featureOptions: []
    })

    useEffect(() => {
        dispatch(getAllBusinessInitiative(clientId))
        // dispatch(getCustomerApplicationListData(clientId))
        // dispatch(getBussinessFeatureListForCapability(clientId))

    }, [])
    useEffect(() => {
        fillInitiativeData(businessInitiativeList)

    }, [businessInitiativeList])


   // useEffect(() => {
    //     fillApplicationData(customerApplicationList)
    //}, [customerApplicationList])

    // useEffect(() => {
    //     fillfeatureData(featureList)
    // }, [])

    if (successFullyAdded) {
        form.resetFields()
    }
    const fillInitiativeData = (initiativeData) => {
        console.log(initiativeData)
        let options = _.map(initiativeData, (val) => { return { initiativeName: val.initiativeName, id: val.id } })
        console.log(options)
        setState({ ...state, initiativeOptions: options })
    }
    const fillApplicationData = (applicationData) => {
        let options = _.map(applicationData, (val) => { return { applicationName: val.applicationName, id: val.id } })
        setState({ ...state, applicationOptions: options })
    }
    const fillfeatureData = (featureData) => {
        let options = _.map(featureData, (val) => { return { featureName: val.featureName, id: val.id } })
        setState({ ...state, featureOptions: options })
    }
    const inputFields = {
        fields: [
            {
                label: 'Capability Name',
                key: 'capabilityName',
                type: 'string',
                required: true,
            },
            {
                label: 'Capability Description',
                key: 'capabilityDescription',
                type: 'string',
                required: true,
            },
            // {
            //     label: 'Assc. Initiative ',
            //     key: 'businessInitiativeList',
            //     type: 'select',
            //     mode: 'multiple',
            //     keyAccessor: x => x.id,
            //     valueAccessor: x => `${x.initiativeName}`,
            //     required: true,
            //     options: state.initiativeOptions,
            //     onChange: (businessInitiativeList) => {
            //         form.setFieldsValue({ businessInitiativeList })
            //     }

            // },
            {
                label: 'Business Initiative ',
                key: 'businessInitiativeId',
                type: 'select',
                keyAccessor: x => x.id,
                valueAccessor: x => `${x.initiativeName}`,
                required: true,
                options: state.initiativeOptions,
                onChange: (businessInitiativeId) => {
                    form.setFieldsValue({ businessInitiativeId })
                    let initiativeId = form.getFieldValue('businessInitiativeId')
                    let filter = state.initiativeOptions.filter(item => initiativeId == item.id)
                    console.log(filter)
                    form.setFieldsValue({'businessInitiativeName' : filter[0].initiativeName })
                }
 
            },
            {
                label: 'status',
                key: 'status',
                type: 'select',
                required: true,
                keyAccessor: x => x,
                valueAccessor: x => `${x}`,
                options: [
                    "completed",
                    "cancelled",
                    "planned",
                    "backlog"
                ],
                onChange: status => {
                    form.setFieldsValue({ status })
                }
            },

            {
                label: 'Expiry Date',
                key: 'expiryDate',
                dataIndex: 'expiryDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Planned/Estimated Cost',
                key: 'estimatedCost',
                dataIndex: 'estimatedCost',
                type: 'number',
            },
            {
                label: 'Actual Implementation Cost',
                key: 'actualImplementationCost',
                dataIndex: 'actualImplementationCost',
                type: 'number',
            },
            // {
            //     label: 'Customer Application List',
            //     key: 'customerApplicationList',
            //     dataIndex: 'customerApplicationList',
            //     type: 'select',
            //     mode: 'multiple',
            //     keyAccessor: x => x.id,
            //     valueAccessor: x => `${x.applicationName}`,
            //     // required: true,
            //     options: state.applicationOptions,
            //     onChange: (customerApplicationList) => {
            //         form.setFieldsValue({ customerApplicationList })
            //     }
            // },
            // {
            //     label: 'Business Feature List',
            //     key: 'miniBusinessFeatureList',
            //     dataIndex: 'miniBusinessFeatureList',
            //     type: 'select',
            //     mode: 'multiple',
            //     keyAccessor: x => x.id,
            //     valueAccessor: x => `${x.featureName}`,
            //     // required: true,
            //     options: state.featureOptions,
            //     onChange: (miniBusinessFeatureList) => {
            //         form.setFieldsValue({ miniBusinessFeatureList })
            //     }
            // },
            // {
            //     label: 'Inbound Notification Hook',
            //     key: 'inboundNotificationHook',
            //     dataIndex: 'inboundNotificationHook',
            //     type: 'select',
            //     options: _.map(notificationHookList, (item) => ({ id: item.watchItemId, display: item.watchItemName })),
            //     onChange: (inboundNotificationHook) => {
            //         form.setFieldsValue({ inboundNotificationHook })
            //     }
            // },
        ]
    }
    const onFinish = (values) => {
        values.id = uuid();
        values.clientId = clientId
        values.businessInitiativeName=form.getFieldValue('businessInitiativeName')
        // values.businessInitiativeList = state.initiativeOptions.filter(item => values.businessInitiativeList.findIndex((val) => val == item.id) > -1)
        console.log(values)
        // values.customerApplicationList = customerApplicationList.filter(item => values.customerApplicationList.findIndex((val) => val == item.id) > -1)
        values.expiryDate = moment(values.expiryDate).toISOString()
        // values.miniBusinessFeatureList = featureList.filter(item => values.miniBusinessFeatureList.findIndex((val) => val == item.id) > -1)
        // values.inboundNotificationHook = notificationHookList.filter(item => values.inboundNotificationHook == item.watchItemId)[0]
        dispatch(addBussinessCapability(values))

    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title={'Add Business Capability'} bordered={true}>
            <Form size={'medium'} form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...tailLayout}>
                    <div className={styles.submitDiv}>
                        <Button onClick={() => form.resetFields()}>
                            {'Clear'}
                        </Button>
                        <Button type="primary" style={{ marginLeft: '10px' }} htmlType="submit" loading={businessCapabilityLoader}>
                            {'Add'}
                        </Button>

                    </div>
                </Form.Item>
            </Form>

        </Card>
    );
};

export default businessCapabilityAdd;