import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import {
  Button,
  Input,
  Select,
  Tag,
  Card,
} from 'antd'
import {
  SettingOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import styles from './styles.less'
import { TableComp } from 'sz-react-utils4'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import {
  getBusinessEpicsListData,
  deleteBusinessEpics
} from '../actions/BusinessEpicsListAction'
import moment from 'moment'
import { timeStampFormat } from '../../settings'
import { getPushPathWrapper } from '../../routes'
import { useSelector, useDispatch } from 'react-redux';
import { getBussinessFeatureListForCapability, getBusinessCapabilityList } from '../../BusinessFeatures/actions/businessFeatureActions'

const clientId=1
const { Option } = Select

const Dashboard = () => {

  const [state, setState] = useState({
    searchText: '',
    loading: false,
    total: 0,
    key: 0,
    FeatureDisabled:true,
    disabled:true
    })
  const dispatch = useDispatch();
  const { BusinessEpicsList, loading,businessFeatureList, businessCapabilityList } = useSelector(
    (state) => ({
      BusinessEpicsList: state.businessEpics.epicsDataList,
      loading: state.businessEpics.loading,
      businessCapabilityList: state.businessCapability.businessCapabilityList,
      businessFeatureList: state.businessFeatures.businessFeatureList,
    
    })
  );

  useEffect(() => {
    dispatch(getBusinessCapabilityList(clientId))
  }, [])

 const result = () => {
  dispatch(getBusinessEpicsListData(state.featureId));
  }
  const selectChangeHandler = (val) => {
    dispatch(getBussinessFeatureListForCapability(val))
    setState({ ...state, capabilitiyId: val,FeatureDisabled: false })
  }
  const selectChangeFeatureHandler = (val) => {
    setState({ ...state, featureId: val,disabled: false })
  }

  const getColumnSearchProps = (dataIndex, index) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          className={`search-value-${dataIndex}`}
          // ref={node => {
          //     searchInput = node
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          className={`search-${dataIndex}`}
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={"search"}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
    </Button>
        <Button className={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
    </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined className={`filter-menu-${dataIndex}`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return index ?
        (
          record[dataIndex] && record[dataIndex][index] && record[dataIndex][index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())

        ) :
        (
          record[dataIndex] && record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())

        )
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() =>searchInput.select())
      }
    },
    render: text => (
      text && <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })
  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setState({ ...state, searchText: selectedKeys[0] })
  }
  const handleReset = clearFilters => {
    clearFilters()
    setState({ ...state, searchText: '' })
  }

  const toggleModal = () => {
    dispatch(
      getPushPathWrapper('addBusinessEpics')
    )
  }
  const deleteBusinessEpicsFxn = (record) => {
    dispatch(deleteBusinessEpics(record.id))
  }
  const tableDataColoumns = [
    {
      title: 'Epics Name',
      key: 'epicName',
      dataIndex: 'epicName',
      // fixed: 'left'
      ...getColumnSearchProps('epicName'),

    },

    {
      title: 'Epic Description',
      key: 'epicDescription',
      dataIndex: 'epicDescription',
    },
    {
      title: 'Creation Date',
      key: 'creationDate',
      dataIndex: 'creationDate',
      render: (val) => {
        return <Tag>{moment(val).format(timeStampFormat)}</Tag>
      }
    },
    {
      title: 'Jira Epic Id',
      key: 'jiraEpicId',
      dataIndex: 'jiraEpicId',
    },
    {
      title: 'Jira Epic Url',
      key: 'jiraEpicUrl',
      dataIndex: 'jiraEpicUrl',
    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return <div className={styles.actions}> <Button
          icon={<EditOutlined />}
          shape="circle"
          onClick={() => {
            // dispatch(
            //   getPushPathWrapper('updateBusinessCapability', { id: record.id })
            // )
          }} />

          <Button icon={<DeleteOutlined />}
            shape="circle"
            type='danger'
            onClick={() => deleteBusinessEpicsFxn(record)} />
        </div>
      }
    },


  ]
  return (<Card
    id={'dashboard'}
    title={`Business Epics List (${BusinessEpicsList && BusinessEpicsList.length})`} extra={<Button type='primary' onClick={toggleModal}>Add Business Epics</Button>}>
    <div className={styles.filters}>
      <Select
        style={{ width: 200 }}
        placeholder="Select a Capability"
        onChange={selectChangeHandler}
      >
        {businessCapabilityList.map((item, key) => (<Option value={item.id} key={key}>{item.capabilityName}</Option>))}
      </Select>
      <Select
        style={{ marginLeft: '10px', width: 200 }}
        placeholder="Select a Feature"
        onChange={selectChangeFeatureHandler}
        disabled={state.FeatureDisabled}
      >
        {businessFeatureList.map((item, key) => (<Option value={item.id} key={key}>{item.featureName}</Option>))}
      </Select>
      <Button type="primary" style={{ marginLeft: '10px' }} disabled={state.disabled} onClick={() => result()}>Search</Button>
    </div>
    <TableComp
      style={styles.test}
      columns={tableDataColoumns}
      bordered
      dataSource={BusinessEpicsList}
      loading={loading}
      pagination={{
        pageSizeOptions: ['10', '25', '50', '100', '500'],
        showSizeChanger: true,
      }}
      extraProps={{
        scroll: { x: true }
      }}
    />
  </Card>
  )
}
export default Dashboard