import React, { useEffect, useState } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import featureList from './sampleBusinessFeature.js';
import notificationHookList from './sampleNotificationHook.js';
import { Form, Card, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { addBussinessCapability, getBussinessCapabilityById, updateBussinessCapability } from '../actions/businessCapabilityActions';
import {
    getAllBusinessInitiative
} from '../../BusinessInitiative/actions/businessInitiativeAction';
import {
    getCustomerApplicationListData
} from '../../CustomerApplications/actions/CustomerApplicationsListAction';
import {
    getBussinessFeatureListForCapability
} from '../../BusinessFeatures/actions/businessFeatureActions';

import { formItemLayout } from '../../modules/utils';
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import AssociativeBusinessInitiative from './associatedBusinessInitiative'
import AssociativeBusinessFeature from './associativeBusinessFeature'
import AssociativeApplication from './associatedApplication'


const clientId='1'
const businessCapabilityAdd = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { businessCapabilityLoader, currentBusinessCapability, businessInitiativeList, customerApplicationList, businessFeatureList } = useSelector(
        (state) => ({
            businessCapabilityLoader: state.businessCapability.businessCapabilityLoader,
            currentBusinessCapability: state.businessCapability.currentBusinessCapability,
            businessInitiativeList: state.businessInitiative.businessInitiativeList,
            // customerApplicationList: state.customerServices.candidateApplicationDataList,
            // businessFeatureList: state.businessFeatures.businessFeatureList,


        })
    );
    const [state, setState] = useState({
        initiativeOptions: [],
        applicationOptions: [],
        featureOptions: []
    })
    const paramsId = location.pathname.split('/')[2]
    useEffect(() => {
        if (paramsId) {
            dispatch(getBussinessCapabilityById(paramsId))
        }
        dispatch(getAllBusinessInitiative(clientId))


    }, [location])
    useEffect(() => {
        if (paramsId){
            currentBusinessCapability.creationDate=moment(currentBusinessCapability.creationDate)
            form.setFieldsValue({ ...currentBusinessCapability })
        }
        console.log(currentBusinessCapability)
    }, [currentBusinessCapability])


    useEffect(() => {
        dispatch(getAllBusinessInitiative(1))

    }, [])
    useEffect(() => {
        fillInitiativeData(businessInitiativeList)
    }, [businessInitiativeList])

    const fillInitiativeData = (initiativeData) => {
        let options = _.map(initiativeData, (val) => { return { initiativeName: val.initiativeName, id: val.id } })
        setState({ ...state, initiativeOptions: options })
    }

    const inputFields = {
        fields: [
            {
                label: 'Capability Name',
                key: 'capabilityName',
                type: 'string',
                required: true,
            },
            {
                label: 'Capability Description',
                key: 'capabilityDescription',
                type: 'string',
                required: true,
            },
            // {
            //     label: 'Assc. Initiative ',
            //     key: 'businessInitiativeList',
            //     type: 'select',
            //     mode: 'multiple',
            //     keyAccessor: x => x.id,
            //     valueAccessor: x => `${x.initiativeName}`,
            //     required: true,
            //     options: state.initiativeOptions,
            //     onChange: (businessInitiativeList) => {
            //         form.setFieldsValue({ businessInitiativeList })
            //     }

            // },
            {
                label: 'status',
                key: 'status',
                type: 'select',
                required: true,
                keyAccessor: x => x,
                valueAccessor: x => `${x}`,
                options: [
                    "inProgress",
                    "completed",
                    "cancelled",
                    "planned",
                    "backlog"
                ],
                onChange: status => {
                    form.setFieldsValue({ status })
                }
            },
            {
                label: 'creation Date',
                key: 'creationDate',
                dataIndex: 'creationDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Last Updated Date',
                key: 'updatedDate',
                dataIndex: 'updatedDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                dataIndex: 'expiryDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Planned/Estimated Cost',
                key: 'estimatedCost',
                dataIndex: 'estimatedCost',
                type: 'number',
            },
            {
                label: 'Actual Implementation Cost',
                key: 'actualImplementationCost',
                dataIndex: 'actualImplementationCost',
                type: 'number',
            }
        ]
    }
    const onFinish = (values) => {
        values.id =currentBusinessCapability.id;
        values.clientId = clientId
        values.expiryDate = moment(values.expiryDate).toISOString()
        dispatch(updateBussinessCapability(values))

    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title={ 'Update Business Capability'} bordered={true}>
            <Form size={'medium'} form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={businessCapabilityLoader}>
                        {'Update'}
                    </Button>
                </Form.Item>
            </Form>
        <AssociativeBusinessInitiative capabilityId={currentBusinessCapability} />
        <AssociativeBusinessFeature capabilityId={currentBusinessCapability} />
        <AssociativeApplication capabilityId={currentBusinessCapability}  />
        </Card>
    );
};

export default businessCapabilityAdd;