export default (
  state = {
    businessCapabilityLoader: false,
    businessCapabilityList: [],
    currentBusinessCapability: {}
  },
  action
) => {
  switch (action.type) {
    case "BUSINESS_CAPABILITY_LIST":
      return { ...state, businessCapabilityList: action.payload };
    case "BUSINESS_CAPABILITY_SHOW_LOADER":
      return { ...state, businessCapabilityLoader: true };
    case "BUSINESS_CAPABILITY_HIDE_LOADER":
      return { ...state, businessCapabilityLoader: false };
    case "SET_BUSINESS_CAPABILITY":
      return { ...state, currentBusinessCapability: action.payload };
      case "BUSINESS_CAPABILITY_SUCCESS":
        return { ...state, successFullyAdded: true };
      case "BUSINESS_CAPABILITY_ERROR":
        return { ...state, successFullyAdded: false };
        case "BUSINESS_INITIATIVE_FOR_CAPABILITY":
          return { ...state, getAllBsnsInitiativesForBsnsCapability: action.payload };
          case "BUSINESS_FEATURE_FOR_CAPABILITY":
            return { ...state, getAllFeaturesForCapability: action.payload };
         
    default:
      return state;
  }
};
