import { apiUrl } from "../../settings";

export const getBussinessFeatureListUrl = (capabilityId) => {
    return apiUrl + 'getAllBusinessFeaturesForBsnsCapbility?businessCapabilityId=' + capabilityId;
};

export const addNewBusinessFeature = (clientId) => {
    return apiUrl + 'addNewBusinessFeature';
};

export const updateBusinessFeature = (clientId) => {
    return apiUrl + 'updateBusinessFeature';
};

export const getBusinessFeatureById = () => {
    return apiUrl + 'getAllBusinessFeature?businessFeatureId=';
};

export const getBusinessCapabilityListUrl = (clientId) => {
    return apiUrl + 'getAllBusinessCapabilities?clientId=' + clientId;
};
export const associateFeatureToBsnsCapabilityUrl= () => {
    return apiUrl + 'associateBusinessFeaturesToBsnsCapbility?businessCapabilityId=';
};
export const deleteFeature = () => {
    return apiUrl + 'deleteBusinessFeature';
};
