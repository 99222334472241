import axios from "axios";
import {
  getAllBusinessEpicsURL,
  updateBusinessEpicUrl,
  addNewBusinessEpic,
  getDeleteBusinessEpicsURL,
  getBusinessEpicsURL
} from "../api/BusinessEpicsWSURLs";
import { notification } from 'antd'
export const getBusinessEpicsListData = ids => async dispatch => {
  dispatch({ type: "SHOW_LOADER" });
  await axios
    .get(getAllBusinessEpicsURL()+ids, { Headers })
    .then(function (data) {
      dispatch({ type: "HIDE_LOADER" });
      dispatch({
        type: "ALL_EPICS_LIST_DATA",
        payload: data.data
      });
    })
    .catch(function (error) {
      dispatch({ type: "HIDE_LOADER" });
    })
};

export const addBusinessEpics = (inputData) => async (dispatch) => {
  console.log(inputData)
  dispatch({ type: 'SHOW_LOADER' });
  dispatch({ type: 'ERROR_CHECKER' });

  await axios
    .post(addNewBusinessEpic(), inputData, { Headers })
    .then(function (data) {
      dispatch({ type: 'HIDE_LOADER' });
      dispatch({ type: 'SUCCESS_CHECKER' });
      notification.success({
        message: 'Successfully added Business Epics.'
      })
    })
    .catch(function (error) {
      notification.error({
        message: JSON.stringify(error)
      })
      dispatch({ type: 'ERROR_CHECKER' });
      dispatch({ type: 'HIDE_LOADER' });
    });
};
export const updateBusinessEpics = (inputData) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER' });
  await axios
    .post(updateBusinessEpicUrl(), inputData, { Headers })
    .then(function (data) {
      dispatch({ type: 'HIDE_LOADER' });
      notification.success({
        message: 'Successfully updated Business Epics.'
      })
    })
    .catch(function (error) {
      console.log('error', error);
      notification.error({
        message: JSON.stringify(error)
      })
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'HIDE_LOADER' });
    });
};
export const deleteBusinessEpics = (inputdata) => async (dispatch) => {

  dispatch({ type: 'SHOW_LOADER' });

  await axios
    .delete(getDeleteBusinessEpicsURL() + inputdata, { Headers })
    .then(function (response) {
      if (response.data) {
        notification.success({
          message: 'Deleted Successfully'
        })
        // toast.success("Successfully deleted web service.");
      } else {
        notification.error({
          message: 'Error while Deleting'
        })
        return
      }
      dispatch({ type: 'HIDE_LOADER' });
      dispatch(getBusinessEpicsListData(1));
    })
    .catch(function (error) {
      notification.error({
        message: 'Error while Deleting'
      })
      dispatch({ type: 'HIDE_LOADER' });
    });
};
export const getBusinessEpics = (id) => async (dispatch) => {
  dispatch({ type: 'SHOW_LOADER' });
  await axios
      .get(getBusinessEpicsURL() + id, { Headers })
      .then(function (data) {
          dispatch({ type: 'HIDE_LOADER' });
          dispatch({
              type: 'SET_BUSINESS_EPIC',
              payload: data.data
          });
      })
      .catch(function (error) {
          console.log('error', error);
          notification.error({
              message: JSON.stringify(error)
          })
          // toast.error(JSON.stringify(error));
          dispatch({ type: 'HIDE_LOADER' });
      });
};