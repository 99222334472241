import React, { useEffect, useState } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import { Form, Card, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { formItemLayout } from '../../modules/utils';
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import styles from './styles.less'
import { getBusinessEpics,updateBusinessEpics } from '../actions/BusinessEpicsListAction'
const clientId = '1'

const businessCapabilityEdit = () => {
    const location = useLocation();

    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { getEpicsData,loading } = useSelector(
        (state) => ({
            getEpicsData: state.businessEpics.getEpicsData,
            loading:state.businessEpics.loading
        })
    );
    const [state, setState] = useState({})
    
    const paramsId = location.pathname.split('/')[2]
    
    useEffect(() => {
        if (paramsId) {
            dispatch(getBusinessEpics(paramsId))
        }
    }, [location])

    const inputFields = {
        fields: [
            {
                label: 'Name',
                key: 'epicName',
                type: 'string',
                required: true,
            },
            {
                label: 'Epic Description',
                key: 'epicDescription',
                type: 'textArea',
                required: true,
            },
            {
                label: 'jira Epic Id',
                key: 'jiraEpicId',
                type: 'string',
                required: true,
            },
            {
                label: 'jira Epic Url',
                key: 'jiraEpicUrl',
                type: 'string',
                required: true,
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                dataIndex: 'expiryDate',
                type: 'date',
                required: true,
            }
        ]
    }
    useEffect(() => {
        if (getEpicsData) {
            form.setFieldsValue({ ...getEpicsData })
        }
    }, [getEpicsData])

    const onFinish = (values) => {
        values.id = getEpicsData.id;
        values.expiryDate = moment(values.expiryDate).toISOString()
        dispatch(updateBusinessEpics(values))

    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title={'Edit Business Epics'} bordered={true}>
            <Form size={'medium'} form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...tailLayout}>
                    <div className={styles.submitDiv}>
                        <Button onClick={() => form.resetFields()}>
                            {'Clear'}
                        </Button>
                        <Button type="primary" style={{ marginLeft: '10px' }} loading={loading} htmlType="submit">
                            {'Edit'}
                        </Button>

                    </div>
                </Form.Item>
            </Form>

        </Card>
    );
};

export default businessCapabilityEdit;