import { apiUrl } from "../../settings";
export const addBusinessInitiativeURL = () => {
    return apiUrl + 'addNewBusinessInitiative';
};

export const getAllBusinessInitiativeListURL = (clientId) => {
    console.log(clientId)
    return apiUrl + 'getAllBusinessInitiatives?clientId=' + clientId;
};

export const updateBusinessInitiativeUrl = () => {
    return apiUrl + 'updateBusinessInitiative';
};

export const getBusinessInitiativeByIdUrl = (businessInitiativeId) => {
    return apiUrl + 'getAllBusinessInitiative?businessInitiativeId=' + businessInitiativeId;
};

export const deleteBusinessInitiativeById = (businessInitiativeId) => {
    return apiUrl + 'deleteBusinessInitiative?businessInitiativeId=' + businessInitiativeId;
};