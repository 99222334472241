export default (
  state = {
    initiativeLoader: false,
    response: {},
    metaData: {},
    selectedInitiative: {},
    environmentList: [],
    environmentHistory: {}
  },
  action
) => {
  switch (action.type) {
    case "ADD_BUSINESS_INITIATIVE":
      return { ...state, data: action.payload };
    case "BUSINESS_INITIATIVE_LIST":
      return { ...state, businessInitiativeList: action.payload };
    case "INITIATIVE_SHOW_LOADER":
      return { ...state, initiativeLoader: true };
    case "INITIATIVE_HIDE_LOADER":
      return { ...state, initiativeLoader: false };
    case "SET_BUSINESS_INITIATIVE":
      return { ...state, selectedInitiative: action.payload };
      case "INITIATIVE_SUCCESS_CHECKER":
        return { ...state, addedSuccessFully: true };
      case "INITIATIVE_ERROR_CHECKER":
        return { ...state, addedSuccessFully: false };
    default:
      return state;
  }
};
