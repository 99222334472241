import React from 'react';
import { push } from 'connected-react-router';
import _ from 'lodash';
import Path from 'path-parser';

import listCustomerApplications from './CustomerApplications/views/CustomerApplications';
import addcustomerApplications from './CustomerApplications/views/AddNewCustomerApplication';

import allBusinessInitiative from './BusinessInitiative/views/allBusinessInitiative';
import WebHistory from './Environments/views/webHistory';
import Environment from './Environments/views/Environments';
import WebServices from './WebServices/views/WebServiceList';
import AddWebService from './WebServices/views/addWebService';
import EditWebService from './WebServices/views/editWebService';

import ListBusinessFeature from './BusinessFeatures/views/businessFeatureList';
import AddBusinessFeatures from './BusinessFeatures/views/businessFeatureAdd';
import EditBusinessFeatures from './BusinessFeatures/views/businessFeatureEdit';

import AddBusinessInitiative from './BusinessInitiative/views/addBusinessInitiative';
import UpdateBusinessInitiative from './BusinessInitiative/views/updateBusinessInitiative';
import AllBusinessInitiative from './BusinessInitiative/views/allBusinessInitiative';

import AddBusinessCapabilities from './BusinessCapability/views/businessCapabilityAdd';
import EditBusinessCapabilities from './BusinessCapability/views/businessCapabilityEdit';
import AllBusinessCapability from './BusinessCapability/views/businessCapabilityList';

import ListBusinessEpics from './BusinessEpics/views/listBusinessEpics';
import AddBusinessEpics from './BusinessEpics/views/AddBusinessEpics';
import EditBusinessEpics from './BusinessEpics/views/EditBusinessEpics';

import ListBusinessComponent from './BusinessComponents/views/listBusinessComponents';
import AddBusinessComponent from './BusinessComponents/views/AddBusinessComponents';
import EditBusinessComponent from './BusinessComponents/views/EditBusinessComponents';

import FlowChart from './FlowChart/views/flowChart';

import {
  DashboardOutlined,
  HddOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  GlobalOutlined
} from '@ant-design/icons';
const menu = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardOutlined />,
    title: 'Dashboard',
    key: 'dashboard',
    homepage: true,
    component: WebServices,
    authority: ['company', 'manager']
  },
  {
    path: '/webServices',
    name: 'Web Services',
    icon: <GlobalOutlined />,
    title: 'WebServices',
    key: 'webServices',
    homepage: true,
    component: WebServices,
    authority: ['company', 'manager']
  },
  {
    path: '/webService/:id',
    name: 'Update Web Service',
    title: 'Update Web Service',
    key: 'editWebService',
    component: AddWebService,
    dontShowOnMenu: true,
    authority: ['company']
  },
  {
    path: '/addWebService',
    name: 'Add Web Service',
    title: 'Add Web Service',
    key: 'addWebService',
    dontShowOnMenu: true,
    component: AddWebService,
    authority: ['company', 'manager']
  },
  {
    path: '/businessInitiative/:id',
    name: 'Business Initiative',
    title: 'Update Business Initiative',
    key: 'updateBusinessInitiative',
    component: UpdateBusinessInitiative,
    dontShowOnMenu: true,
    authority: ['company']
  },
  {
    path: '/addBusinessInitiative',
    name: 'Add Business Initiative',
    title: 'Add Business Initiative',
    key: 'addBusinessInitiative',
    dontShowOnMenu: true,
    component: AddBusinessInitiative,
    authority: ['company', 'manager']
  },
  {
    path: '/allBusinessInitiative',
    name: 'Business Initiative',
    icon: <HddOutlined />,
    title: 'Business Initiative',
    key: 'allBusinessInitiative',
    component: AllBusinessInitiative
  },
  {
    path: '/addBusinessCapabilities',
    name: 'Add Business Capabilities',
    title: 'Add Business Capabilities',
    key: 'addBusinessCapabilities',
    dontShowOnMenu: true,
    component: AddBusinessCapabilities
  },
  {
    path: '/AllBusinessCapability',
    name: 'Business Capability',
    icon: <ContainerOutlined />,
    title: 'AllBusinessCapability',
    key: 'AllBusinessCapability',
    // 'homepage': true,
    component: AllBusinessCapability,
    authority: ['company', 'manager']
  },
  {
    path: '/businessCapability/:id',
    name: 'Business Capability',
    title: 'Update Business Capability',
    key: 'updateBusinessCapability',
    component: EditBusinessCapabilities,
    dontShowOnMenu: true,
    authority: ['company']
  },
  {
    path: '/customer-applications',
    name: 'Customer Application',
    icon: <DesktopOutlined />,
    title: 'Application',
    key: 'cutomer-applications',
    component: listCustomerApplications,
    authority: ['company', 'manager']
  },
  {
    path: '/addCustomerapplications',
    name: 'Application',
    icon: 'file',
    title: 'Application',
    dontShowOnMenu: true,
    key: 'add-customer-applications',
    component: addcustomerApplications,
    authority: ['company', 'manager']
  },
  {
    path: '/environment/:id',
    name: 'Environments',
    title: 'Environments',
    key: 'Environments',
    // 'homepage': true,
    dontShowOnMenu: true,
    component: Environment,
    authority: ['company', 'manager']
  },
  {
    path: '/web-history/:id',
    name: 'WebHistory',
    title: 'Web History',
    key: 'web-history',
    // 'homepage': true,
    dontShowOnMenu: true,
    component: WebHistory,
    authority: ['company', 'manager']
  },
  {
    path: '/add-business-feature',
    name: 'Add Business Features',
    title: 'Add Business Features',
    key: 'addBusinessFeatures',
    dontShowOnMenu: true,
    component: AddBusinessFeatures,
    authority: ['company', 'manager']
  },
  {
    path: '/business-feature/:id',
    name: 'Business Feature',
    title: 'Update Business Feature',
    key: 'updateBusinessFeature',
    component: EditBusinessFeatures,
    dontShowOnMenu: true,
    authority: ['company']
  },
  {
    path: '/business-features-list',
    name: 'Business Features',
    icon: <DeploymentUnitOutlined />,
    title: 'Business Features',
    key: 'businessFeatures',
    component: ListBusinessFeature,
    authority: ['company', 'manager']
  },
  {
    path: '/addBusinessEpics',
    name: 'Add Business Epics',
    title: 'Add Business Epics',
    key: 'addBusinessEpics',
    dontShowOnMenu: true,
    component: AddBusinessEpics
  },
  {
    path: '/AllBusinessEpics',
    name: 'Business Epics',
    icon: <ContainerOutlined />,
    title: 'AllBusinessEpics',
    key: 'AllBusinessEpics',
    // 'homepage': true,
    component: ListBusinessEpics,
    authority: ['company', 'manager']
  },
  {
    path: '/businessEpics/:id',
    name: 'Business Epics',
    title: 'Update Business Epics',
    key: 'updateBusinessEpics',
    component: EditBusinessEpics,
    dontShowOnMenu: true,
    authority: ['company']
  },
  {
    path: '/addBusinessComponent',
    name: 'Add Business Component',
    title: 'Add Business Component',
    key: 'addBusinessComponent',
    dontShowOnMenu: true,
    component: AddBusinessComponent
  },
  {
    path: '/AllBusinessComponent',
    name: 'Business Components',
    icon: <ContainerOutlined />,
    title: 'AllBusinessComponent',
    key: 'AllBusinessComponent',
    // 'homepage': true,
    component: ListBusinessComponent,
    authority: ['company', 'manager']
  },
  {
    path: '/businessComponent/:id',
    name: 'Business Component',
    title: 'Update Business Component',
    key: 'updateBusinessComponent',
    component: EditBusinessComponent,
    dontShowOnMenu: true,
    authority: ['company']
  },
  {
    path: '/flowChart',
    name: 'Flow Chart',
    title: 'Flow Chart',
    key: 'flowChart',
    component: FlowChart,
    dontShowOnMenu: true,
    authority: ['company']
  }
];

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query));
};

export const getUrlPath = (keyString, params) => {
  if (!params) params = {};

  let keyArr = keyString.split('.');

  let val = _.find(menu, (p) => p.key === keyArr[0]);

  if (!val) {
    return `/`;
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1]);
  }

  if (!val) {
    return `/`;
  }

  let queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

  return `${val.path}?${queryString}`;
};

export const getPushPathWrapper = (keyString, params) => {
  let obj = getUrlObject(keyString);

  if (obj) {
    const path = new Path(obj.path);

    return push(path.build(params));
  }

  return 'error';
};

export const getUrlParams = (keyString, route) => {
  let obj = getUrlObject(keyString);

  if (obj) {
    const path = new Path(obj.path);

    return path.test(route);
  }

  return { error: true };
};

export const getUrlObject = (keyString) => {
  let keyArr = keyString.split('.');

  let val = _.find(menu, (p) => p.key === keyArr[0]);

  if (!val) {
    return `/`;
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, (p) => p.key === keyArr[1]);
  }

  if (!val) {
    return `/`;
  }

  return val;
};
//</editor-fold>

export default menu;
