import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import {
  Button,
  Input,
  Card,
} from 'antd'
import {
  SettingOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import styles from './styles.less'
import { TableComp } from 'sz-react-utils4'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import {
  getCustomerApplicationListData,
  deleteCustomerApplication

} from '../actions/CustomerApplicationsListAction'
import { getPushPathWrapper } from '../../routes'
import { useSelector, useDispatch } from 'react-redux';

const Dashboard = () => {

  const [stateData, setState] = useState({
    searchText: '',
    loading: false,
    total: 0,
    key: 0,
    tableData: []
  })
  const dispatch = useDispatch();
  const { CustomerApplicationList, loading } = useSelector(
    (state) => ({ CustomerApplicationList: state.customerServices.candidateApplicationDataList,
      loading: state.customerServices.loading })
  );

  useEffect(() => {
    dispatch(getCustomerApplicationListData(1));
  }, []);

  const getColumnSearchProps = (dataIndex, index) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          className={`search-value-${dataIndex}`}
          // ref={node => {
          //     searchInput = node
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          className={`search-${dataIndex}`}
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={"search"}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
    </Button>
        <Button className={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
    </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined className={`filter-menu-${dataIndex}`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return index ?
        (
          record[dataIndex] && record[dataIndex][index] && record[dataIndex][index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())

        ) :
        (
          record[dataIndex] && record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())

        )
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() =>searchInput.select())
      }
    },
    render: text => (
      text && <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[stateData.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })
  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setState({ ...stateData, searchText: selectedKeys[0] })
  }
  const handleReset = clearFilters => {
    clearFilters()
    setState({ ...stateData, searchText: '' })
  }

  const toggleModal = () => {
    dispatch(
      getPushPathWrapper('add-customer-applications')
    )
  }
  const deleteApplication = (record) => {
    dispatch(deleteCustomerApplication(record.id))
  }
  const tableDataColoumns = [
    {
      title: 'Application Name',
      key: 'applicationName',
      dataIndex: 'applicationName',
      // fixed: 'left'
      ...getColumnSearchProps('applicationName'),

    },

    {
      title: 'Current Owner',
      key: 'applicationOwnerName',
      dataIndex: 'applicationOwnerName',
      width: 125,


    },
    {
      title: 'Capabilities #',
      key: 'miniBusinessCapabilityList',
      dataIndex: 'miniBusinessCapabilityList',

      render: (val) => {
        return val.length
      }


    },
    {
      title: 'Dependent On Applications #',
      key: 'dependentApplicationList',
      dataIndex: 'dependentApplicationList',
      render: (val) => {
        return val.length
      }

    },
    {
      title: 'Impact Applications #',
      key: 'dependsOnApplicationList',
      dataIndex: 'dependsOnApplicationList',
      render: (val) => {
        return val.length
      }

    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return <div className={styles.actions}> <Button
          icon={<EditOutlined />}
          shape="circle"
          onClick={() => {
            // dispatch(
            //   getPushPathWrapper('updateBusinessCapability', { id: record.id })
            // )
          }} />

          <Button icon={<DeleteOutlined />}
            shape="circle"
            type='danger'
            onClick={() => deleteApplication(record)} />
        </div>
      }
    },


  ]
  return (<Card
    id={'dashboard'}
    loading={loading}
    title={`Application List (${CustomerApplicationList && CustomerApplicationList.length})`} extra={<Button type='primary' onClick={toggleModal}>Add Application</Button>}>

    <TableComp
      style={styles.test}
      columns={tableDataColoumns}
      // ref={table}
      bordered
      dataSource={CustomerApplicationList}
      loading={stateData.loading}
      pagination={{
        // current: 1,
        pageSizeOptions: ['10', '25', '50', '100', '500'],
        showSizeChanger: true,
      }}
      extraProps={{
        scroll: { x: true }
      }}
    />
  </Card>
  )

}
export default Dashboard