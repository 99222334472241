import axios from "axios";
import { addBusinessInitiativeURL,
    getAllBusinessInitiativeListURL,
    updateBusinessInitiativeUrl,
    getBusinessInitiativeByIdUrl,
    deleteBusinessInitiativeById
} from "../apis/businessInitiativeUrl";
import Headers from "../apis/Headers";
import { notification } from 'antd'
export const addBusinessInitiative = (inputData) => async (dispatch) => {
    dispatch({ type: 'INITIATIVE_SHOW_LOADER' });
    await axios
        .post(addBusinessInitiativeURL(), inputData, { Headers })
        .then(function (data) {
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
            dispatch({ type: 'INITIATIVE_SUCCESS_CHECKER' });

            dispatch({
                type: 'ADD_BUSINESS_INITIATIVE',
                payload: data.data,
            });
            notification.success({
                message: 'Successfully added business initiatives.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'INITIATIVE_ERROR_CHECKER' });

            notification.error({
                message: JSON.stringify(error)
            })
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
        });
};
export const getAllBusinessInitiative = (clientId) => async (dispatch) => {
    console.log(clientId)
    dispatch({ type: 'INITIATIVE_SHOW_LOADER' });
    await axios
        .get(getAllBusinessInitiativeListURL(clientId), { Headers })
        .then(function (data) {
            console.log(data)
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
            dispatch({
                type: 'BUSINESS_INITIATIVE_LIST',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
        });
};

export const updateBussinessInitiative = (inputData) => async (dispatch) => {
    dispatch({ type: 'INITIATIVE_SHOW_LOADER' });
    await axios
        .post(updateBusinessInitiativeUrl(), inputData, { Headers })
        .then(function (data) {
            console.log(data, 'updatebusiness initiative')
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
            notification.success({
                message: 'Successfully updated business features.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
        });
};

export const getBussinessInitiativeById = (initiativeId) => async (dispatch) => {
    dispatch({ type: 'INITIATIVE_SHOW_LOADER' });
    await axios
        .get(getBusinessInitiativeByIdUrl(initiativeId), { Headers })
        .then(function (data) {
            console.log(data, 'initiative data')
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
            dispatch({
                type: 'SET_BUSINESS_INITIATIVE',
                payload: data.data
            });
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
        });
};

export const deleteBusinessInitiative = (businessInitiativeId) => async (dispatch) => {
    dispatch({ type: 'INITIATIVE_SHOW_LOADER' });
    console.log(businessInitiativeId)
    await axios
        .delete(deleteBusinessInitiativeById(businessInitiativeId), { Headers })
        .then(function (data) {
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
            notification.success({
                message: 'Successfully deleted business initiatives.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            notification.error({
                message: JSON.stringify(error)
            })
            dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
        });
};

export const selectBusinessInitiative = (businessInitiative) => async (dispatch) => {
    await dispatch({ type: 'INITIATIVE_SHOW_LOADER' })
    await dispatch({ type: 'SET_BUSINESS_Initiative', payload: businessInitiative })
    await dispatch({ type: 'INITIATIVE_HIDE_LOADER' });
};