import React from 'react'

import { v4 as uuid } from 'uuid'


let inBoundHooks = [{
    "enableInboundHook": true,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
        "additionalProp1": true,
        "additionalProp2": true,
        "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string"
},
{
    "enableInboundHook": false,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
        "additionalProp1": true,
        "additionalProp2": true,
        "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string2"
},
{
    "enableInboundHook": true,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
        "additionalProp1": true,
        "additionalProp2": true,
        "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string3"
},
{
    "enableInboundHook": false,
    "endDate": "2020-05-26T08:01:35.069Z",
    "inboundChannelsMap": {
        "additionalProp1": true,
        "additionalProp2": true,
        "additionalProp3": true
    },
    "watchItemCategory": "string",
    "watchItemId": `${uuid()}`,
    "watchItemName": "string4"
}]
export const associatedMiniWebSeriveList = [{
    "clientId": "1",
    "id": `${uuid()}`,
    "rmiWSOperationType": {
        "id": `${uuid()}`,
        "operationName": "string"
    },
    "wsDescription": "string",
    "wsName": "string"
},{
    "clientId": "1",
    "id": `${uuid()}`,
    "rmiWSOperationType": {
        "id": `${uuid()}`,
        "operationName": "string1"
    },
    "wsDescription": "string1",
    "wsName": "string1"
}]
export const dependentApplicationList = [
    {
        "applicationName": "string",
        "applicationShortName": "string",
        "id": `${uuid()}`,
        "urlForLogo": "string"
    },
    {
        "applicationName": "string1",
        "applicationShortName": "string1",
        "id": `${uuid()}`,
        "urlForLogo": "string1"
    }
]
export const dependsOnApplicationList = [
    {
        "applicationName": "string",
        "applicationShortName": "string",
        "id": `${uuid()}`,
        "urlForLogo": "string"
    },
    {
        "applicationName": "string1",
        "applicationShortName": "string1",
        "id": `${uuid()}`,
        "urlForLogo": "string1"
    }
]
export const miniBusinessCapabilityList = [
    {
        "businessInitiativeId": `${uuid()}`,
        "businessInitiativeName": "string",
        "capabilityDescription": "string",
        "capabilityName": "string",
        "id": `${uuid()}`,
        "inboundNotificationHook": {
            "enableInboundHook": true,
            "endDate": "2020-06-01T09:57:09.057Z",
            "inboundChannelsMap": {
                "additionalProp1": true,
                "additionalProp2": true,
                "additionalProp3": true
            },
            "watchItemCategory": "string",
            "watchItemId": "string",
            "watchItemName": "string"
        }
    },
    {
        "businessInitiativeId": `${uuid()}`,
        "businessInitiativeName": "string1",
        "capabilityDescription": "string1",
        "capabilityName": "string1",
        "id": `${uuid()}`,
        "inboundNotificationHook": {
            "enableInboundHook": true,
            "endDate": "2020-06-01T09:57:09.057Z",
            "inboundChannelsMap": {
                "additionalProp1": true,
                "additionalProp2": true,
                "additionalProp3": true
            },
            "watchItemCategory": "string",
            "watchItemId": "string",
            "watchItemName": "string"
        }
    }
]
export const miniBusinessFeatureList = [
    {
        featureDescription: "string",
        featureName: "string",
        id: `${uuid()}`
    },
    {
        featureDescription: "string1",
        featureName: "string1",
        id: `${uuid()}`
    }
]

export default inBoundHooks