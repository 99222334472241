import React, { useState } from "react";
import {
  Dropdown,
  Menu,
  DropdownMenu,
  DropdownItem
} from "antd";

export default function BodyTypes({
  bodyTypes,
  setBodyType,
  selectedBodyType
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const menu = (
    <Menu
      onClick={({ item, bodyType }) => {
        setBodyType(bodyType);
      }}
    >
      {
        bodyTypes.map(bodyType => (
          <Menu.Item
            key={bodyType.bodyTypeName}
          >
            {bodyType.bodyTypeName}
          </Menu.Item>
        ))
      }
    </Menu>
  );
  return (
    <Dropdown
      visible={openDropdown}
      overlay={menu}
    >
      <div>{selectedBodyType}</div>
    </Dropdown>
  );
}
