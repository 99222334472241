import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import {
    Button,
    Input,
    Form,
    Drawer,
    Card,
    Tag,
    List,
    Menu,
    Dropdown
} from 'antd'
import {
    SettingOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import { timeStampFormat, dateFormat } from '../../settings'
import moment from 'moment'
import styles from './styles.less'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import { apiUrl } from '../../settings'
import { formItemLayout } from '../../modules/utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4'
import { getPushPathWrapper } from '../../routes'
import { useSelector, useDispatch } from 'react-redux';
import { getBusinessCapabilityList } from '../../BusinessCapability/actions/businessCapabilityActions'
import { getCustomerApplicationListData } from '../../CustomerApplications/actions/CustomerApplicationsListAction'
import { getWebServiceList } from '../../WebServices/actions/WebServiceActions'
import async from 'async'
const BusinessApplication = (props) => {
    const dispatch = useDispatch()

    const [form] = Form.useForm();

    const [visible, setState] = useState(false)
    const [loading, loadingState] = useState(false)

    const [tableData, setMainState] = useState([])
    const [selectBoxData, selectBoxState] = useState([])
    const [selectBoxTableData, selectBoxTableState] = useState([])

    const showDrawer = () => {
        setState(true);
        // form.setFieldsValue({ featureName: props.features.featureName })
    };
    const onClose = () => {
        setState(false)
    }

    useEffect(() => {
        setMainState(props.associatedBusinessCapabilities)
        dispatch(getBusinessCapabilityList(1))
    }, [props.associatedBusinessCapabilities])


    // useEffect(() => {
    //     if (tableData && tableData.length) {
    //         let data = businessCapabilityList.filter(item => tableData.findIndex((val) => val.id == item.id) == -1)
    //         selectBoxState(data)
    //     } else {
    //         selectBoxState(businessCapabilityList)
    //     }
    // }, [businessCapabilityList, tableData])
    const subString = (content) => {
        return content ? (content.length >= 30 ? content.substring(0, 30) + "..." : content) : ''
    }
    const { loader, candidateApplicationDataList, webServiceList } = useSelector((state) => ({
        loader: state.businessFeatures.businessFeatureLoader,
        candidateApplicationDataList: state.customerServices.candidateApplicationDataList,
        webServiceList: state.webServices.webServiceList

    }))
    useEffect(() => {
        dispatch(getCustomerApplicationListData())
        dispatch(getWebServiceList())
    }, [])

    const applicationColoumn = [
        {
            label: 'Application Name',
            key: 'applicationName',
            dataIndex: 'applicationName'
        },
        {
            label: 'Associated Capability',
            key: 'miniBusinessCapabilityList',
            dataIndex: 'miniBusinessCapabilityList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.capabilityName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Associated Web Services',
            key: 'associatedMiniWebSeriveList',
            dataIndex: 'associatedMiniWebSeriveList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.wsName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Associated Components',
            key: 'associatedComponents',
            dataIndex: 'associatedComponents',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.componentName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="0" onClick={() => {
                            // dispatch(
                            //   getPushPathWrapper('updateBusinessFeatures', { id: record.id })
                            // )

                        }}>
                            Disassociate Application
                  </Menu.Item>
                        <Menu.Divider />
                    </Menu>
                )
                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <SettingOutlined />
                    </Dropdown>
                )
            }
            // fixed: 'right'
        }
    ]
    const applicationColoumnDrawer = [
        {
            label: 'Application Name',
            key: 'applicationName',
            dataIndex: 'applicationName'
        },
        {
            label: 'Associated Feature',
            key: 'miniBusinessFeatureList',
            dataIndex: 'miniBusinessFeatureList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.featureName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Associated Web Services',
            key: 'associatedMiniWebSeriveList',
            dataIndex: 'associatedMiniWebSeriveList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.wsName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Associated Components',
            key: 'associatedComponents',
            dataIndex: 'associatedComponents',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.componentName}

                            />
                        </List.Item>
                    )}
                />
            }
        },

    ]
    const inputFields = {
        fields: [
            {

                label: 'Capability Name',
                key: 'capabilityName',
                type: 'string',
                disable: true,
                onChange: () =>{
                    form.setFieldsValue({capabilityName: props.capabilityName})
                }

            },
            {

                label: 'Feature Name',
                key: 'featureName',
                type: 'string',
                disable: true,
                onChange: () =>{
                    form.setFieldsValue({featureName: props.featureName})
                }

            },
            
            {
                label: 'Associate Application Name',
                key: 'associateApplicationName',
                dataIndex:'associateApplicationName',
                type: 'select',
                mode:'multiple',
                options: _.map(candidateApplicationDataList, (item) => ({ id: item.id, display: item.applicationName })),
                onChange: (associateApplicationName) => {
                    console.log("called",associateApplicationName, form.getFieldsValue({associateApplicationName}))
                    form.setFieldsValue({ associateApplicationName })
                }

            },
            {
                label: 'Associate Webservice Name',
                key: 'associateWebserviceName',
                dataIndex:'associateWebserviceName',
                type: 'select',
                mode:'multiple',
                options: _.map(webServiceList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: (associateWebserviceName) => {
                    console.log("called",associateWebserviceName, form.getFieldsValue({associateWebserviceName}))
                    form.setFieldsValue({ associateWebserviceName })
                }

            },
            {
                label: 'Associate Component Name',
                key: 'associateComponentName',
                dataIndex:'associateComponentName',
                type: 'select',
                mode:'multiple',
                options: _.map(webServiceList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: (associateComponentName) => {
                    console.log("called",associateComponentName, form.getFieldsValue({associateComponentName}))
                    form.setFieldsValue({ associateComponentName })
                }

            },
        ]
    }
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    const DrawerSubmit = (val) => {
        loadingState(true)
        console.log(props.features)
        async.forEach(val.associateCapabilityName, (doc, cb) => {
            // dispatch(
                // associateFeatureToBsnsCapability(doc, props.features.id)
            // )
            cb()
        }, () => {
            // dispatch(getBussinessFeature(props.features.id))
            loadingState(false)
            setState(false);
            selectBoxTableState([])
            form.resetFields();
        })
    }
    return (
        <Card
            id={'associatedBusinessApplication'}
            loading={loader}
            title={'Associative Business Application'} extra={<Button type='primary' onClick={showDrawer}>{'Associate other Business Application'}</Button>}
            style={{marginBottom:'25px'}}
        >
            <TableComp
                columns={applicationColoumn}
                bordered
                dataSource={props.associatedBusinessApplications}
                loading={loader}

            />
            <Drawer
                title={'Associate Other Business Applications'}
                placement={'right'}
                width={520}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={'right'}
            >
                <Form {...formItemLayout} onFinish={DrawerSubmit} form={form}>
                    <GetAllFormFields
                        apiUrl={apiUrl}
                        inputSchema={inputFields}
                    />
                <TableComp
                    columns={applicationColoumnDrawer}
                    bordered
                    dataSource={selectBoxTableData}
                    loading={loader}
                    />
                     <div className={styles.drawerButton}>
                        <Button onClick={() => { setState(false); form.resetFields(); selectBoxTableState([]) }}>Cancel</Button>
                        <Button type="primary" {...submitFormLayout} htmlType="submit" loading={loading}>Associate</Button>
                    </div>
                    </Form>
            </Drawer>


        </Card>
    )
}
export default BusinessApplication
