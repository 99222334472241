import React, { useEffect, useState } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import { Form, Card, Button } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux'
import { formItemLayout } from '../../modules/utils';
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import styles from './styles.less'
import { addBusinessComponent } from '../actions/BusinessComponentsAction'
const clientId = '1'

const businessCapabilityAdd = () => {
    const location = useLocation();

    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { successCheck, loading } = useSelector(
        (state) => ({
            successCheck: state.businessComponent.successCheck,
            loading: state.businessComponent.loading,

        })
    );
    const [state, setState] = useState({})

    if (successCheck) {
        form.resetFields()
    }

    const inputFields = {
        fields: [
            {
                label: 'Name',
                key: 'component_nm',
                type: 'string',
                required: true,
            },
            {
                label: 'Description',
                key: 'component_descr',
                type: 'textArea',
                required: true,
            },
            {
                label: 'Type',
                key: 'component_type',
                type: 'string',
                required: true,
            },

            {
                label: 'Expiry Date',
                key: 'expiryDate',
                dataIndex: 'expiryDate',
                type: 'date',
                required: true,
            }
        ]
    }
    const onFinish = (values) => {
        values.id = uuid();
        values.expiryDate = moment(values.expiryDate).toISOString()
        dispatch(addBusinessComponent(values))

    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title={'Add Business Component'} bordered={true}>
            <Form size={'medium'} form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...tailLayout}>
                    <div className={styles.submitDiv}>
                        <Button onClick={() => form.resetFields()}>
                            {'Clear'}
                        </Button>
                        <Button type="primary" style={{ marginLeft: '10px' }} loading={loading} htmlType="submit">
                            {'Add'}
                        </Button>

                    </div>
                </Form.Item>
            </Form>

        </Card>
    );
};

export default businessCapabilityAdd;