import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import {
  Select,
  Button,
  Card,
  Tag,
  Popconfirm,
  Menu,
  Dropdown,
  Checkbox,
} from 'antd'
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getPushPathWrapper } from '../../routes'
import {
  SettingOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { notification } from 'antd/lib'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4'

import styles from './styles.less'
import { getBussinessFeatureListForCapability, getBusinessCapabilityList, deleteFeatureFxn } from '../actions/businessFeatureActions'
import { timeStampFormat, dateFormat } from '../../settings'

const { Option } = Select
const clientId = 1
const ListBusinessFeature = (props) => {
  const dispatch = useDispatch()
  const { businessFeatureList, businessFeatureLoader, businessCapabilityList } = useSelector(
    (state) => ({
      businessCapabilityList: state.businessCapability.businessCapabilityList,
      businessFeatureList: state.businessFeatures.businessFeatureList,
      businessFeatureLoader: state.businessFeatures.businessFeatureLoader
    })
  )
  const [state, setState] = useState({ disabled: true })
  const [tableData, setMainState] = useState([])
  const selectChangeHandler = (val) => {
    setState({ ...state, capabilitiyId: '', featureId: val, disabled: false })
  }
  const result = () => {
    dispatch(getBussinessFeatureListForCapability(state.featureId))

  }
  useEffect(() => {
    dispatch(getBusinessCapabilityList(clientId))
  }, [])

  const deleteFeatures = (record) => {
    console.log(state.capabilitiyId,record.id)
    dispatch(deleteFeatureFxn(state.featureId,record.id))
  }

  const tableDataColoumns = [
    {
      title: 'Feature Name',
      key: 'featureName',
      dataIndex: 'featureName'
    },
    {
      title: 'Feature Description',
      key: 'featureDescription',
      dataIndex: 'featureDescription'
    },
    {
      title: 'Associated Applications',
      key: 'associatedApplicationId',
      dataIndex: 'associatedApplicationId'
    },
    {
      title: 'Associated Capabilities',
      key: 'businessCapabilityName',
      dataIndex: 'businessCapabilityName'
    },
    {
      title: 'Associated Web Services',
      key: 'associatedWebServiceId',
      dataIndex: 'associatedWebServiceId'
    },
    {
      title: 'Creation Date',
      key: 'creationDate',
      dataIndex: 'creationDate',
      render: (val) => {
        return val && <Tag>{moment(val).format(timeStampFormat)}</Tag>
      }
    },
    {
      title: 'Updation Date',
      key: 'updationDate',
      dataIndex: 'updationDate',
      render: (val) => {
        return val && <Tag>{moment(val).format(timeStampFormat)}</Tag>
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width:100,
      render: (text, record) => {
        return <div className={styles.actions}> <Button
          icon={<EditOutlined />}
          shape="circle"
          onClick={() => {
            dispatch(
              getPushPathWrapper('updateBusinessFeature', { id: record.id })
            )
          }} />
          <Popconfirm placement="topLeft"
            title={'Are you sure you want to delete? This will cause all the associations added to be removed.'}
            onConfirm={() => deleteFeatures(record)} okText="Yes" cancelText="No">

            <Button icon={<DeleteOutlined />}
              shape="circle"
              type='danger' />
          </Popconfirm>

        </div>
    
    }
  }
  ]

  return (
    <Card
      title={<div style={{ display: 'flex', justifyContent: 'space-between' }}>{'Business Features'}<div style={{ display: 'flex', justifyContent: 'space-around', width: '54%' }}>
        <Button type={'primary'} onClick={() => {
          dispatch(
            getPushPathWrapper('addBusinessFeatures')
          )
        }}>Download Sample Feature File</Button>
        <Button type={'primary'} onClick={() => {
          dispatch(
            getPushPathWrapper('addBusinessFeatures')
          )
        }}>Upload Feature</Button>
        <Button type={'primary'} onClick={() => {
          dispatch(
            getPushPathWrapper('addBusinessFeatures')
          )
        }}>Add Business Feature</Button></div></div>}

    >
      <div className={styles.filters}>
        <Select
          style={{ width: 200 }}
          placeholder="Select a Capability"
          onChange={selectChangeHandler}

        >
          {businessCapabilityList.map((item, key) => (<Option value={item.id} key={key}>{item.capabilityName}</Option>))}
        </Select>
        <Button type="primary" style={{ marginLeft: '10px' }} disabled={state.disabled} onClick={() => result()}>Search</Button>
      </div>
      <TableComp
        columns={tableDataColoumns}
        bordered
        dataSource={businessFeatureList}
        loading={businessFeatureLoader}
        pagination={{
          // current: 1,
          pageSizeOptions: ['10', '25', '50', '100', '500'],
          showSizeChanger: true
        }}
      />

    </Card>
  )

}

export default ListBusinessFeature
