import React from "react";

const getStatus = status => {
  switch (status) {
    case "200":
      return (
        <>
          <i className="fa fa-check fa-lg text-success"></i>
          <span className="text-success">200- OK</span>
        </>
      );

    case "400":
      return (
        <>
          <i className="fa fa-close fa-lg text-danger"></i>{" "}
          <span className="text-danger">400 - Bad Request</span>
        </>
      );

    case "401":
      return (
        <>
          <i className="fa fa-close fa-lg text-danger"></i>{" "}
          <span className="text-danger">401 - Unauthorized</span>
        </>
      );
    case "403":
      return (
        <>
          <i className="fa fa-close fa-lg text-danger"></i>{" "}
          <span className="text-danger">403 - Forbidden</span>
        </>
      );
    case "404":
      return (
        <>
          <i className="fa fa-close fa-lg text-danger"></i>{" "}
          <span className="text-danger">404 - Not Found</span>
        </>
      );
    case "405":
      return (
        <>
          <i className="fa fa-close fa-lg text-danger"></i>{" "}
          <span className="text-danger">405 - Method Not Allowed</span>
        </>
      );
    case "500":
      return (
        <>
          <i className="fa fa-close fa-lg text-danger"></i>{" "}
          <span className="text-danger">500 - Internal Server Error</span>
        </>
      );
    default:
      return "Unknown";
  }
};

export default getStatus;
