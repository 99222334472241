import axios from 'axios';
import {
  getMetaDataURL,
  getWebServiceListURL,
  createWebServiceURL,
  updateWebServiceURL,
  deleteWebServiceURL,
  getWebServiceInstancesURL,
  getWSRequestHistoryForEnvURL,
  testWebServiceURL,
  saveWebServiceURL,
  deleteEnvWebServiceURL,
  deleteWebServiceRequestResponseURL,
  deleteRequestsURL,
  uploadBulkRequestURL
} from '../api/urls';
import Headers from '../api/Headers';
// import { toast } from "react-toastify";
import moment from 'moment';
import { getPushPathWrapper } from '../../routes';
import { notification } from 'antd';

export const getWebServiceList = (clientId) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  await axios
    .get(getWebServiceListURL(1), { Headers })
    .then(function (data) {
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      dispatch({
        type: 'WEBSERVICE_LIST',
        payload: data.data
      });
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const getMetaData = () => async (dispatch) => {
  await axios
    .get(getMetaDataURL(), { Headers })
    .then(function (data) {
      // dispatch({ type: "WEBSERVICE_HIDE_LOADER" });
      dispatch({
        type: 'WEBSERVICE_META_DATA',
        payload: data.data
      });
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
    });
};

export const createWebService = (inputdata) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });

  await axios
    .post(createWebServiceURL(), inputdata, { Headers })
    .then(function (response) {
      dispatch({ type: 'WEBSERVICE_ADD_VALIDATED' });
      notification.success({
        message: `Successfully Added Web Service`
      });
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      // dispatch(getWebServiceList(1));
      dispatch(getPushPathWrapper('webServices'));

      // toast.success("Successfully created web service.");
    })
    .catch(function (error) {
      console.log('error', error);
      notification.error({
        message: `Error While Adding`
      });
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      dispatch({ type: 'WEBSERVICE_ADD_VALIDATED_ERROR' });
    });
};

export const updateWebService = (inputdata) => async (dispatch) => {
  // dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  await axios
    .post(updateWebServiceURL(), inputdata, { Headers })
    .then(function (response) {
      // dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      dispatch({ type: 'WEBSERVICE_ADD_VALIDATED' });

      dispatch(getWebServiceList(1));
      // toast.success("Successfully updated web service.");
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_ADD_VALIDATED_ERROR' });

      // dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const deleteWebService = (inputdata) => async (dispatch) => {
  await axios
    .delete(deleteWebServiceURL() + inputdata, { Headers })
    .then(function (response) {
      if (response.data) {
        notification.success({
          message: 'Deleted Successfully'
        });
        // toast.success("Successfully deleted web service.");
      } else {
        notification.error({
          message: 'Error while Deleting'
        });
        return;
      }
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      dispatch(getWebServiceList(1));
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      notification.error({
        message: 'Error while Deleting'
      });
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const getWebServiceInstances = (id) => async (dispatch) => {
  // dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  await axios
    .get(getWebServiceInstancesURL(id), { Headers })
    .then(function (data) {
      // dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      dispatch({
        type: 'WEBSERVICE_INSTANCES',
        payload: data.data
      });
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      // dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const getWSRequestHistoryForEnv = (id) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  await axios
    .get(getWSRequestHistoryForEnvURL(id), { Headers })
    .then(function (data) {
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      dispatch({
        type: 'WEBSERVICE_HISTORY',
        payload: data.data
      });
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const clearResponse = () => (dispatch) => {
  dispatch({ type: 'CLEAR_RESPONSE' });
};

export const testWebService = (inputdata) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_WEBSERVICE_SHOW_LOADER' });
  console.log('testWebService', JSON.stringify(inputdata));
  // inputdata.requestDate = moment().format("YYYY-MM-DD hh:mm:ss");
  inputdata.requestDate = moment().format();
  delete inputdata.responseDate;
  delete inputdata.responseData;
  delete inputdata.responseStatusCode;
  await axios
    .post(testWebServiceURL(), inputdata, { Headers })
    .then(function (response) {
      dispatch({ type: 'WEBSERVICE_WEBSERVICE_HIDE_LOADER' });
      dispatch({
        type: 'WEBSERVICE_RESPONSE_RECVD',
        payload: response
      });
      notification.success({
        message: 'Successfully sent web service.'
      });
      dispatch(getWebServiceList());
      // toast.success("Successfully sent web service.");
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error("Test web service failed: " + JSON.stringify(error));
      dispatch({
        type: 'WEBSERVICE_RESPONSE_RECVD',
        payload: error
      });
      dispatch({ type: 'WEBSERVICE_WEBSERVICE_HIDE_LOADER' });
    });
};

export const saveWebService = (inputdata) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  await axios
    .post(saveWebServiceURL(), inputdata, { Headers })
    .then(function (response) {
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
      // toast.success("Successfully saved web service.");
      dispatch(getWebServiceInstances(inputdata.rmiWSInfo.id));
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const deleteEnvWebService = (instanceEnvId, wsId) => async (
  dispatch
) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  await axios
    .delete(deleteEnvWebServiceURL(instanceEnvId), { Headers })
    .then(function (response) {
      if (response.data) {
        // toast.success("Successfully deleted web service instance.");
      } else {
        // toast.success("Something went wrong in deleting web service instance.");
      }
      dispatch(getWebServiceInstances(wsId));
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const deleteWebServiceRequestResponse = (request) => async (
  dispatch
) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  console.log('deleteWebServiceRequestResponse', request);
  await axios
    .post(deleteWebServiceRequestResponseURL(), request, { Headers })
    .then(function (response) {
      if (response.data) {
        // toast.success("Successfully deleted web service request.");
      } else {
        // toast.success("Something went wrong in deleting web service history.");
      }
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const deleteRequests = (requests) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  console.log('deleteRequests', requests);
  await axios
    .post(deleteRequestsURL(), requests, { Headers })
    .then(function (response) {
      if (response.data) {
        // toast.success("Successfully deleted.");
      } else {
        // toast.success("Something went wrong in deletion.");
      }
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};

export const uploadBulkRequest = (requests) => async (dispatch) => {
  dispatch({ type: 'WEBSERVICE_SHOW_LOADER' });
  console.log('uploadBulkRequest', requests);
  await axios
    .post(uploadBulkRequestURL(), requests, { Headers })
    .then(function (response) {
      if (response.data) {
        // toast.success("Successfully uploaded requests.");
      } else {
        // toast.success("Something went wrong in uploading requests.");
      }
    })
    .catch(function (error) {
      console.log('error', error);
      // toast.error(JSON.stringify(error));
      dispatch({ type: 'WEBSERVICE_HIDE_LOADER' });
    });
};
