import React, { PureComponent, useEffect, useState } from 'react'
import {
    Button,
    Card,
    Tag,
    Drawer,
    Table,
    Form,
    Popconfirm,
} from 'antd'
import async from 'async'
import styles from './styles.less'
import _ from 'lodash'
import moment from 'moment'
import {
    DeleteOutlined
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { timeStampFormat, apiUrl } from '../../settings'
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils4'
import { formItemLayout, submitFormLayout } from '../../modules/utils'
import {
    getBusinessCapabilityList,
    getAllBsnsInitiativesForBsnsCapabilityList,
    associateInitiativeToBsnsCapability,
    deAssociateBsnsCapabilityToBsnsInitiativeFxn
} from '../../BusinessCapability/actions/businessCapabilityActions'
import { getAllBusinessInitiative } from '../../BusinessInitiative/actions/businessInitiativeAction'
const clientId = 1
export default function AllBusinessInitiative(props) {
    const dispatch = useDispatch()
    const [visible, setState] = useState(false)
    const [form] = Form.useForm();
    const [loading, loadingState] = useState(false)
    const [selectBoxData, selectBoxState] = useState([])
    const [selectBoxTableData, selectBoxTableState] = useState([])
    const [tableData, setMainState] = useState([])

    const { businessCapabilityList, businessInitiativeList, getAllBsnsInitiativesForBsnsCapability, successFullyAdded } = useSelector((state) => ({
        businessCapabilityList: state.businessCapability.businessCapabilityList,
        getAllBsnsInitiativesForBsnsCapability: state.businessCapability.getAllBsnsInitiativesForBsnsCapability,
        successFullyAdded: state.businessCapability.successFullyAdded,
        businessInitiativeList: state.businessInitiative.businessInitiativeList,

    }))
    useEffect(() => {
        if (tableData && tableData.length) {
            let data = businessInitiativeList.filter(item => tableData.findIndex((val) => val.id == item.id) == -1)
            selectBoxState(data)
        } else {
            selectBoxState(businessInitiativeList)
        }
    }, [businessInitiativeList])
    useEffect(() => {
        dispatch(getAllBsnsInitiativesForBsnsCapabilityList(props.capabilityId.id))
    }, [props.capabilityId])
    useEffect(() => {
        dispatch(getBusinessCapabilityList(clientId))
        dispatch(getAllBusinessInitiative(clientId))

    }, [])
    useEffect(() => {
        setMainState(getAllBsnsInitiativesForBsnsCapability)
    }, [getAllBsnsInitiativesForBsnsCapability])

    const drawerTableDataColoumns = [
        {
            title: 'Initiative Name',
            key: 'initiativeName',
            dataIndex: 'initiativeName',
            fixed: 'left'
        },
        {
            title: 'Elevator Statement',
            key: 'initiativeElevatorStatement',
            dataIndex: 'initiativeElevatorStatement',
            render: (content, r) => {
                return subString(content)
            }
        },
        {
            title: 'Is Funded',
            key: 'isFunded',
            dataIndex: 'isFunded',
            render: (val) => {
                return <Tag>{val ? 'True' : 'False'}</Tag>
            }
        },
        {
            title: 'Creation Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val, record) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            },
        },
    ]
    const showDrawer = () => {
        setState(true);
        // form.setFieldsValue({ capabilityName: props.capabilityId.capabilityName })
    };
    const onClose = () => {
        setState(false);
    };
    const inputFields = {
        fields: [
            {
                label: 'Associated Capabilities',
                key: 'associatedBusinessCapabilities',
                // required: true,
                dataIndex: 'associatedBusinessCapabilities',
                type: 'select',
                options: _.map(businessCapabilityList, (item) => ({ id: item.id, display: item.capabilityName })),
                onChange: (associatedBusinessCapabilities) => {
                    form.setFieldsValue({ associatedBusinessCapabilities })
                }
            },
            {
                label: 'Initiative Name',
                key: 'businessInitiative',
                required: true,
                type: 'select',
                mode: 'multiple',
                options: _.map(selectBoxData, (item) => ({ id: item.id, display: item.initiativeName })),
                onChange: (businessInitiative) => {
                    let docs = selectBoxData.filter(item => businessInitiative.findIndex((val) => val == item.id) > -1)
                    selectBoxTableState(docs)
                    console.log(businessInitiative)
                    form.setFieldsValue({ businessInitiative })
                }
            },
        ]
    }
    const DrawerSubmit = (val) => {
        async.forEach(val.businessInitiative, (doc, cb) => {
            dispatch(associateInitiativeToBsnsCapability(props.capabilityId.id, doc))
            cb()
        }, () => {
            setState(false);
            form.resetFields();
            selectBoxTableState([])
            dispatch(getAllBsnsInitiativesForBsnsCapabilityList(props.capabilityId.id))
        })
    }

    const subString = (content) => {
        return content ? (content.length >= 30 ? content.substring(0, 30) + "..." : content) : ''
    }
    const deAssociatedFxn = (record) => {
        dispatch(deAssociateBsnsCapabilityToBsnsInitiativeFxn(props.capabilityId.id, record.id))
    }
    const tableDataColoumns = [

        {
            title: 'Initiative Name',
            key: 'initiativeName',
            dataIndex: 'initiativeName',
            fixed: 'left'
        },
        {
            title: 'Initiative Elevator Statement',
            key: 'initiativeElevatorStatement',
            dataIndex: 'initiativeElevatorStatement',
            render: (content, r) => {
                return subString(content)
            }
        },
        {
            title: 'Is Funded',
            key: 'isFunded',
            dataIndex: 'isFunded',
            render: (val) => {
                return <Tag color={val ? "#87d068" : '#f50'}>{val ? 'True' : 'False'}</Tag>
            }
        },
        {
            title: 'Creation Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val, record) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            },
        },
        {
            title: 'Action',
            key: 'action',
            width: 180,
            dataIndex: 'action',
            render: (text, record) => {
                return <Popconfirm placement="topLeft"
                    title={'Are you sure you want to Deassociate? This will cause the associations added to be removed.'}
                    onConfirm={() => { deAssociatedFxn(record) }} okText="Yes" cancelText="No">
                    <Button
                        type='danger'>
                        Deassociate
                    </Button>
                </Popconfirm>
            }
        }
    ]

    return (
        <Card
            id={'associativeBusinessInitiative'}
            title={'Associative Business Initiatives '}
            extra={<Button type={'primary'} onClick={() => { showDrawer() }}>Associative Other Business Initiative</Button>}
        >
            <TableComp
                style={styles.test}
                columns={tableDataColoumns}
                bordered
                dataSource={tableData}
                pagination={false}
                extraProps={{ scroll: { x: true } }}
            />
            <Drawer
                title="Associate Other Business Initiatives "
                placement={'right'}
                width={600}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={'right'}
            >
                <Form {...formItemLayout} form={form} onFinish={DrawerSubmit}>
                    <GetAllFormFields
                        apiurl={apiUrl}
                        inputSchema={inputFields}
                    />

                    <Table
                        columns={drawerTableDataColoumns}
                        bordered
                        dataSource={selectBoxTableData}
                        pagination={false}
                    />
                    <div className={styles.drawerButton}>
                        <Button onClick={() => { setState(false); form.resetFields(); selectBoxTableState([]) }}>Cancel</Button>
                        <Button type="primary" {...submitFormLayout} htmlType="submit" loading={loading}>Associate</Button>
                    </div>
                </Form>
            </Drawer>

        </Card>
    )
}
