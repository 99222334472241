import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import {
    Button,
    Input,
    Form,
    Table,
    Popconfirm,
    Drawer,
    Card,
} from 'antd'
import {
    SettingOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import styles from './styles.less'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import { apiUrl } from '../../settings'
import {
    getCustomerApplicationListData,
} from '../../CustomerApplications/actions/CustomerApplicationsListAction'

import { associateApplicationToBsnsCapability,deAssociateApplicationToBsnsCapbility } from '../actions/businessCapabilityActions'
import async from 'async'
import { formItemLayout, submitFormLayout } from '../../modules/utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4'
import { useSelector, useDispatch } from 'react-redux';
const clientId = 1
const Dashboard = (props) => {

    const dispatch = useDispatch();
    const [visible, setState] = useState(false)
    const [form] = Form.useForm();
    const [tableData, setMainState] = useState()
    const [selectBoxData, selectBoxState] = useState([])
    const [selectBoxTableData, selectBoxTableState] = useState([])
    const [loading, loadingState] = useState(false)

    useEffect(() => {
        setMainState(props.capabilityId.customerApplicationList)
    }, [props.capabilityId.customerApplicationList])
    const showDrawer = () => {
        setState(true);
        if (props.capabilityId) {
            form.setFieldsValue({ capabilityName: props.capabilityId.capabilityName })
        }
    };
    const onClose = () => {
        setState(false);
    };
    const { CustomerApplicationList } = useSelector(
        (state) => ({
            CustomerApplicationList: state.customerServices.candidateApplicationDataList,
            loading: state.customerServices.loading
        })
    );

    useEffect(() => {
        if (tableData && tableData.length) {
            let data = CustomerApplicationList.filter(item => tableData.findIndex((val) => val.id == item.id) == -1)
            selectBoxState(data)
        } else {
            selectBoxState(CustomerApplicationList)
        }
    }, [CustomerApplicationList,tableData])


    useEffect(() => {
        dispatch(getCustomerApplicationListData(clientId));
    }, []);

    const deAssociatedFxn = (record) => {
        dispatch(deAssociateApplicationToBsnsCapbility(props.capabilityId.id,record.id))
    }   
    const tableDataColoumns = [
        {
            title: 'Application Name',
            key: 'applicationName',
            dataIndex: 'applicationName',
            // fixed: 'left'

        },
        {
            title: 'Application Short Name',
            key: 'applicationShortName',
            dataIndex: 'applicationShortName',
            // fixed: 'left'

        },
        {
            title: 'Url For Logo',
            key: 'urlForLogo',
            dataIndex: 'urlForLogo',
            // fixed: 'left'

        },

        {
            title: 'Action',
            key: 'action',
            width: 180,
            dataIndex: 'action',
            render: (text, record) => {
                return <Popconfirm placement="topLeft"
                    title={'Are you sure you want to Deassociate? This will cause the associations added to be removed.'}
                    onConfirm={() => { deAssociatedFxn(record) }} okText="Yes" cancelText="No">
                    <Button
                        type='danger'>
                        Deassociate
                </Button>
                </Popconfirm>
            },
        }


    ]
    const DrawertableDataColoumns = [
        {
            title: 'Application Name',
            key: 'applicationName',
            dataIndex: 'applicationName',
            // fixed: 'left'

        },
        {
            title: 'Application Short Name',
            key: 'applicationShortName',
            dataIndex: 'applicationShortName',
            // fixed: 'left'

        },
        {
            title: 'Url For Logo',
            key: 'urlForLogo',
            dataIndex: 'urlForLogo',
            // fixed: 'left'

        },

    ]
    const inputFields = {
        fields: [
            {
                label: 'Initiative Name',
                key: 'initiativeName',
                type: 'string',
                disable: true,
            },
            {
                label: 'Capability Name',
                key: 'capabilityName',
                type: 'string',
                disable: true,
            },
            {
                label: 'Feature Name',
                key: 'featureName',
                type: 'string',
                disable: true,
            },
            {
                label: 'Assc. Customer Application ',
                key: 'customerApplicationList',
                type: 'select',
                mode: 'multiple',
                options: _.map(selectBoxData, (item) => ({ id: item.id, display: item.applicationName })),
                onChange: (customerApplicationList) => {
                    let docs = selectBoxData.filter(item => customerApplicationList.findIndex((val) => val == item.id) > -1)
                    selectBoxTableState(docs)
                    form.setFieldsValue({ customerApplicationList })
                }
            }
        ]
    }
    const DrawerSubmit = (val) => {
        // loadingState(true)
        async.forEach(val.customerApplicationList, (doc, cb) => {
            dispatch(associateApplicationToBsnsCapability(props.capabilityId.id, doc))
            cb()
        }, () => {
            setState(false);
            form.resetFields();
             selectBoxTableState([])
            // loadingState(false)
        })
    }
    return (<Card
        id={'dashboard'}
        loading={loading}
        title={`Associative Customer Application`} extra={<Button type='primary' onClick={() => showDrawer()}>Associative Other Customer Application</Button>}>

        <TableComp
            style={styles.test}
            columns={tableDataColoumns}
            bordered
            dataSource={tableData}
            pagination={false}
        />
        <Drawer
            title="Associate Other Customer Application "
            placement={'right'}
            width={600}
            closable={false}
            onClose={onClose}
            visible={visible}
            key={'right'}
        >
            <Form {...formItemLayout} form={form} onFinish={DrawerSubmit} >
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />

                <Table
                    style={styles.test}
                    columns={DrawertableDataColoumns}
                    bordered
                    dataSource={selectBoxTableData}
                    pagination={false}
                />
                <div className={styles.drawerButton}>
                    <Button onClick={() => { setState(false); form.resetFields(); selectBoxTableState([]) }}>Cancel</Button>
                    <Button type="primary" {...submitFormLayout} htmlType="submit" loading={loading}>Associate</Button>
                </div>
            </Form>
        </Drawer>
    </Card>
    )

}
export default Dashboard