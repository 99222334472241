/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:9a764154-5a33-43bc-aed0-3e97b862e82a",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_I6Pjvj2oq",
    "aws_user_pools_web_client_id": "7k3kgoplvflsn2pg0r4jm575m",
    "oauth": {}
};


export default awsmobile;
