import React, { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { Icon, Button, Input, Card, Tag, Menu, Dropdown, Modal } from 'antd';
import { SettingOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import styles from './styles.less';
import { TableComp } from 'sz-react-utils4';
import { getPushPathWrapper } from '../../routes';
import {
  badgeColors,
  operationTypes,
  bodyTypes,
  contentTypes,
  authTypes
} from './constants';
import {
  getWebServiceList,
  deleteWebService
} from '../actions/WebServiceActions';
import { useSelector, useDispatch } from 'react-redux';

const WebServiceList = () => {
  const [state, setState] = useState({
    searchText: '',
    loading: false,
    editId: null,
    key: 0,
    toggle: false,
    tableData: [],
    showConfirmModal: false,
    selectedRecord: null
  });
  const dispatch = useDispatch();

  const { WebApplicationList, loading } = useSelector((state) => ({
    WebApplicationList: state.webServices.webServiceList,
    loading: state.webServices.webServiceLoader
  }));

  useEffect(() => {
    dispatch(getWebServiceList(1));
  }, []);

  const getColumnSearchProps = (dataIndex, index) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          className={`search-value-${dataIndex}`}
          // ref={node => {
          // searchInput = node
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          className={`search-${dataIndex}`}
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button
          className={`reset-${dataIndex}`}
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon
        className={`filter-menu-${dataIndex}`}
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      return index
        ? record[dataIndex] &&
            record[dataIndex][index] &&
            record[dataIndex][index]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
        : record[dataIndex] &&
            record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.select())
      }
    },
    render: (text) =>
      text && (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      )
  });
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setState({ ...state, searchText: selectedKeys[0] });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: '' });
  };
  const deleteService = async () => {
    hideConfirmModal();
    dispatch(deleteWebService(state.selectedRecord.id));
  };
  const showConfirmModal = (record) => {
    setState({ ...state, selectedRecord: record, showConfirmModal: true });
  };
  const hideConfirmModal = () => {
    setState({ ...state, showConfirmModal: false });
  };

  const tableDataColoumns = [
    {
      title: 'Web Service Name',
      key: 'wsName',
      dataIndex: 'wsName',
      fixed: 'left',
      ...getColumnSearchProps('wsName')
    },

    {
      title: 'Version',
      key: 'version',
      dataIndex: 'version',
      width: 125,
      ...getColumnSearchProps('version')
    },
    {
      title: 'Operation Type',
      key: 'operationName',
      dataIndex: ['rmiWSOperationType', 'operationName'],
      filters: [...operationTypes],
      onFilter: (value, record) => {
        return record.rmiWSOperationType.operationName.indexOf(value) === 0;
      },
      render: (val) => {
        const index = _.findIndex(badgeColors, (item) => item.type === val);
        return (
          <div key={badgeColors[index] && badgeColors[index].color}>
            <Tag color={badgeColors[index] && badgeColors[index].color}>
              {val}
            </Tag>
          </div>
        );
      }
    },
    {
      title: 'Body Type',
      key: 'bodyTypeName',
      dataIndex: ['rmiWSBodyType', 'bodyTypeName'],
      filters: [...bodyTypes],
      onFilter: (value, record) => {
        return record.rmiWSBodyType.bodyTypeName.indexOf(value) === 0;
      }
    },
    {
      title: 'Content Type',
      key: 'contentTypeName',
      dataIndex: ['rmiWSContentType', 'contentTypeName'],
      filters: [...contentTypes],
      onFilter: (value, record) => {
        return record.rmiWSContentType.contentTypeName.indexOf(value) === 0;
      }
    },
    {
      title: 'Authentication Type',
      key: 'authenticationTypeName',
      dataIndex: ['rmiWSAuthenticationType', 'authenticationTypeName'],
      filters: [...authTypes],
      onFilter: (value, record) => {
        return (
          record.rmiWSAuthenticationType.authenticationTypeName.indexOf(
            value
          ) === 0
        );
      }
    },
    {
      title: 'Application Owner',
      key: 'applicationOwner',
      dataIndex: 'applicationOwner',
      ...getColumnSearchProps('applicationOwner')
    },
    {
      title: 'Consumer Applications',
      key: 'consumerApplications',
      dataIndex: 'consumerApplications',
      ...getColumnSearchProps('consumerApplications')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="0"
              onClick={() => {
                dispatch(
                  getPushPathWrapper('Environments', {
                    id: record.id,
                    name: record.wsName
                  })
                );
              }}>
              Manage Enviroment
            </Menu.Item>
            <Menu.Item
              key="1"
              onClick={() => {
                dispatch(
                  getPushPathWrapper('editWebService', { id: record.id })
                );
              }}>
              <Icon type="edit" /> Edit
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="3"
              onClick={() => {
                showConfirmModal(record);
              }}>
              <Icon type="delete" /> Delete
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <SettingOutlined />
          </Dropdown>
        );
      },
      fixed: 'right'
    }
  ];

  const onClose = () => {
    setState({
      ...state,
      visible: false
    });
  };
  return (
    <Card
      title={`Web Services (${
        WebApplicationList && WebApplicationList.length
      })`}
      id={'web-services'}
      loading={loading}
      extra={
        <Button
          type="primary"
          onClick={() => {
            dispatch(getPushPathWrapper('addWebService')); // redirection to add webservice page
          }}>
          Add Web Service Definition
        </Button>
      }>
      <TableComp
        className={styles.test}
        columns={tableDataColoumns}
        // ref={table}
        bordered
        dataSource={WebApplicationList}
        loading={state.loading}
        pagination={{
          // current: 1,
          defaultPageSize: 100,
          pageSizeOptions: ['10', '25', '50', '100', '500'],
          showSizeChanger: true
        }}
      />
      <Modal
        title="Delete webservice ?"
        visible={state.showConfirmModal}
        onOk={deleteService}
        onCancel={hideConfirmModal}>
        <p>
          Are you sure you want to delete webservice{' '}
          <strong>
            {state.selectedRecord ? state.selectedRecord.wsName : ''}
          </strong>
          ?
        </p>
      </Modal>
    </Card>
  );
};

export default WebServiceList;
