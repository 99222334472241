import React, { useCallback, useState } from 'react';
import { Modal, Form, Button } from 'antd';
import { Upload, message } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';

import './BulkUpload.less';

const BulkUpload = ({
  show,
  toggle,
  className,
  uploadBulkRequest,
  loading
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  //   const [fileNames, setFileNames] = useState([]);
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 }
  };

  const normFile = (e) => {
    console.log('Upload event:', e);
    // if (Array.isArray(e)) {
    //   return e;
    // }
    return e && e.fileList;
  };

  return (
    <div>
      <Modal
        title="Bulk Upload"
        visible={show}
        footer={null}
        onOk={uploadBulkRequest}
        onCancel={toggle}>
        <div>
          <Form
            name="validate_other"
            onFinish={(data) => {
              console.log(data, selectedFile, 'data');
              uploadBulkRequest(selectedFile);
            }}>
            <Form.Item>
              <Form.Item
                name="bulkUpload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle>
                <Upload.Dragger
                  name="files"
                  beforeUpload={(file) => {
                    setSelectedFile(file);
                    return false;
                  }}
                  action="/upload.do">
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint"></p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <Form.Item className="bulkupload-buttons">
              <Button onClick={toggle}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default BulkUpload;
