import React, { useEffect, useState } from 'react';
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import { Form, Card, Button, List, Tag, Menu, Dropdown } from 'antd';
import _ from 'lodash'
import { timeStampFormat, dateFormat } from '../../settings'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { getBussinessFeature,updateBussinessFeature } from '../actions/businessFeatureActions';
import { useLocation } from 'react-router-dom';
import  BusinessCapability  from './associatedBusinessCapability'
import  BusinessApplication  from './associatedBusinessApplication'
import  BusinessEpics  from './associatedEpicAndUserstories'


const businessFeatureAdd = () => {
    const location = useLocation();
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { currentBusinessFeature, loader } = useSelector((state) => ({
        currentBusinessFeature: state.businessFeatures.currentBusinessFeature,
        loader: state.businessFeatures.businessFeatureLoader,
    }))
    const paramsId = location.pathname.split('/')[2]
    useEffect(() => {
        if (paramsId)
            dispatch(getBussinessFeature(paramsId))
    }, [location])
    useEffect(() => {
        if (paramsId){
            currentBusinessFeature.creationDate=moment(currentBusinessFeature.creationDate)
            form.setFieldsValue({ ...currentBusinessFeature })
            console.log(currentBusinessFeature)
        }
    }, [currentBusinessFeature])

    const onFinish = (valData) => {
        valData.creationDate = moment(valData.creationDate).toISOString()
        valData.id = currentBusinessFeature.id
        dispatch(updateBussinessFeature(valData))
    }
    const inputFields = {
        fields: [

            {
                label: 'Feature Name',
                key: 'featureName',
                required: true,
            },
            {
                label: 'Feature Description',
                key: 'featureDescription',
                type: 'textarea',
                required: true,
            },
            {
                label: 'Creation Date',
                key: 'creationDate',
                dataIndex: 'creationDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Updation Date',
                key: 'updationDate',
                dataIndex: 'updationDate',
                type: 'date',
                required: true,
            }
        ]
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 12 }
        }
    }
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    return (
        <Card title={'Update Business Features'}>
            <Form form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...submitFormLayout}>
                    <Button loading={loader} type='primary' htmlType='submit'>Update</Button>
                </Form.Item>
            </Form>
            <BusinessCapability associatedBusinessCapabilities={currentBusinessFeature.capabilitiesList}
                                features={currentBusinessFeature}/>
            <BusinessApplication associatedBusinessApplication={currentBusinessFeature.customerApplicationList}/>
            <BusinessEpics associatedBusinessEpics={currentBusinessFeature.businessEpicList}/>
        </Card>
    );
};

export default businessFeatureAdd;