import React, { useEffect } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import { Form, Card, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { getBussinessInitiativeById, updateBussinessInitiative } from '../actions/businessInitiativeAction';
import { formItemLayout } from '../../modules/utils';
import { v4 as uuid } from 'uuid'
import { useLocation } from 'react-router-dom';
import moment from 'moment'

const businessInitiativeUpdate = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { loading, selectedInitiative } = useSelector(
        (state) => ({
            loading: state.businessInitiative.initiativeLoader,
            selectedInitiative: state.businessInitiative.selectedInitiative
        })
    );
    const paramsId = location.pathname.split('/')[2]
    useEffect(() => {
        if (paramsId) {
            dispatch(getBussinessInitiativeById(paramsId))
        }
    }, [location])
    useEffect(() => {
        if (paramsId) {
            form.setFieldsValue({
                ...selectedInitiative,
                actualEndDt: selectedInitiative.actualEndDt && moment(selectedInitiative.actualEndDt),
                expiryDate: selectedInitiative.expiryDate && moment(selectedInitiative.expiryDate),
                inceptionDt: selectedInitiative.inceptionDt && moment(selectedInitiative.inceptionDt),
                plannedEndDt: selectedInitiative.plannedEndDt && moment(selectedInitiative.plannedEndDt)
            })
        }
    }, [selectedInitiative])

    const inputFields = {
        fields: [
            {
                label: 'Actual End Date',
                key: 'actualEndDt',
                type: 'date',
                required: true,
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Inception Date',
                key: 'inceptionDt',
                type: 'date',
                required: true,
            },
            {
                label: 'Initiative Elevator Statement',
                key: 'initiativeElevatorStatement',
                type: 'string',
                required: true,
            },
            {
                label: 'Initiative Name',
                key: 'initiativeName',
                type: 'string',
                required: true,
            },
            {
                label: 'Funded',
                key: 'isFunded',
                type: 'switch',
                onChange: (value) => {
                    form.setFieldsValue({ 'isFunded': value })
                }
            },
            {
                label: 'Planned End Date',
                key: 'plannedEndDt',
                type: 'date',
                required: true,
            },
            {
                label: 'Total Approved Funding Amount',
                key: 'totalApprovedFundingAmount',
                type: 'number',
                required: true,
            },
            {
                label: 'Total Requested Funding Amount',
                key: 'totalFundingRequestAmount',
                type: 'number',
                required: true,
            }
        ]
    }

    const onFinish = (values) => {
        values.id = uuid();
        values.clientId = 1
        dispatch(updateBussinessInitiative(values))
    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title={'Update Business Initiative'} loading={loading} bordered={true}>
            <Form size={'medium'} form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                </Button>
                </Form.Item>
            </Form>

        </Card>
    );
};

export default businessInitiativeUpdate;