export const sample = {
    offset: {
        x: 0,
        y: 0
    },
    scale: 1,
    nodes: {
        capability: {
            id: "capability",
            type: "Capability",
            position: {
                x: 100,
                y: 300
            },
            ports: {
                port1: {
                    id: "port1",
                    type: "right",
                    properties: {
                        value: "yes"
                    }
                }
            }
        },
        feature1: {
            id: "feature1",
            type: "feature1",
            position: {
                x: 500,
                y: 100
            },
            ports: {
                port1: {
                    id: "port1",
                    type: "left"
                },
                port2: {
                    id: "port2",
                    type: "right",
                    properties: {
                        value: "yes"
                    }
                }
            }
        },
        feature2: {
            id: "feature2",
            type: "feature2",
            position: {
                x: 500,
                y: 500
            },
            ports: {
                port1: {
                    id: "port1",
                    type: "left"
                },
                port2: {
                    id: "port2",
                    type: "right",
                    properties: {
                        value: "yes"
                    }
                }
            }
        },
        application1: {
            id: "application1",
            type: "application1",
            position: {
                x: 900,
                y: 100
            },
            ports: {
                port1: {
                    id: "port1",
                    type: "left"
                },
                // port2: {
                //     id: "port2",
                //     type: "right"
                // }
            }
        },
        application2: {
            id: "application2",
            type: "application2",
            position: {
                x: 900,
                y: 500
            },
            ports: {
                port1: {
                    id: "port1",
                    type: "left"
                },
                // port2: {
                //     id: "port2",
                //     type: "right"
                // }
            }
        }
    },
    links: {
        link1: {
            id: "link1",
            from: {
                nodeId: "capability",
                portId: "port1"
            },
            to: {
                nodeId: "feature1",
                portId: "port1"
            },
        },
        link2: {
            id: "link2",
            from: {
                nodeId: "capability",
                portId: "port1"
            },
            to: {
                nodeId: "feature2",
                portId: "port1"
            },
        },
        link3: {
            id: "link3",
            from: {
                nodeId: "feature1",
                portId: "port2"
            },
            to: {
                nodeId: "application1",
                portId: "port1"
            },
        },
        link4: {
            id: "link4",
            from: {
                nodeId: "feature1",
                portId: "port2"
            },
            to: {
                nodeId: "application2",
                portId: "port1"
            },
        },
        link5: {
            id: "link5",
            from: {
                nodeId: "feature2",
                portId: "port2"
            },
            to: {
                nodeId: "application1",
                portId: "port1"
            },
        },
        link6: {
            id: "link6",
            from: {
                nodeId: "feature2",
                portId: "port2"
            },
            to: {
                nodeId: "application2",
                portId: "port1"
            },
        }
    },
    selected: {},
    hovered: {}
}