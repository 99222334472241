import React from 'react';
import { apiUrl } from '../../settings';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';

export default function Details({ state, form, formItemLayout }) {
  const detailsLeftInputTypes = {
    fields: [
      {
        label: 'Application Owner',
        key: 'applicationOwner',
      },
      {
        label: 'Archive Date',
        key: 'archiveDate',
        type: 'date',
        onChange: (archiveDate) => {
          form.setFieldsValue({ archiveDate });
        },
      },
      {
        label: 'Healthcheck URL',
        key: 'healthcheckURL',
      },
      {
        label: 'Consumer Applications',
        key: 'consumerApplications',
        type: 'select',
        mode: 'multiple',
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: ['App1', 'App2', 'App3', 'App4', 'App5', 'App6'],
        onChange: (consumerApplications) => {
          form.setFieldsValue({ consumerApplications });
        },
      },
      {
        label: 'Features',
        key: 'features',
        type: 'select',
        mode: 'multiple',
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: [
          'Email Alerts',
          'SMS Alerts',
          'Discount Enroll',
          'Discount Unenroll',
          'Other',
        ],
        onChange: (features) => {
          form.setFieldsValue({ features });
        },
      },
    ],
  };

  const detailsRightInputTypes = {
    fields: [
      {
        label: 'Service Category',
        key: 'serviceCategory',
        type: 'select',
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: ['Draft', 'Published'],
        onChange: (serviceCategory) => {
          form.setFieldsValue({ serviceCategory });
        },
      },
      {
        label: 'Deprecation Date',
        key: 'deprecationDate',
        type: 'date',
        onChange: (deprecationDate) => {
          form.setFieldsValue({ deprecationDate });
        },
      },
      {
        label: 'Capabilities',
        key: 'capabilities',
        type: 'select',
        mode: 'multiple',
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: ['Registration', 'Login', 'Enroll', 'Unenroll', 'Other'],
        onChange: (capabilities) => {
          form.setFieldsValue({ capabilities });
        },
      },
      {
        label: 'Search Label',
        key: 'searchLabel',
        type: 'select',
        mode: 'tags',
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: state.searchLabelOptions,
        onChange: (searchLabel) => {
          form.setFieldsValue({ searchLabel });
        },
      },
    ],
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        marginTop: '2%',
        justifyContent: 'space-around',
      }}>
      <div style={{ width: '50%' }}>
        <GetAllFormFields
          apiurl={apiUrl}
          inputSchema={detailsLeftInputTypes}
          formItemLayout={formItemLayout}
        />
      </div>
      <div style={{ width: '50%' }}>
        <GetAllFormFields
          apiurl={apiUrl}
          inputSchema={detailsRightInputTypes}
          formItemLayout={formItemLayout}
        />
      </div>
    </div>
  );
}
