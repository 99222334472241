import React, { useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import {
  Button,
  Input,
  Checkbox,
  DatePicker,
  Popconfirm,
  Card,
  Tag,
} from 'antd'
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getPushPathWrapper } from '../../routes'
import {
  SettingOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { notification } from 'antd/lib'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4'
import styles from './styles.less'
import { getBusinessCapabilityList, deleteCapability } from '../actions/businessCapabilityActions'
import { timeStampFormat, dateFormat } from '../../settings'
const { RangePicker } = DatePicker

const ListBusinessCapability = (props) => {
  const dispatch = useDispatch()
  const { businessCapabilityList, businessCapabilityLoader } = useSelector(
    (state) => ({
      businessCapabilityList: state.businessCapability.businessCapabilityList,
      businessCapabilityLoader: state.businessCapability.businessCapabilityLoader
    })
  )

  const [state, setState] = useState({})
  const subString = (content) => {
    return content ? (content.length >= 30 ? content.substring(0, 30) + "..." : content) : ''
  }
  useEffect(() => {
    dispatch(getBusinessCapabilityList(1))
  }, [])

  const deleteCapabilityRecord = (record) => {
    dispatch(deleteCapability(record.id))
  }
  const getColumnSearchProps = (dataIndex, index) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          className={`search-value-${dataIndex}`}
          // ref={node => {
          //     state.searchInput = node
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          className={`search-${dataIndex}`}
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
</Button>
        <Button className={`reset-${dataIndex}`} onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
</Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined className={`filter-menu-${dataIndex}`} type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return index ?
        (
          record[dataIndex] && record[dataIndex][index] && record[dataIndex][index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())

        ) :
        (
          record[dataIndex] && record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())

        )
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => state.searchInput.select())
      }
    },
    render: text => (
      text && <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })
  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    setState({ ...state, searchText: selectedKeys[0] })
  }
  const handleReset = clearFilters => {
    clearFilters()
    setState({ ...state, searchText: '' })
  }
  const tableDataColoumns = [
    {
      title: 'Name',
      key: 'capabilityName',
      dataIndex: 'capabilityName',
      fixed: 'left',
      width: 200,
      ...getColumnSearchProps('capabilityName')

    },
    {
      title: 'Description',
      key: 'capabilityDescription',
      dataIndex: 'capabilityDescription',
      render: (content, r) => {
        return subString(content)

      }
    },
    {
      title: 'Initiative Name',
      key: 'businessInitiativeName',
      dataIndex: 'businessInitiativeName',
      ...getColumnSearchProps('businessInitiativeName')

    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (val, record) => {
        return <Tag>{val}</Tag>
      }
      // ...getColumnSearchProps('businessInitiativeName')

    },
    {
      title: 'Creation Date',
      key: 'creationDate',
      dataIndex: 'creationDate',
      render: (val, record) => {
        return (val && <Tag>{moment(val).format(timeStampFormat)}</Tag>)
      },
      // ...getColumnDateSearchProps('creationDate')
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return <div className={styles.actions}> <Button
          icon={<EditOutlined />}
          shape="circle"
          onClick={() => {
            dispatch(
              getPushPathWrapper('updateBusinessCapability', { id: record.id })
            )
          }} />
          <Popconfirm placement="topLeft"
            title={'Are you sure you want to delete? This will cause all the associations added to be removed.'}
            onConfirm={() => deleteCapabilityRecord(record)} okText="Yes" cancelText="No">

            <Button icon={<DeleteOutlined />}
              shape="circle"
              type='danger' />
          </Popconfirm>

        </div>

      }
    },
  ]

  return (
    <Card
      title={`Business Capability (${businessCapabilityList && businessCapabilityList.length})`}
      extra={<Button type={'primary'} onClick={() => {
        dispatch(
          getPushPathWrapper('addBusinessCapabilities')
        )
      }}>Add Business Capability</Button>}
    >
      <TableComp
        columns={tableDataColoumns}
        bordered
        dataSource={businessCapabilityList}
        loading={businessCapabilityLoader}
        pagination={{
          // current: 1,
          pageSizeOptions: ['10', '25', '50', '100', '500'],
          showSizeChanger: true
        }}
        extraProps={{
          scroll: { x: true }
        }}
      />

    </Card>
  )

}

export default ListBusinessCapability
