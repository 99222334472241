export default (
  state = {
    webServiceLoader: false,
    response: {},
    metaData: {},
    selectedWebService: {},
    webServiceList: [],
    webServiceInstances: {},
    webServiceHistory: {},
  },
  action
) => {
  switch (action.type) {
    case "WEBSERVICE_RESPONSE_RECVD":
      return { ...state, response: action.payload };
    case "CLEAR_RESPONSE":
      return { ...state, response: {} };
    case "WEBSERVICE_META_DATA":
      return { ...state, metaData: action.payload };
    case "WEBSERVICE_LIST":
      return { ...state, webServiceList: action.payload };
    case "WEBSERVICE_INSTANCES":
      return { ...state, webServiceInstances: action.payload };
    case "WEBSERVICE_HISTORY":
      return { ...state, webServiceHistory: action.payload };
    case "WEBSERVICE_SHOW_LOADER":
      return { ...state, webServiceLoader: true };
    case "WEBSERVICE_HIDE_LOADER":
      return { ...state, webServiceLoader: false };
    case "WEBSERVICE_ADD_VALIDATED":
      return { ...state, successfullyAddedService: true };
    case "WEBSERVICE_ADD_VALIDATED_ERROR":
      return { ...state, successfullyAddedService: false };
    default:
      return state;
  }
};
