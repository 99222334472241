
const Headers = {
  "Content-Type": "application/json;",
  // Authorization: "TBD..",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":"*"
};

export const associate={
  'Content-Type': 'text/plain'
}

export default Headers;
