import React, { useEffect, useState } from 'react'
import {
    Button,
    Popconfirm,
    Form,
    Drawer,
    Card,
    Tag,
    List,
} from 'antd'
import _ from 'lodash'
import async from 'async'
import { timeStampFormat, dateFormat } from '../../settings'
import moment from 'moment'
import styles from './styles.less'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import { apiUrl } from '../../settings'
import { formItemLayout } from '../../modules/utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4'
import { useSelector, useDispatch } from 'react-redux';
import { getBusinessCapabilityList } from '../../BusinessCapability/actions/businessCapabilityActions'
import { associateFeatureToBsnsCapability, getBussinessFeature } from '../actions/businessFeatureActions'
const BusinessCapability = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [visible, setState] = useState(false)
    const [loading, loadingState] = useState(false)

    const [tableData, setMainState] = useState([])
    const [selectBoxData, selectBoxState] = useState([])
    const [selectBoxTableData, selectBoxTableState] = useState([])

    const showDrawer = () => {
        setState(true);
        form.setFieldsValue({ featureName: props.features.featureName })
    };
    const onClose = () => {
        setState(false)
    }
    const { loader, businessCapabilityList } = useSelector((state) => ({
        loader: state.businessFeatures.businessFeatureLoader,
        businessCapabilityList: state.businessCapability.businessCapabilityList,
    }))

    useEffect(() => {
        setMainState(props.associatedBusinessCapabilities)
        dispatch(getBusinessCapabilityList(1))
    }, [props.associatedBusinessCapabilities])

    useEffect(() => {
        if (tableData && tableData.length) {
            let data = businessCapabilityList.filter(item => tableData.findIndex((val) => val.id == item.id) == -1)
            selectBoxState(data)
        } else {
            selectBoxState(businessCapabilityList)
        }
    }, [businessCapabilityList, tableData])
    const subString = (content) => {
        return content ? (content.length >= 30 ? content.substring(0, 30) + "..." : content) : ''
    }
    const deAssociatedFxn = (record) => {
        // dispatch(deAssociateBsnsCapabilityToBsnsInitiativeFxn(props.capabilityId.id, record.id))
    }
    const capabilityColoumn = [
        {
            label: 'Capability Name',
            key: 'capabilityName',
            dataIndex: 'capabilityName'
        },
        {
            label: 'Description',
            key: 'capabilityDescription',
            dataIndex: 'capabilityDescription',
            render: (content, r) => {
                return subString(content)
            }
        },
        {
            label: 'Cration Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val) => {
                return <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        },
        {
            label: 'Associated Initiative',
            key: 'businessInitiativeName',
            dataIndex: 'businessInitiativeName',
        },
        {
            label: 'Associated Features',
            key: 'miniBusinessFeatureList',
            dataIndex: 'miniBusinessFeatureList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.featureName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return <Popconfirm placement="topLeft"
                    title={'Are you sure you want to Deassociate? This will cause the associations added to be removed.'}
                    onConfirm={() => { deAssociatedFxn(record) }} okText="Yes" cancelText="No">
                    <Button
                        type='danger'>
                        Deassociate
                    </Button>
                </Popconfirm>
            }
            // fixed: 'right'
        }
    ]
    const capabilityColoumnDrawer = [
        {
            label: 'Capability Name',
            title: 'Capability Name',
            key: 'capabilityName',
            dataIndex: 'capabilityName',
            width: 100,
            fixed: 'left',
        },
        {
            label: 'Description',
            title: 'Description',
            key: 'capabilityDescription',
            dataIndex: 'capabilityDescription',
            render: (content, r) => {
                return subString(content)
            }
        },
        {
            label: 'Cration Date',
            title: 'Cration Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val) => {
                return val && <Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        },
        {
            label: 'Associated Initiative',
            title: 'Associated Initiative',
            key: 'businessInitiativeName',
            dataIndex: 'businessInitiativeName',
        },
        {
            label: 'Associated Features',
            title: 'Associated Features',
            key: 'miniBusinessFeatureList',
            dataIndex: 'miniBusinessFeatureList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.featureName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Status',
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        },

    ]
    const inputFields = {
        fields: [
            {

                label: 'Feature Name',
                key: 'featureName',
                type: 'string',
                disable: true,
            },
            {
                label: 'Associate Capability',
                key: 'associateCapabilityName',
                dataIndex: 'associateCapabilityName',
                type: 'select',
                required: true,
                mode: 'multiple',
                options: _.map(selectBoxData, (item) => ({ id: item.id, display: item.capabilityName })),
                onChange: (associateCapabilityName) => {
                    let docs = selectBoxData.filter(item => typeof (associateCapabilityName) == 'string' ? (associateCapabilityName == item.id) :
                        associateCapabilityName.findIndex((val) => val == item.id) > -1)
                    selectBoxTableState(docs)
                    form.setFieldsValue({ associateCapabilityName })
                }

            },
        ]
    }
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    const DrawerSubmit = (val) => {
        loadingState(true)
        console.log(props.features)
        async.forEach(val.associateCapabilityName, (doc, cb) => {
            dispatch(
                associateFeatureToBsnsCapability(doc, props.features.id)
            )
            cb()
        }, () => {
            dispatch(getBussinessFeature(props.features.id))
            loadingState(false)
            setState(false);
            selectBoxTableState([])
            form.resetFields();
        })
    }
    return (
        <Card
            id={'associatedBusinessCapability'}
            loading={loader}
            title={'Associative Business Capability'} extra={<Button type='primary' onClick={showDrawer}>{'Associate other Business Capability'}</Button>}
            style={{ marginBottom: '25px' }}>

            <TableComp
                columns={capabilityColoumn}
                bordered
                dataSource={tableData}
                loading={loader}/>

            <Drawer
                title={'Associate Other Business Capabilities'}
                placement={'right'}
                width={600}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={'right'}>

                <Form {...formItemLayout} onFinish={DrawerSubmit} form={form}>
                    <GetAllFormFields
                        apiUrl={apiUrl}
                        inputSchema={inputFields}
                    />
                    <TableComp
                        columns={capabilityColoumnDrawer}
                        bordered
                        dataSource={selectBoxTableData}
                        extraProps={{
                            scroll: { x: true }
                        }}
                    />
                    <div className={styles.drawerButton}>
                        <Button onClick={() => { setState(false); form.resetFields(); selectBoxTableState([]) }}>Cancel</Button>
                        <Button type="primary" {...submitFormLayout} htmlType="submit" loading={loading}>Associate</Button>
                    </div>
                </Form>
            </Drawer>
        </Card>
    )
}
export default BusinessCapability
