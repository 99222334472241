import { apiUrl } from '../../settings';

export const getAllCustomerApplicationsForClientURL =  (clientId) => {
  return apiUrl + "getAllApplications?clientId=" + clientId
};

export const getCustomerApplicationURL =  () => {
  return apiUrl + "getMetaData";
};

export const getArchieveCustomerApplicationURL =  () => {
  return apiUrl + "archieveApplication";
};

export const getDeleteCustomerApplicationURL =  () => {
  return apiUrl + "deleteApplication?applicationId=";
};

export const getInsertCustomerApplicationURL =  () => {
  return apiUrl + "addNewApplication";
};

export const getUpdateCustomerApplicationURL =  () => {
  return apiUrl + "updateApplication";
};


