import React, { useState, useEffect } from 'react'
import {
    Button,
    Card,
    Spin,
    Form,
    Checkbox,
    Tabs
} from 'antd'
import _ from 'lodash'
import 'react-quill/dist/quill.snow.css';
import { notification } from 'antd/lib'
import { useDispatch, useSelector } from 'react-redux'
import { FormUtils as GetAllFormFields } from 'sz-react-utils4'
import {
    addCustomerApplication
} from '../actions/CustomerApplicationsListAction'
import { timeStampFormat, socketUrl, dateFormat, apiUrl } from '../../settings'
import moment from 'moment'
import inBoundHooks, {
    miniBusinessFeatureList, miniBusinessCapabilityList, dependsOnApplicationList, dependentApplicationList, associatedMiniWebSeriveList
} from './sampleJson'
import { v4 as uuid } from 'uuid'

const { TabPane } = Tabs
const CheckboxGroup = Checkbox.Group;
const clientId = '1'

const AddCustomerApplication = () => {
    const [outboundNotificationEvents, outboundNotificationEventsState] = useState({
        plainOptions: [
            "enableCapabilityAssocChangeEvent",
            "enableConfigChangeEvent",
            "enableFeatureAssocChangeEvent",
            "enableWSChangeEvent"
        ]
    })
    const [integrateAppsMap, integrateAppsMapState] = useState({
        plainOptions: [
            "additionalProp1",
            "additionalProp2",
            "additionalProp3"
        ]
    })
    const [state, setState] = useState({ checkField: false })
    const dispatch = useDispatch()

    const { loading, successCheck } = useSelector(
        (state) => ({ loading: state.customerServices.loading, successCheck: state.customerServices.successCheck })
    );
    
    if (successCheck) {
        form.resetFields()
    }

    const [form] = Form.useForm();
    const handleSubmit = async (values) => {
        values.id = uuid()
        values.inBoundHooks = inBoundHooks.filter(item => values.inBoundHooks.findIndex((val) => val == item.watchItemId) > -1)
        values.miniBusinessFeatureList = miniBusinessFeatureList.filter(item => values.miniBusinessFeatureList.findIndex((val) => val == item.id) > -1)
        values.miniBusinessCapabilityList = miniBusinessCapabilityList.filter(item => values.miniBusinessCapabilityList.findIndex((val) => val == item.id) > -1)
        values.dependsOnApplicationList = dependsOnApplicationList.filter(item => values.dependsOnApplicationList.findIndex((val) => val == item.id) > -1)
        values.dependentApplicationList = dependentApplicationList.filter(item => values.dependentApplicationList.findIndex((val) => val == item.id) > -1)
        values.associatedMiniWebSeriveList = associatedMiniWebSeriveList.filter(item => values.associatedMiniWebSeriveList.findIndex((val) => val == item.id) > -1)
        values.clientId = clientId
        values.createdOn = moment().toISOString()
        values.expiryDate=moment(values.expiryDate).toISOString()
        values.urlForLogo=''
        let outboundNotification = {}
        let integrateAppsMapData = {}
        _.each(integrateAppsMap.checkedList, doc => {
            integrateAppsMapData[_.camelCase(doc)] = true
        })
        _.each(outboundNotificationEvents.checkedList, doc => {
            outboundNotification[_.camelCase(doc)] = true
        })
        values.integrateAppsMap = integrateAppsMapData
        values.outboundNotificationEvents = outboundNotification
        dispatch(addCustomerApplication(values))
    }

    const onChange = checkedList => {
        outboundNotificationEventsState({
            ...outboundNotificationEvents,
            checkedList
        });
    };
    const integrateAppsMapOnChange = checkedList => {
        integrateAppsMapState({
            ...integrateAppsMap,
            checkedList
        });
    };
    const generalLeftInputTypes = {
        fields: [
            {
                label: 'Dashboard Url',
                key: 'applicationDashboardUrl',
                required: true,
            },
            {
                label: 'Name',
                key: 'applicationName',
                required: true,
            },
            {
                label: 'Owner Email',
                key: 'applicationOwnerEmail',
                required: true,
            },
            {
                label: 'Short Name',
                key: 'applicationShortName',
                required: true,
            },
            {
                label: 'Team Email Address',
                key: 'applicationTeamEmailAddress',
                required: true,
            },
            {
                label: 'Mini Business Feature',
                key: 'miniBusinessFeatureList',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(miniBusinessFeatureList, (item) => ({ id: item.id, display: item.featureName })),
                onChange: miniBusinessFeatureList => {
                    form.setFieldsValue({ miniBusinessFeatureList })
                },
            },
            {
                label: 'Mini Business Capability',
                key: 'miniBusinessCapabilityList',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(miniBusinessCapabilityList, (item) => ({ id: item.id, display: item.capabilityName })),
                onChange: miniBusinessCapabilityList => {
                    form.setFieldsValue({ miniBusinessCapabilityList })
                },
            }
        ]
    }
    const generalRightInputTypes = {
        fields: [
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                dataIndex: 'expiryDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Dependent Application',
                key: 'dependentApplicationList',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(dependentApplicationList, (item) => ({ id: item.id, display: item.applicationName })),
                onChange: dependentApplicationList => {
                    form.setFieldsValue({ dependentApplicationList })
                },
            },
            {
                label: 'DependsOn Application',
                key: 'dependsOnApplicationList',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(dependsOnApplicationList, (item) => ({ id: item.id, display: item.applicationName })),
                onChange: dependsOnApplicationList => {
                    form.setFieldsValue({ dependsOnApplicationList })
                },
            },
            {
                label: 'Assc. Mini WebSerive',
                key: 'associatedMiniWebSeriveList',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(associatedMiniWebSeriveList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: associatedMiniWebSeriveList => {
                    form.setFieldsValue({ associatedMiniWebSeriveList })
                },
            },

            {
                label: 'In Bound Hooks',
                key: 'inBoundHooks',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(inBoundHooks, (item) => ({ id: item.watchItemId, display: item.watchItemName })),
                onChange: inBoundHooks => {
                    form.setFieldsValue({ inBoundHooks })
                },
            },

        ]
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 12 }
        }
    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title="Add Customer Application"> 
            <Form form={form} name="control-hooks" onFinish={handleSubmit} {...formItemLayout} onFinishFailed={() => {
                notification.error({
                    message: 'Fill All Required Fields'
                })
                setState({ checkField: true })
            }}>
                <Spin spinning={false} delay={500} tip={'Loading...'}>
                    <Tabs type="card">
                        <TabPane tab={<div style={{ color: state.checkField ? "red" : '' }}>General</div>} key="1">
                            <div style={{ display: 'flex', width: '100%', marginTop: '2%', justifyContent: 'space-around' }}>
                                <div style={{ width: '50%' }}>
                                    {console.log(apiUrl)}
                                    <GetAllFormFields
                                        apiurl={apiUrl}
                                        inputSchema={generalLeftInputTypes}
                                        formItemLayout={formItemLayout}
                                    />

                                </div>
                                <div style={{ width: '50%' }}>
                                    <GetAllFormFields
                                        apiurl={apiUrl}
                                        inputSchema={generalRightInputTypes}
                                        formItemLayout={formItemLayout}
                                    />
                                </div>

                            </div>
                        </TabPane>
                        <TabPane tab="Details" key="2">
                            <div style={{ width: '100%', display: 'flex', marginTop: '2%', justifyContent: 'space-around' }} >
                                <Card style={{ width: '40%', marginTop: '2%', justifyContent: 'space-around' }} title="Outbound Notification Events">
                                    <CheckboxGroup
                                        options={_.map(outboundNotificationEvents.plainOptions, str => _.startCase(_.camelCase(str)))}
                                        value={_.map(outboundNotificationEvents.checkedList, str => _.startCase(_.camelCase(str)))}
                                        onChange={onChange}
                                    />
                                </Card>
                                <Card style={{ width: '40%', marginTop: '2%', justifyContent: 'space-around' }} title="Integrate Apps Map">
                                    <CheckboxGroup
                                        options={_.map(integrateAppsMap.plainOptions, str => _.startCase(_.camelCase(str)))}
                                        value={_.map(integrateAppsMap.checkedList, str => _.startCase(_.camelCase(str)))}
                                        onChange={integrateAppsMapOnChange}
                                    />
                                </Card>
                            </div>
                            <Form.Item {...tailLayout} style={{marginTop:'6px'}}>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    SUBMIT
                                </Button>
                            </Form.Item>
                        </TabPane>
                    </Tabs>

                </Spin>
            </Form>
        </Card>
    )
}

export default AddCustomerApplication