import React from 'react';
import { apiUrl } from '../../settings';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { Select, Button, Table, Switch, Form } from 'antd';
import {
  EditableCell,
  EditableFormRow
} from '../../components/_utils/editableTable';
import { DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const components = {
  body: {
    row: EditableFormRow,
    cell: EditableCell
  }
};

export default function General({
  state,
  setState,
  form,
  fieldUpdate,
  rmiWSAuthenticationInfos,
  formItemLayout,
  bodyFieldCheck
}) {
  const handleTableEdit = (row) => {
    let dataSource = [...state.dataSource];
    const index = dataSource.findIndex((item) => row.id === item.id);
    const item = dataSource[index];
    dataSource.splice(index, 1, {
      ...item,
      ...row
    });
    setState({ ...state, dataSource: [...dataSource] });
  };

  const handleDelete = (key) => {
    let dataSource = [...state.dataSource];
    dataSource = dataSource.filter((item) => item.id !== key);
    dataSource.forEach((item, index) => {
      item.id = `${index + 1}`;
    });
    setState({
      ...state,
      dataSource: dataSource
    });
  };

  const generalLeftInputTypes = {
    fields: [
      {
        label: 'Web Service Name',
        key: 'wsName',
        required: true
      },
      {
        label: 'Content Type',
        key: 'rmiWSContentType',
        value: 'rmiWSContentType',
        type: 'select',
        required: true,
        keyAccessor: (x) => x.contentTypeName,
        valueAccessor: (x) => `${x.contentTypeName}`,
        options: state.contentTypeOptions,
        onChange: (rmiWSContentType) => {
          form.setFieldsValue({ rmiWSContentType });
        }
      },
      {
        label: 'Operation Type',
        key: 'rmiWSOperationType',
        name: 'rmiWSOperationType',
        required: true,
        type: 'select',
        keyAccessor: (x) => x.operationName,
        valueAccessor: (x) => `${x.operationName}`,
        options: state.operationTypeOptions,
        onChange: (rmiWSOperationType) => {
          form.setFieldsValue({ rmiWSOperationType });
          if (rmiWSOperationType === 'GET') {
            fieldUpdate(true);
            form.setFieldsValue({ rmiWSBodyType: 'application/json' });
          } else {
            form.setFieldsValue({ rmiWSBodyType: '' });
            fieldUpdate(false);
          }
        }
      }
    ]
  };
  const generalRightInputTypes = {
    fields: [
      {
        label: 'Document Links',
        key: 'documentLinks',
        type: 'select',
        mode: 'tags',
        tokenSeparators: [','],
        options: [],
        required: true,
        onChange: (documentLinks) => {
          form.setFieldsValue({ documentLinks });
        }
      },
      {
        label: 'Authentication Type',
        key: 'rmiWSAuthenticationType',
        type: 'select',
        required: true,
        keyAccessor: (x) => x.authenticationTypeName,
        valueAccessor: (x) => `${x.authenticationTypeName}`,
        options: state.authTypeOptions,
        onChange: (rmiWSAuthenticationType) => {
          form.setFieldsValue({ rmiWSAuthenticationType });
        }
      },
      {
        label: 'Supported Feature Id',
        key: 'supportedFeatureIdList',
        type: 'select',
        mode: 'multiple',
        required: true,
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: [
          'Email Alerts',
          'SMS Alerts',
          'Discount Enroll',
          'Discount Unenroll',
          'Other'
        ],
        onChange: (supportedFeatureIdList) => {
          form.setFieldsValue({ supportedFeatureIdList });
        }
      },
      {
        label: 'Rmi AuthenticationInfos',
        key: 'rmiWSAuthenticationInfos',
        mode: 'multiple',
        required: true,
        type: 'select',
        options: rmiWSAuthenticationInfos.map((item) => ({
          id: item.id,
          display: item.authenticationKey
        })),
        onChange: (rmiWSAuthenticationInfos) => {
          form.setFieldsValue({ rmiWSAuthenticationInfos });
        }
      }
    ]
  };

  const headerColumns = [
    {
      title: 'Key',
      key: 'headerKey',
      dataIndex: 'headerKey',
      width: '20%',
      editable: true
    },
    {
      title: 'Default Value',
      key: 'headerDefaultValue',
      dataIndex: 'headerDefaultValue',
      width: '20%',
      editable: true
    },
    {
      title: 'Editable',
      key: 'editable',
      dataIndex: 'editable',
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(value) => {
              record.editable = value;
              handleTableEdit(record);
            }}
          />
        );
      }
    },
    {
      title: 'Required',
      key: 'required',
      dataIndex: 'required',
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(value) => {
              record.required = value;
              handleTableEdit(record);
            }}
          />
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) =>
        state.dataSource.length >= 1 ? (
          <Button
            shape="circle"
            // size="small"
            icon={<DeleteOutlined />}
            type="danger"
            onClick={() => {
              handleDelete(record.id);
            }}
          />
        ) : // <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
        // </Popconfirm>
        null
    }
  ];

  const columns = headerColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleTableEdit
      })
    };
  });

  const addNewDataSource = () => {
    const dataSource = [...state.dataSource];
    dataSource.push({
      headerKey: 'Key',
      headerDefaultValue: 'value',
      editable: false,
      required: true,
      id: `${dataSource.length + 1}`
    });
    setState({ ...state, dataSource: dataSource });
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          width: '100%',
          marginTop: '2%',
          justifyContent: 'space-around'
        }}>
        <div style={{ width: '50%' }}>
          <GetAllFormFields
            apiurl={apiUrl}
            inputSchema={generalLeftInputTypes}
            formItemLayout={formItemLayout}
          />

          {!bodyFieldCheck && (
            <Form.Item
              name="rmiWSBodyType"
              key="rmiWSBodyType"
              label="Body Type"
              rules={[{ required: true }]}>
              <Select
                placeholder="Body Type"
                keyAccessor={(x) => x.bodyTypeName}
                valueAccessor={(x) => `${x.bodyTypeName}`}
                onChange={(rmiWSBodyType) => {
                  form.setFieldsValue({ rmiWSBodyType });
                }}
                allowClear>
                {state.bodyTypeOptions &&
                  state.bodyTypeOptions.map((d) => {
                    return (
                      <Option value={d.bodyTypeName} id={d.id}>
                        {d.bodyTypeName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          )}
        </div>
        <div style={{ width: '50%' }}>
          <GetAllFormFields
            apiurl={apiUrl}
            inputSchema={generalRightInputTypes}
            formItemLayout={formItemLayout}
          />
        </div>
      </div>

      <Table
        id={'headers-table'}
        title={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <div>Headers</div>
            <Button
              type="default"
              onClick={() => {
                addNewDataSource();
              }}>
              Add a Row
            </Button>
          </div>
        )}
        components={components}
        rowClassName={() => 'editable-row'}
        rowKey={(record) => record.id}
        bordered
        pagination={false}
        loading={false}
        dataSource={state.dataSource}
        columns={columns}
      />
    </React.Fragment>
  );
}
