import React from 'react';
import BodyTypes from './BodyTypes';
import AceEditor from 'react-ace';

export default function Parameters({
  request,
  handleRequestChange,
  bodyTypes,
  setBodyType,
}) {
  return (
    <React.Fragment>
      <BodyTypes
        bodyTypes={bodyTypes}
        setBodyType={setBodyType}
        selectedBodyType={request.bodyType}
      />
      <AceEditor
        value={request.requestData}
        mode="javascript"
        onChange={(value) => {
          handleRequestChange({ requestData: value });
        }}
      />
    </React.Fragment>
  );
}
