import React from "react";
import { Table, Button, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
export default function Headers({ headers = [], updateHeader, removeHeader }) {
  const columns = [
    {
      title: 'Key',
      key: 'key',
      render: (val, record, index) => {
        console.log(record, index)
        return (
          <Input
            size='small'
            value={record.headerKey}
            disabled={true}
          />
        )
      }
    },
    {
      title: 'Value',
      key: 'value',
      render: (val, record, index) => (
        <Input
          size='small'
          value={record.headerValue}
          disabled={!record.editable}
          onChange={event => {
            updateHeader(index, null, event.target.value);
          }}
        />
      )
    },
    {
      title: 'Actions',
      key: 'action',
      render: (val, record, index) => (
        <Button shape='circle' icon={<DeleteOutlined />} onClick={() => removeHeader(index)} />
      )
    },
  ]
  let dataSource = Array.isArray(headers) ? headers : []
  return (
    <Table
      bordered
      size='small'
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  );
}
