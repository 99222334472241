import { apiUrl } from "../../settings";
export const getEnvironmentListUrl = (rmiWSInfoId) => {
    return apiUrl + 'getWSListForEnv?rmiWSInfoId=' + rmiWSInfoId;
};
export const getMetaDataURL = () => {
    return apiUrl + 'getMetaData';
};

export const createWebServiceURL = () => {
    return apiUrl + 'createWebService';
};

export const updateWebServiceURL = () => {
    return apiUrl + 'updateWebService';
};

export const deleteWebServiceURL = () => {
    return apiUrl + 'deleteWebService?rmiWSInfoId=';
};

export const getWebServiceInstancesURL = (id) => {
    return apiUrl + 'getWSListForEnv?rmiWSInfoId=' + id;
};

export const getWSRequestHistoryForEnvURL = (id) => {
    return apiUrl + 'getWSRequestHistoryForEnv?rmiWSInfoId=' + id;
};

export const testWebServiceURL = () => {
    return apiUrl + 'testWebService';
};

export const saveWebServiceURL = () => {
    return apiUrl + 'saveWebService';
};

export const deleteEnvWebServiceURL = (id) => {
    return apiUrl + 'deleteEnvWebService?envId=' + id;
};

export const deleteWebServiceRequestResponseURL = () => {
    return apiUrl + 'deleteWebServiceRequestResponse';
};

export const deleteRequestsURL = () => {
    return apiUrl + 'deleteRequests';
};

export const uploadBulkRequestURL = () => {
    return apiUrl + 'uploadBulkRequest';
};
