import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Table, Spin, Switch, Form, Tabs } from 'antd';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { notification } from 'antd/lib';
import { useDispatch, useSelector } from 'react-redux';

import {
  createWebService,
  getMetaData,
  updateWebService
} from '../actions/WebServiceActions';

import { v4 as uuid } from 'uuid';
import { rmiWSAuthenticationInfos } from './sampleWebServiceJson.js';
import General from './General.js';
import Details from './Details.js';
const { TabPane } = Tabs;
const clientId = '1';

const addWebService = (props) => {
  const {
    match: { params }
  } = props;
  const [state, setState] = useState({
    searchText: '',
    description: '',
    operationTypeOptions: [],
    bodyTypeOptions: [],
    checkField: '',
    contentTypeOptions: [],
    authTypeOptions: [],
    searchLabelOptions: [],
    dataSource: [
      {
        headerKey: 'Content-Type',
        headerDefaultValue: 'application/json',
        editable: false,
        required: true,
        id: '1'
      },
      {
        headerKey: 'Accept-Encoding',
        headerDefaultValue: 'gzip, deflate',
        editable: false,
        required: true,
        id: '2'
      },
      {
        headerKey: 'Accept',
        headerDefaultValue: 'application/json',
        editable: false,
        required: true,
        id: '3'
      },
      {
        headerKey: 'Accept-Charset',
        headerDefaultValue: 'utf-8',
        editable: false,
        required: true,
        id: '4'
      },
      {
        headerKey: 'Cache-Control',
        headerDefaultValue: 'no-cache',
        editable: false,
        required: true,
        id: '5'
      },
      {
        headerKey: 'Connection',
        headerDefaultValue: 'keep-alive',
        editable: false,
        required: true,
        id: '6'
      }
    ]
  });
  const [bodyFieldCheck, fieldUpdate] = useState(false);
  const dispatch = useDispatch();

  const { loading, metaData, webServiceList } = useSelector((state) => ({
    loading: state.webServices.webServiceLoader,
    metaData: state.webServices.metaData,
    webServiceList: state.webServices.webServiceList
  }));
  const [form] = Form.useForm();

  if (params.id && webServiceList.length) {
    const webServiceDefData = webServiceList.find(
      (element) => element.id === params.id
    );
    console.log('WebServiceDefData', webServiceDefData);
    const { wsName } = webServiceDefData;
    form.setFieldsValue({
      wsName,
      rmiWSContentType: 'text/plain'
    });
  }
  useEffect(() => {
    dispatch(getMetaData());
  }, []);

  useEffect(() => {
    console.log('metadata', metaData);
    if (metaData && metaData.operationTypes) {
      let options = {
        operationTypeOptions: metaData.operationTypes,
        bodyTypeOptions: metaData.bodyTypes,
        contentTypeOptions: metaData.contentTypes,
        authTypeOptions: metaData.authenticationTypes
      };
      setState({ ...state, ...options });
      form.setFieldsValue({
        rmiWSContentType: 'text/plain'
      });
    }
  }, [metaData]);

  const extractObject = (array, find = { key: '', value: '' }) => {
    const found = _.find(array, (val) => val[find.key] == find.value);
    if (!found) {
      return null;
    }
    return found;
  };

  const handleSubmit = async (valData) => {
    console.log('valData', valData);
    const {
      dataSource,
      description,
      bodyTypeOptions,
      operationTypeOptions,
      contentTypeOptions,
      authTypeOptions,
      searchLabelOptions
    } = state;
    let isHeaders = false;
    dataSource.forEach((item) => {
      if (item.headerKey == '' || item.headerDefaultValue == '') {
        isHeaders = true;
        return;
      }
    });
    if (isHeaders) {
      notification.error({
        message: 'Invalid Headers'
      });
      return;
    }
    valData.clientId = clientId;
    valData.id = uuid();
    valData.rmiWSAuthenticationInfos = rmiWSAuthenticationInfos.filter(
      (item) =>
        valData.rmiWSAuthenticationInfos.findIndex((val) => val == item.id) > -1
    );
    valData.rmiWSAuthenticationType = extractObject(authTypeOptions, {
      key: 'authenticationTypeName',
      value: valData.rmiWSAuthenticationType
    });
    valData.rmiWSBodyType = valData.rmiWSBodyType
      ? extractObject(bodyTypeOptions, {
          key: 'bodyTypeName',
          value: valData.rmiWSBodyType
        })
      : { bodyTypeName: 'none', id: '8' };
    valData.rmiWSContentType = extractObject(contentTypeOptions, {
      key: 'contentTypeName',
      value: valData.rmiWSContentType
    });
    valData.rmiWSHeaderInfos = dataSource;
    valData.rmiWSOperationType = extractObject(operationTypeOptions, {
      key: 'operationName',
      value: valData.rmiWSOperationType
    });
    valData.wsDescription = description == 'description' ? '' : description;
    dispatch(createWebService(valData));

    // setState({...state,description:'Description'})
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  };
  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 4, offset: 20 },
      md: { span: 24, offset: 0 }
    }
  };

  return (
    <Card title={`${params.id ? 'Edit' : 'Add'} Web Service`}>
      <Form
        form={form}
        name="control-hooks"
        onFinish={handleSubmit}
        {...formItemLayout}
        onFinishFailed={() => {
          notification.error({
            message: 'Fill All Required Fields'
          });
          setState({ ...state, checkField: true });
        }}>
        <Spin spinning={false} delay={500} tip={'Loading...'}>
          <Tabs type="card">
            <TabPane
              tab={
                <div style={{ color: state.checkField ? 'red' : '' }}>
                  General
                </div>
              }
              key="1">
              <General
                state={state}
                setState={setState}
                form={form}
                fieldUpdate={fieldUpdate}
                rmiWSAuthenticationInfos={rmiWSAuthenticationInfos}
                formItemLayout={formItemLayout}
                bodyFieldCheck={bodyFieldCheck}
              />
            </TabPane>
            <TabPane tab="Details" key="2">
              <Details
                state={state}
                setState={setState}
                form={form}
                fieldUpdate={fieldUpdate}
                rmiWSAuthenticationInfos={rmiWSAuthenticationInfos}
                formItemLayout={formItemLayout}
                bodyFieldCheck={bodyFieldCheck}
              />
            </TabPane>

            <TabPane tab="Documentation" key="3">
              <ReactQuill
                theme="snow"
                style={{ height: '200px', marginTop: '2%', marginBottom: '5%' }}
                value={state.description}
                onChange={(value) => {
                  setState({ ...state, description: value });
                }}
              />
            </TabPane>
          </Tabs>
        </Spin>
        <Form.Item
          style={{ textAlign: 'right', paddingTop: '20px' }}
          {...submitFormLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default addWebService;
