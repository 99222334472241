import axios from "axios";
import moment from 'moment'
import Headers,{associate} from "../api/Headers";
import {
    getBussinessFeatureListUrl,
    addNewBusinessFeature,
    updateBusinessFeature,
    getBusinessFeatureById,
    associateFeatureToBsnsCapabilityUrl,
    getBusinessCapabilityListUrl,
    deleteFeature
} from '../api/urls';
import { notification } from "antd";

export const getBussinessFeatureListForCapability = (capabilityId) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_FEATURE_SHOW_LOADER' });
    await axios
        .get(getBussinessFeatureListUrl(capabilityId), { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
            dispatch({
                type: 'BUSINESS_FEATURE_LIST',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            // notification.error({
            //     message: JSON.stringify(error)
            // })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
        });
};

export const addBussinessFeature = (inputData) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_FEATURE_SHOW_LOADER' });
    dispatch({ type: 'BUSINESS_FEATURE_ERROR' });

    await axios
        .post(addNewBusinessFeature(), inputData, { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
            dispatch({ type: 'BUSINESS_FEATURE_SUCCESS' });

            notification.success({
                message: 'Successfully added business features.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            dispatch({ type: 'BUSINESS_FEATURE_ERROR' });
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
        });
};

export const updateBussinessFeature = (inputData) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_FEATURE_SHOW_LOADER' });
    await axios
        .post(updateBusinessFeature(), inputData, { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
            notification.success({
                message: 'Successfully updated business features.'
            })
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
        });
};

export const getBussinessFeature = (businessFeatureId) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_FEATURE_SHOW_LOADER' });
    await axios
        .get(getBusinessFeatureById() + businessFeatureId, { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
            dispatch({
                type: 'SET_BUSINESS_FEATURE',
                payload: data.data
            });
        })
        .catch(function (error) {
            console.log('error', error);
            notification.error({
                message: JSON.stringify(error)
            })
            // toast.error(JSON.stringify(error));
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
        });
};

export const getBusinessCapabilityList = (clientId) => async (dispatch) => {
    dispatch({ type: 'BUSINESS_FEATURE_SHOW_LOADER' });
    await axios
        .get(getBusinessCapabilityListUrl(clientId), { Headers })
        .then(function (data) {
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
            dispatch({
                type: 'BUSINESS_CAPABILITY_LIST',
                payload: data.data,
            });
        })
        .catch(function (error) {
            console.log('error', error);
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
        });
};

export const associateFeatureToBsnsCapability = (capabilityId, inputData) => async (dispatch) => {

    await axios
        .post(associateFeatureToBsnsCapabilityUrl() + capabilityId, inputData, { headers:associate })
        .then(function (data) {
            notification.success({
                message: 'Successfully Associated business features.'
            })
            getBussinessFeatureListForCapability(capabilityId)
        })
        .catch(function (error) {
            notification.error({
                message: JSON.stringify(error)
            })

        });
};
export const deleteFeatureFxn = (capabilityId, inputdata) => async (dispatch) => {

    dispatch({ type: 'BUSINESS_FEATURE_SHOW_LOADER' });

    await axios
        .delete(deleteFeature(), {businessFeatureId:inputdata}, { Headers })
        .then(function (response) {
            console.log(response)
            if (response.data) {
                notification.success({
                    message: 'Deleted Successfully'
                })
                // toast.success("Successfully deleted web service.");
            } else {
                notification.error({
                    message: 'Error while Deleting'
                })
                dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
                return
            }
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
            dispatch(getBussinessFeatureListForCapability(capabilityId));
        })
        .catch(function (error) {
            console.log('error', error);
            // toast.error(JSON.stringify(error));
            notification.error({
                message: 'Error while Deleting'
            })
            dispatch({ type: 'BUSINESS_FEATURE_HIDE_LOADER' });
        });
};
