import React from 'react'

import { v4 as uuid } from 'uuid'


let data = [{
  "featureDescription": "string",
  "featureName": "string",
  "id": `${uuid()}`,
},
{
  "featureDescription": "string",
  "featureName": "string1",
  "id": `${uuid()}`,
},
{
  "featureDescription": "string",
  "featureName": "string2",
  "id": `${uuid()}`,
},
{
  "featureDescription": "string",
  "featureName": "string3",
  "id": `${uuid()}`,
},
{
  "featureDescription": "string",
  "featureName": "string4",
  "id": `${uuid()}`,
},
{
  "featureDescription": "string",
  "featureName": "string5",
  "id": `${uuid()}`,
}
]
export default data