import React, { useState, useEffect } from 'react'
import {
      Button,
    Card,
    Form,
    Tag,
    Popconfirm,
    Table,
    Drawer,
} from 'antd'
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import GetAllFormFields from '../../components/_utils/___formUtils'
import { TableComp } from 'sz-react-utils4'
import {
    getBussinessFeatureListForCapability,
    associateFeatureToBsnsCapability
} from '../../BusinessFeatures/actions/businessFeatureActions'
import { getAllFeaturesForCapabilityList } from '../actions/businessCapabilityActions'
import styles from './styles.less'
import { timeStampFormat, dateFormat, apiUrl } from '../../settings'
import { formItemLayout, submitFormLayout } from '../../modules/utils'

const ListBusinessFeature = (props) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const { businessFeatureList, addedSuccessFully, getAllFeaturesForCapability } = useSelector(
        (state) => ({
            businessCapabilityList: state.businessCapability.businessCapabilityList,
            getAllFeaturesForCapability: state.businessCapability.getAllFeaturesForCapability,
            businessFeatureList: state.businessFeatures.businessFeatureList,
            businessFeatureLoader: state.businessFeatures.businessFeatureLoader,
            addedSuccessFully: state.businessFeatures.addedSuccessFully

        })
    )
    const [visible, setState] = useState(false)
    const [loading, loadingState] = useState(false)

    const [tableData, setMainState] = useState([])
    const [selectBoxData, selectBoxState] = useState([])
    const [selectBoxTableData, selectBoxTableState] = useState([])

    useEffect(() => {
        setMainState(getAllFeaturesForCapability)
    }, [getAllFeaturesForCapability])


    useEffect(() => {
        if (tableData && tableData.length) {
            let data = businessFeatureList.filter(item => tableData.findIndex((val) => val.businessFeatureId == item.businessFeatureId) == -1)
            selectBoxState(data)
        } else {
            selectBoxState(businessFeatureList)
        }
    }, [businessFeatureList,tableData])

    useEffect(() => {
        if (props.capabilityId.id) {
           dispatch(getBussinessFeatureListForCapability(props.capabilityId.id))
            dispatch(getAllFeaturesForCapabilityList(props.capabilityId.id))
        }
    }, [props.capabilityId])
    const deAssociatedFxn = (record) => {


    }
    const subString = (content) => {
        return content ? (content.length >= 30 ? content.substring(0, 30) + "..." : content) : ''
    }
    const tableDataColoumns = [
        {
            title: 'Feature Name',
            key: 'businessFeatureName',
            dataIndex: 'businessFeatureName'
        },
        {
            title: 'Feature Description',
            key: 'businessFeatureDescription',
            dataIndex: 'businessFeatureDescription',
            render: (content, r) => {
                return subString(content)
            }
        },
        {
            title: 'Creation Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val) => {
                return val&&<Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width:180,
            render: (text, record) => {
                return <Popconfirm placement="topLeft"
                    title={'Are you sure you want to Deassociate? This will cause the associations added to be removed.'}
                    onConfirm={() => { deAssociatedFxn(record) }} okText="Yes" cancelText="No">
                    <Button
                        type='danger'>
                        Deassociate
                </Button>
                </Popconfirm>
            }
            // fixed: 'right'
        }
    ]
    const drawerTableDataColoumns = [
        {
            title: 'Feature Name',
            key: 'businessFeatureName',
            dataIndex: 'businessFeatureName'
        },
        {
            title: 'Feature Description',
            key: 'businessFeatureDescription',
            dataIndex: 'businessFeatureDescription',
            render: (content, r) => {
                return subString(content)

            }
        },
        {
            title: 'Creation Date',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render: (val) => {
                return val&&<Tag>{moment(val).format(timeStampFormat)}</Tag>
            }
        }
    ]
    const showDrawer = () => {
        setState(true);
        form.setFieldsValue({ capabilityName: props.capabilityId.capabilityName })
    };
    const onClose = () => {
        setState(false);
    };
    const inputFields = {
        fields: [
            {
                label: 'Capability Name',
                key: 'capabilityName',
                type: 'string',
                disabled: true
            },
            {
                label: 'Assc. Business Feature ',
                key: 'businessFeature',
                type: 'select',
                mode: 'multiple',
                required: true,
                options: _.map(selectBoxData, (item) => ({ id: item.businessFeatureId, display: item.businessFeatureName })),
                onChange: (businessFeature) => {
                    let docs = selectBoxData.filter(item => typeof (businessFeature) == 'string' ? (businessFeature == item.businessFeatureId) :
                        businessFeature.findIndex((val) => val == item.businessFeatureId) > -1)
                    selectBoxTableState(docs)
                    form.setFieldsValue({ businessFeature })
                }
            }
        ]
    }
    const DrawerSubmit = (val) => {
        loadingState(true)
        async.forEach(val.businessFeatureList, (doc, cb) => {
            associateFeatureToBsnsCapability(props.capabilityId.id, { businessFeatureId: doc })
            cb()
        }, () => {
            loadingState(false)
        })
    }
    return (
        <Card
            title={'Associative Business Features'}
            extra={<Button type={'primary'} onClick={() => { showDrawer() }}>Associative Other Business Features</Button>}
        >
            <TableComp
                columns={tableDataColoumns}
                bordered
                dataSource={tableData}
                pagination={{
                    // current: 1,
                    pageSizeOptions: ['10', '25', '50', '100', '500'],
                    showSizeChanger: true
                }}
            />

            <Drawer
                title="Associate Other Business Feature "
                placement={'right'}
                width={520}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={'right'}
            >
                <Form {...formItemLayout} form={form} onFinish={DrawerSubmit}>
                    <GetAllFormFields
                        apiurl={apiUrl}
                        inputSchema={inputFields}
                    />

                    <Table
                        columns={drawerTableDataColoumns}
                        bordered
                        dataSource={selectBoxTableData}
                        pagination={false}
                    />
                    <div className={styles.drawerButton}>
                        <Button onClick={() => { setState(false); form.resetFields(); selectBoxTableState([]) }}>Cancel</Button>
                        <Button type="primary" {...submitFormLayout} htmlType="submit" loading={loading}>Associate</Button>
                    </div>
                </Form>
            </Drawer>
        </Card>
    )

}

export default ListBusinessFeature
