export default {}

// export const pageTitle = 'API Visualizer - The nextgen for API Managmenet'
export const pageTitle = 'API Visualizer'
export const subTitle = 'Powered by Sense & Respond'
export const SITEKEY = '6Lf43e4UAAAAAHNjiZf2O_ksmatlFp0chweY6Uxc'

// ================= Local Server =================
export const socketUrl = 'http://localhost:8349'
// export const apiUrl = 'http://107.191.45.180:8080/rmi-webservices-api/'
// export const apiUrl = 'http://ec2-52-91-144-174.compute-1.amazonaws.com:8080/rmi-webservices-api/'
export const apiUrl = 'https://web.next-gen-it.io/api/'



export const dateFormat = 'MM/DD/YYYY'
export const timeStampFormat = 'MM/DD/YYYY HH:mm A'
