import React, { useEffect, useState } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import { Form, Card, Button } from 'antd';
import epicList from './sampleEpic.js';
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { addBussinessFeature, getBussinessFeature } from '../actions/businessFeatureActions';
import { getBusinessCapabilityList } from '../../BusinessCapability/actions/businessCapabilityActions'
import { getCustomerApplicationListData } from '../../CustomerApplications/actions/CustomerApplicationsListAction'
import { getWebServiceList } from '../../WebServices/actions/WebServiceActions'
const clientId=1
const businessFeatureAdd = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { loader, businessCapabilityList, addedSuccessFully, candidateApplicationDataList, webServiceList } = useSelector((state) => ({
        loader: state.businessFeatures.businessFeatureLoader,
        businessCapabilityList: state.businessCapability.businessCapabilityList,
        addedSuccessFully: state.businessFeatures.addedSuccessFully,
        candidateApplicationDataList: state.customerServices.candidateApplicationDataList,
        webServiceList: state.webServices.webServiceList

    }))
    useEffect(() => {
        dispatch(getBusinessCapabilityList(clientId))
        dispatch(getCustomerApplicationListData(clientId))
        dispatch(getWebServiceList(clientId))

    }, [])

    useEffect(() => {
        if (addedSuccessFully) {
            form.resetFields()
        }
    }, [addedSuccessFully])

    const onFinish = (valData) => {
        console.log(valData.associatedBusinessCapabilities)
        valData.businessEpicList = epicList.filter(item => valData.businessEpicList.findIndex((val) => val == item.id) > -1)
        valData.capabilitiesList = businessCapabilityList.filter(item => valData.capabilitiesList.findIndex((val) => val == item.id) > -1)
        valData.associatedApplicationId = candidateApplicationDataList.filter(item => valData.associatedApplicationId.findIndex((val) => val == item.id) > -1)
        valData.associatedWebServiceId = webServiceList.filter(item => valData.associatedWebServiceId.findIndex((val) => val == item.id) > -1)
        valData.associatedComponentId = webServiceList.filter(item => valData.associatedComponentId.findIndex((val) => val == item.id) > -1)
        valData.associatedProjectId = webServiceList.filter(item => valData.associatedProjectId.findIndex((val) => val == item.id) > -1)
        valData.associatedUserStoriesId = webServiceList.filter(item => valData.associatedUserStoriesId.findIndex((val) => val == item.id) > -1)

        valData.expiryDate = moment(valData.expiryDate).toISOString()
        valData.creationDate = moment().toISOString()
        valData.id = uuid()
        dispatch(addBussinessFeature(valData))

    }
    const inputFields = {
        fields: [

            {
                label: 'Feature Name',
                key: 'featureName',
                required: true,
            },
            {
                label: 'Feature Description',
                key: 'featureDescription',
                type: 'textarea',
                required: true,
            },
            {
                label: 'Business Capabilities',
                key: 'businessCapabilityId',
                dataIndex: 'businessCapabilityId',
                required: true,
                type: 'select',
                options: _.map(businessCapabilityList, (item) => ({ id: item.id, display: item.capabilityName })),
                onChange: (businessCapabilityId) => {
                    form.setFieldsValue({ businessCapabilityId })
                }
            },
            {
                label: 'Associated Capabilities',
                key: 'capabilitiesList',
                required: true,
                dataIndex: 'capabilitiesList',
                type: 'select',
                mode: 'multiple',
                options: _.map(businessCapabilityList, (item) => ({ id: item.id, display: item.capabilityName })),
                onChange: (capabilitiesList) => {
                    form.setFieldsValue({ capabilitiesList })
                }
            },
            {
                label: 'Associated Applications',
                key: 'associatedApplicationId',
                required: true,
                dataIndex: 'associatedApplicationId',
                type: 'select',
                mode: 'multiple',
                options: _.map(candidateApplicationDataList, (item) => ({ id: item.id, display: item.applicationName })),
                onChange: (associatedApplicationId) => {
                    form.setFieldsValue({ associatedApplicationId })
                }
            },
            {
                label: 'Associated Web Service',
                key: 'associatedWebServiceId',
                required: true,
                dataIndex: 'associatedWebServiceId',
                type: 'select',
                mode: 'multiple',
                showSearch: true,
                options: _.map(webServiceList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: (associatedWebServiceId) => {
                    form.setFieldsValue({ associatedWebServiceId })
                }
            },
            {
                label: 'Associated Components',
                key: 'associatedComponentId',
                required: true,
                dataIndex: 'associatedComponentId',
                type: 'select',
                mode: 'multiple',
                showSearch: true,
                options: _.map(webServiceList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: (associatedComponentId) => {
                    form.setFieldsValue({ associatedComponentId })
                }
            },
            {
                label: 'Associated Project Name',
                key: 'associatedProjectId',
                required: true,
                dataIndex: 'associatedProjectId',
                type: 'select',
                mode: 'multiple',
                showSearch: true,
                options: _.map(webServiceList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: (associatedProjectId) => {
                    form.setFieldsValue({ associatedProjectId })
                }
            },
            {
                label: 'Business Epics',
                key: 'businessEpicList',
                dataIndex: 'businessEpicList',
                type: 'select',
                mode: 'multiple',
                options: _.map(epicList, (item) => ({ id: item.id, display: item.epicName })),
                onChange: (businessEpicList) => {
                    form.setFieldsValue({ businessEpicList })
                }
            },
            {
                label: 'Associated User Stories',
                key: 'associatedUserStoriesId',
                required: true,
                dataIndex: 'associatedUserStoriesId',
                type: 'select',
                mode: 'multiple',
                showSearch: true,
                options: _.map(webServiceList, (item) => ({ id: item.id, display: item.wsName })),
                onChange: (associatedUserStoriesId) => {
                    form.setFieldsValue({ associatedUserStoriesId })
                }
            },
        ]
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
            md: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
            md: { span: 12 }
        }
    }
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    return (
        <Card title={`${'Add'} Business Features`}>
            <Form form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...submitFormLayout}>
                    <Button loading={loader} type='primary' htmlType='submit'>{'Add'}</Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default businessFeatureAdd;