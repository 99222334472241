function reducer(state = { data: "", ComponentDataList: [] }, action) {

  switch (action.type) {
    case "ALL_COMPONENTS_LIST_DATA":
      return {
        ...state,
        ComponentDataList: action.payload
      };
      case "SET_BUSINESS_COMPONENT":
      return {
        ...state,
        getComponentData: action.payload
      };  
    case "SHOW_LOADER":
      return {
        ...state,
        loading: true
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false
      };
    case "SUCCESS_CHECKER":
      return {
        ...state,
        successCheck: true
      };
    case "ERROR_CHECKER":
      return {
        ...state,
        successCheck: false
      };
    default:
      return state;
  }
}

export default reducer;
