export default (
    state = {
      businessFeatureLoader: false,
      businessFeatureList: [],
      currentBusinessFeature: {}
    },
    action
  ) => {
    switch (action.type) {
      case "BUSINESS_FEATURE_LIST":
        return { ...state, businessFeatureList: action.payload };
      case "BUSINESS_FEATURE_SHOW_LOADER":
        return { ...state, businessFeatureLoader: true };
      case "BUSINESS_FEATURE_HIDE_LOADER":
        return { ...state, businessFeatureLoader: false };
      case "SET_BUSINESS_FEATURE":
        return { ...state, currentBusinessFeature: action.payload };
        case "BUSINESS_FEATURE_SUCCESS":
        return { ...state, addedSuccessFully: true };
      case "BUSINESS_FEATURE_ERROR":
        return { ...state, addedSuccessFully: false };
      default:
        return state;
    }
  };
  