export const badgeColors = [
  { type: 'GET', color: 'green' },
  { type: 'POST', color: 'blue' },
  { type: 'PUT', color: 'purple' },
  { type: 'DELETE', color: 'red' },
  { type: 'PURGE', color: 'volcano' },
  { type: 'COPY', color: 'geekblue' },
  { type: 'OPTIONS', color: 'gold' },
  { type: 'VIEW', color: 'magenta' },
];

export const operationTypes = [
  {
    text: 'GET',
    value: 'GET',
  },
  {
    text: 'POST',
    value: 'POST',
  },
  {
    text: 'PUT',
    value: 'PUT',
  },
  {
    text: 'DELETE',
    value: 'DELETE',
  },
  {
    text: 'COPY',
    value: 'COPY',
  },
  {
    text: 'OPTION',
    value: 'OPTION',
  },
  {
    text: 'PURGE',
    value: 'PURGE',
  },
  {
    text: 'VIEW',
    value: 'VIEW',
  },
];

export const bodyTypes = [
  {
    text: 'text/plain',
    value: 'text/plain',
  },
  {
    text: 'text/xml',
    value: 'text/xml',
  },
  {
    text: 'text/html',
    value: 'text/html',
  },
  {
    text: 'application/json',
    value: 'application/json',
  },
  {
    text: 'application/x-www-form-urlencoded',
    value: 'application/x-www-form-urlencoded',
  },
  {
    text: 'application/xml',
    value: 'application/xml',
  },
  {
    text: 'application/javascript',
    value: 'application/javascript',
  },
  {
    text: 'none',
    value: 'none',
  },
];

export const authTypes = [
  {
    text: 'No Auth',
    value: 'No Auth',
  },
  {
    text: 'Basic Auth',
    value: 'Basic Auth',
  },
  {
    text: 'Digest Auth',
    value: 'Digest Auth',
  },
  {
    text: 'OAuth 1.0',
    value: 'OAuth 1.0',
  },
  {
    text: 'OAuth 2.0',
    value: 'OAuth 2.0',
  },
  {
    text: 'Hawk Authentication',
    value: 'Hawk Authentication',
  },
  {
    text: 'AWS Signature',
    value: 'AWS Signature',
  },
];

export const contentTypes = [
  {
    text: 'text/plain',
    value: 'text/plain',
  },
  {
    text: 'text/xml',
    value: 'text/xml',
  },
  {
    text: 'text/html',
    value: 'text/html',
  },
  {
    text: 'application/json',
    value: 'application/json',
  },
  {
    text: 'application/x-www-form-urlencoded',
    value: 'application/x-www-form-urlencoded',
  },
  {
    text: 'application/xml',
    value: 'application/xml',
  },
  {
    text: 'application/javascript',
    value: 'application/javascript',
  },
];
