function reducer(state = { data: "", epicsDataList: [] }, action) {

  switch (action.type) {
    case "ALL_EPICS_LIST_DATA":
      return {
        ...state,
        epicsDataList: action.payload
      };
      case "SET_BUSINESS_EPIC":
      return {
        ...state,
        getEpicsData: action.payload
      };  
    case "SHOW_LOADER":
      return {
        ...state,
        loading: true
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false
      };
    case "SUCCESS_CHECKER":
      return {
        ...state,
        successCheck: true
      };
    case "ERROR_CHECKER":
      return {
        ...state,
        successCheck: false
      };
    default:
      return state;
  }
}

export default reducer;
