import React, { PureComponent, useEffect, useState } from 'react';
import { Icon, Button, List, Table, Card, Tag, Menu, Dropdown } from 'antd';
import { getPushPathWrapper } from '../../routes';
import styles from './styles.less';
import _ from 'lodash';
import { SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getEnvironmentList } from '../actions/environmentActions';
import WebServiceTester from '../../WebServiceTester';
import sampleRequest from '../../WebServices/sampleRequest.json';
import {
  clearResponse,
  deleteEnvWebService,
  saveWebService,
  testWebService,
  getWebServiceInstances,
  getMetaData
} from '../actions/environmentActions';
import { TableComp } from 'sz-react-utils4';

export default function Environment(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const rmiwsInfoId = location.pathname.split('/')[2];

  const { environmentList, loading } = useSelector((state) => ({
    environmentList: state.environment.environmentList,
    loading: state.environment.environmentLoader
  }));

  const {
    metaData,
    response,
    webServiceLoader,
    webServiceInstances
  } = useSelector((state) => ({
    metaData: state.environment.metaData,
    response: state.environment.response,
    webServiceLoader: state.webServices.webServiceLoader,
    webServiceInstances: state.environment.webServiceInstances
  }));
  const [state, setState] = useState({
    searchText: '',
    total: 0,
    key: 0,
    tableData: [],
    name: '',
    bodyType: '',
    request: { ...sampleRequest },
    isUrlEditable: true
  });

  const [isVisible, setVisible] = useState(false);
  const toggleModal = () => {
    setVisible((prevState) => !prevState);
  };
  useEffect(() => {
    dispatch(getEnvironmentList(rmiwsInfoId));
    dispatch(getWebServiceInstances(rmiwsInfoId));
    dispatch(getMetaData());
  }, [location]);

  useEffect(() => {
    const { rmiWSEnvInfos, rmiwsInfo } = environmentList;
    if (rmiWSEnvInfos)
      setState({
        ...state,
        tableData: rmiWSEnvInfos,
        name: rmiwsInfo.wsName,
        bodyType: rmiwsInfo.rmiWSOperationType.operationName,
        total: rmiWSEnvInfos.length,
        loading: false
      });
  }, [environmentList]);

  const { key, bodyType } = state;
  const badgeColors = [
    { type: 'GET', color: 'green' },
    { type: 'POST', color: 'blue' },
    { type: 'PUT', color: 'purple' },
    { type: 'DELETE', color: 'red' },
    { type: 'PURGE', color: 'volcano' },
    { type: 'COPY', color: 'geekblue' },
    { type: 'OPTIONS', color: 'gold' },
    { type: 'VIEW', color: 'magenta' }
  ];

  const renderBodyType = (badgeColors, bodyType) => {
    let index = _.findIndex(badgeColors, (item) => item.type == bodyType);
    return <Tag color={badgeColors[index].color}>{bodyType}</Tag>;
  };
  const addInstance = () => {
    dispatch(clearResponse());
    let request = {
      ...sampleRequest,
      rmiWSEnvRequestHeaderList: environmentList.rmiwsInfo.rmiWSHeaderInfos.map(
        (header) => {
          header.headerValue = header.headerDefaultValue;
          header.headerKeyId = header.id;
          header.headerKeyName = header.headerKey;
          return header;
        }
      )
    };
    setState({ request, isUrlEditable: true });
    toggleModal();
  };
  const editInstance = (request, isUrlEditable) => {
    dispatch(clearResponse());
    let requestEdit = {
      ...request,
      rmiWSEnvRequestHeaderList: environmentList.rmiwsInfo.rmiWSHeaderInfos.map(
        (header) => {
          header.headerValue = header.headerDefaultValue;
          header.headerKeyId = header.id;
          header.headerKeyName = header.headerKey;
          return header;
        }
      )
    };
    setState({ ...state, request: requestEdit, isUrlEditable });
    toggleModal();
  };
  const testInstance = (instance) => {
    testWebService(instance);
    setState({ ...state, reloadData: true });
  };

  const toggleInstanceConfirmationPopup = (row) => {
    setState({
      ...state,
      deleteInstanceConfirmation: !state.deleteInstanceConfirmation,
      selectedInstanceId: row.envId
    });
  };

  const deleteInstance = (record) => {
    dispatch(
      deleteEnvWebService(record.envId, webServiceInstances.rmiwsInfo.id)
    );
  };
  const handleRequestChange = (change) => {
    setState({
      ...state,
      request: {
        ...state.request,
        ...change
      }
    });
  };
  const clearAll = () => {
    dispatch(clearResponse());
    setState((prev) => ({ ...prev, request: { ...sampleRequest } }));
    console.log(state);
  };

  const tableDataColoumns = [
    { title: 'Environment', key: 'envName', dataIndex: 'envName' },
    {
      title: 'URL',
      key: 'requestURL',
      dataIndex: 'requestURL'
    },
    {
      title: 'Header (key, value)',
      key: 'rmiWSEnvRequestHeaderList',
      dataIndex: 'rmiWSEnvRequestHeaderList',
      render: (record) => {
        return (
          record &&
          record.length > 0 && (
            <List
              bordered
              size="small"
              dataSource={record}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.headerKeyName} />
                  <div>{item.headerValue}</div>
                </List.Item>
              )}
            />
          )
        );
      }
    },
    {
      title: 'Response Status Code',
      key: 'responseStatusCode',
      dataIndex: 'responseStatusCode',
      render: (val) => {
        return val == '200' ? (
          <Tag icon={<Icon type="checked" color="green" />} color="green">
            {val}-OK
          </Tag>
        ) : (
          <Tag icon={<Icon type="checked" color="red" />} color="red">
            {val}-NOT OK
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="0"
              onClick={() => {
                dispatch(
                  getPushPathWrapper('web-history', {
                    id: location.pathname.split('/')[2]
                  })
                );
              }}>
              View Existing Requests
            </Menu.Item>
            <Menu.Divider />

            <Menu.Item key="1" onClick={() => editInstance(record, true)}>
              Edit Environment details
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="2">Create new Request</Menu.Item>
            <Menu.Divider />

            <Menu.Item key="3">Resend same request</Menu.Item>
            <Menu.Divider />

            <Menu.Item key="4" onClick={() => deleteInstance(record)}>
              Delete environment
            </Menu.Item>
            <Menu.Divider />

            <Menu.Item key="5">Bulk Upload Requests</Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <SettingOutlined />
          </Dropdown>
        );
      }
      // fixed: 'right'
    }
  ];
  return (
    <Card
      id={'manageEnv'}
      title={
        bodyType && (
          <div>
            Existing Environments for ({state.name}){' '}
            {state.bodyType && renderBodyType(badgeColors, state.bodyType)}{' '}
          </div>
        )
      }
      extra={
        <Button type="primary" onClick={addInstance}>
          Create New Environment Instance
        </Button>
      }>
      <TableComp
        style={styles.test}
        columns={tableDataColoumns}
        bordered
        dataSource={environmentList.rmiWSEnvInfos}
        loading={loading}
        pagination={{
          // current: 1,
          pageSizeOptions: ['10', '25', '50', '100', '500'],
          showSizeChanger: true
        }}
      />
      <WebServiceTester
        selectedWS={environmentList.rmiwsInfo}
        request={state.request}
        handleRequestChange={handleRequestChange}
        isOpen={isVisible}
        isUrlEditable={state.isUrlEditable}
        testWebService={(data) => dispatch(testWebService(data))}
        saveWebService={(data) => dispatch(saveWebService(data))}
        loader={loading}
        webServiceLoader={webServiceLoader}
        metaData={metaData}
        response={response}
        toggleModal={toggleModal}
        clearResponse={() => dispatch(clearResponse())}
        clearAll={clearAll}
      />
    </Card>
  );
}
