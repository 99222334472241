export const parseUrl = (url, view) => {
  if (url && url.length > 1) {
    let queryString = url.split('?');
    let params = {};
    if (queryString.length > 2) {
      console.log('invalid url');
    }
    if (queryString.length === 2 && queryString[1]) {
      params =
        view === 'bulkedit'
          ? parseQueryString(queryString[1])
          : parseQueryStringKeyValue(queryString[1]);
    }
    return params;
  }
  return '';
};

export const parseQueryString = (query) => {
  let vars = query.split('&');
  let query_string = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === 'undefined') {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === 'string') {
      let arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
};

export const parseQueryStringKeyValue = (query) => {
  let vars = query.split('&');
  let params = [];
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    params = [...params, { headerKeyName: key, headerValue: value }];
  }
  return params;
};

export const parseJsonToURL = (url, obj) => {
  if (url) {
    let requestURL = url.split('?');
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return requestURL[0] + '?' + str.join('&');
  }
  return '';
};

export const parseKeyValueToURL = (url, index, newKey, newValue) => {
  if (url) {
    let requestURL = url.split('?');
    let headersArr = parseQueryStringKeyValue(requestURL[1]);
    if (newKey === null) {
      headersArr[index].headerValue = newValue;
    } else {
      headersArr[index].headerKeyName = newKey;
    }
    let str = [];
    for (let i = 0; i < headersArr.length; i++) {
      str.push(
        encodeURIComponent(headersArr[i].headerKeyName) +
          '=' +
          encodeURIComponent(headersArr[i].headerValue)
      );
    }
    return requestURL[0] + '?' + str.join('&');
  }
  return '';
};

export const getJsonView = (params) => {
  let jsonView = {};
  params.map((param) => {
    jsonView = {
      ...jsonView,
      ...{ [param.headerKeyName]: param.headerValue }
    };
    return param;
  });
  console.log('jsonview', jsonView);
  return JSON.stringify(jsonView);
};
