import { combineReducers } from 'redux'
import counter from './modules/counter'
import theme from './modules/theme'
import global from './modules/global'
import webServices from './WebServices/reducers/webServiceReducer'
import environment from './Environments/reducers/environmentReducer'
import customerServices from './CustomerApplications/reducers/CustomerApplicationsReducer'
import businessFeatures from './BusinessFeatures/reducers/bussinessFeaturesReducers'
import businessInitiative from './BusinessInitiative/reducers/businessInitiativeReducer'
import businessCapability from './BusinessCapability/reducers/bussinessCapabilityReducers'
import businessEpics from './BusinessEpics/reducers/BusinessEpicsReducer'
import businessComponent from './BusinessComponents/reducers/BusinessComponentsReducer'



import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  counter,
  theme,
  global,
  webServices,
  environment,
  customerServices,
  businessFeatures,
  businessInitiative,
  businessCapability,
  businessEpics,
  businessComponent
})

export default createRootReducer
