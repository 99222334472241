import { apiUrl } from '../../settings';

export const getAllBusinessComponentURL =  () => {
  return apiUrl + "getAllBusinessComponentsForApp?applicationId="
};
export const getDeleteBusinessComponentURL =  () => {
  return apiUrl + "deleteBusinessComponent?businessComponentId=";
};

export const addNewBusinessComponent =  () => {
  return apiUrl + "addNewBusinessComponent";
};

export const updateBusinessComponentUrl =  () => {
  return apiUrl + "updateBusinessComponent";
};
export const getBusinessComponentURL =  () => {
  return apiUrl + "getBusinessComponent?businessComponentId="
};


