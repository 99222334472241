import React from 'react'

import { v4 as uuid } from 'uuid'

let data=[
    {
        "creationDate": "2020-05-25T04:48:40.888Z",
        "epicDescription": "Description",
        "epicName": "Epic 1",
        "expiryDate": "2020-05-25T04:48:40.888Z",
        "id": `${uuid()}`,
        "jiraEpicId": `${uuid()}`,
        "jiraEpicUrl": "epic/url"
    },
    {
        "creationDate": "2020-05-26T04:48:40.888Z",
        "epicDescription": "Description",
        "epicName": "Epic 2",
        "expiryDate": "2020-05-25T04:48:40.888Z",
        "id": `${uuid()}`,
        "jiraEpicId": `${uuid()}`,
        "jiraEpicUrl": "epic/url"
    },
    {
        "creationDate": "2020-05-21T04:48:40.888Z",
        "epicDescription": "Description",
        "epicName": "Epic 3",
        "expiryDate": "2020-05-25T04:48:40.888Z",
        "id": `${uuid()}`,
        "jiraEpicId": `${uuid()}`,
        "jiraEpicUrl": "epic/url"
    },
    {
        "creationDate": "2020-05-22T04:48:40.888Z",
        "epicDescription": "Description",
        "epicName": "Epic 4",
        "expiryDate": "2020-05-25T04:48:40.888Z",
        "id": `${uuid()}`,
        "jiraEpicId": `${uuid()}`,
        "jiraEpicUrl": "epic/url"
    },
    {
        "creationDate": "2020-05-24T04:48:40.888Z",
        "epicDescription": "Description",
        "epicName": "Epic 5",
        "expiryDate": "2020-05-25T04:48:40.888Z",
        "id": `${uuid()}`,
        "jiraEpicId": `${uuid()}`,
        "jiraEpicUrl": "epic/url"
    }
]
export default data