export default (
  state = {
    environmentLoader: false,
    response: {},
    metaData: {},
    selectedEnvironment: {},
    environmentList: [],
    environmentHistory: {},
    selectedWebService: {},
    webServiceInstances: {},
    webServiceHistory: {}
  },
  action
) => {
  switch (action.type) {
    case 'ENVIRONMENT_RESPONSE_RECVD':
      return { ...state, response: action.payload };
    case 'ENVIRONMENT_META_DATA':
      return { ...state, metaData: action.payload };
    case 'ENVIRONMENT_LIST':
      return { ...state, environmentList: action.payload };
    case 'ENVIRONMENT_HISTORY':
      return { ...state, environmentHistory: action.payload };
    case 'ENVIRONMENT_SHOW_LOADER':
      return { ...state, environmentLoader: true };
    case 'ENVIRONMENT_HIDE_LOADER':
      return { ...state, environmentLoader: false };
    case 'CLEAR_RESPONSE':
      return { ...state, response: {} };
    case 'ENVIRONMENT_META_DATA':
      return { ...state, metaData: action.payload };
    case 'ENVIRONMENT_INSTANCES':
      return { ...state, webServiceInstances: action.payload };
    default:
      return state;
  }
};
