import { apiUrl } from '../../settings';

//const apiUrl = "http://localhost:8080/rmi-webservices-api/";
// const apiUrl = "https://107.191.45.180:4443/rmi-webservices-api/";
// const apiUrl = "http://107.191.45.180:8080/rmi-webservices-api/";

export const getMetaDataURL = () => {
  return apiUrl + 'getMetaData';
};

export const getWebServiceListURL = (clientId) => {
  return apiUrl + 'getWSListForClient?clientId=' + clientId;
};

export const createWebServiceURL = () => {
  return apiUrl + 'createWebService';
};

export const updateWebServiceURL = () => {
  return apiUrl + 'updateWebService';
};

export const deleteWebServiceURL = () => {
  return apiUrl + 'deleteWebService?rmiWSInfoId=';
};

export const getWebServiceInstancesURL = (id) => {
  return apiUrl + 'getWSListForEnv?rmiWSInfoId=' + id;
};

export const getWSRequestHistoryForEnvURL = (id) => {
  return apiUrl + 'getWSRequestHistoryForEnv?rmiWSInfoId=' + id;
};

export const testWebServiceURL = () => {
  return apiUrl + 'testWebService';
};

export const saveWebServiceURL = () => {
  return apiUrl + 'saveWebService';
};

export const deleteEnvWebServiceURL = (id) => {
  return apiUrl + 'deleteEnvWebService?envId=' + id;
};

export const deleteWebServiceRequestResponseURL = () => {
  return apiUrl + 'deleteWebServiceRequestResponse';
};

export const deleteRequestsURL = () => {
  return apiUrl + 'deleteRequests';
};

export const uploadBulkRequestURL = () => {
  return apiUrl + 'uploadBulkRequest';
};
