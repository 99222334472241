import React, { useEffect } from 'react';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import { apiUrl } from '../../settings';
import { Form, Card, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { addBusinessInitiative } from '../actions/businessInitiativeAction';
import { formItemLayout } from '../../modules/utils';
import { v4 as uuid } from 'uuid'
import moment from 'moment'


const businessInitiativeAdd = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { loading, addedSuccessFully } = useSelector(
        (state) => ({
            loading: state.businessInitiative.initiativeLoader,
            addedSuccessFully: state.businessInitiative.addedSuccessFully

        })
    );

    const inputFields = {
        fields: [
            {
                label: 'Actual End Date',
                key: 'actualEndDt',
                type: 'date',
                required: true,
            },
            {
                label: 'Expiry Date',
                key: 'expiryDate',
                type: 'date',
                required: true,
            },
            {
                label: 'Inception Date',
                key: 'inceptionDt',
                type: 'date',
                required: true,
            },
            {
                label: 'Initiative Elevator Statement',
                key: 'initiativeElevatorStatement',
                type: 'string',
                required: true,
            },
            {
                label: 'Initiative Name',
                key: 'initiativeName',
                type: 'string',
                required: true,
            },
            {
                label: 'Funded',
                key: 'isFunded',
                type: 'switch',
                onChange: (value) => {
                    form.setFieldsValue({ 'isFunded': value })
                }
            },
            {
                label: 'Planned End Date',
                key: 'plannedEndDt',
                type: 'date',
                required: true,
            },
            {
                label: 'Total Approved Funding Amount',
                key: 'totalApprovedFundingAmount',
                type: 'number',
                required: true,
            },
            {
                label: 'Total Requested Funding Amount',
                key: 'totalFundingRequestAmount',
                type: 'number',
                required: true,
            }
        ]
    }

    useEffect(() => {
        if (addedSuccessFully) {
            form.resetFields()
        }
    }, [addedSuccessFully])
    const onFinish = (values) => {
        values.id = uuid();
        values.expiryDate=moment(values.expiryDate).toISOString()
        values.clientId = 1
        dispatch(addBusinessInitiative(values))

    }
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Card title={'Add Business Initiative'} bordered={true}>
            <Form size={'medium'} form={form} {...formItemLayout} onFinish={onFinish}>
                <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={inputFields}
                />
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                </Button>
                </Form.Item>
            </Form>

        </Card>
    );
};

export default businessInitiativeAdd;