import React, { useState, useEffect, useCallback } from 'react';
import { Select, Button, Card, Table, Spin, Switch, Form, Tabs } from 'antd';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { notification } from 'antd/lib';
import { useDispatch, useSelector } from 'react-redux';
import { FormUtils as GetAllFormFields } from 'sz-react-utils4';
import {
  EditableCell,
  EditableFormRow
} from '../../components/_utils/editableTable';
import { useLocation } from 'react-router-dom';
import {
  getMetaData,
  getWebServiceList,
  updateWebService
} from '../actions/WebServiceActions';
import { apiUrl } from '../../settings';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { rmiWSAuthenticationInfos } from './sampleWebServiceJson.js';
const { Option } = Select;
const { TabPane } = Tabs;
const clientId = '1';
const addWebService = () => {
  const [state, setState] = useState({
    searchText: '',
    description: 'Description',
    operationTypeOptions: [],
    bodyTypeOptions: [],
    checkField: '',
    contentTypeOptions: [],
    authTypeOptions: [],
    searchLabelOptions: [],
    dataSource: [
      {
        headerKey: 'Content-Type',
        headerDefaultValue: 'application/json',
        editable: false,
        required: true,
        id: '1'
      },
      {
        headerKey: 'Accept-Encoding',
        headerDefaultValue: 'gzip, deflate',
        editable: false,
        required: true,
        id: '2'
      },
      {
        headerKey: 'Accept',
        headerDefaultValue: 'application/json',
        editable: false,
        required: true,
        id: '3'
      },
      {
        headerKey: 'Accept-Charset',
        headerDefaultValue: 'utf-8',
        editable: false,
        required: true,
        id: '4'
      },
      {
        headerKey: 'Cache-Control',
        headerDefaultValue: 'no-cache',
        editable: false,
        required: true,
        id: '5'
      },
      {
        headerKey: 'Connection',
        headerDefaultValue: 'keep-alive',
        editable: false,
        required: true,
        id: '6'
      }
    ]
  });
  const [bodyFieldCheck, fieldUpdate] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const paramsId = location.pathname.split('/')[2];
  const { loading, metaData, webServiceList } = useSelector((state) => ({
    loading: state.webServices.webServiceLoader,
    successValidator: state.webServices.successfullyAddedService,
    webServiceList: state.webServices.webServiceList,
    metaData: state.webServices.metaData
  }));
  const [form] = Form.useForm();
  console.log(
    'paramis',
    paramsId,
    webServiceList,
    webServiceList.find((element) => element.id === paramsId)
  );

  let WebServiceDefData = {};
  useEffect(() => {
    dispatch(getMetaData());

    if (paramsId && !webServiceList.length) {
      dispatch(getWebServiceList(1));
    }
  }, [location]);

  const setFormValues = (WebServiceDefData) => {
    console.log('setForm', WebServiceDefData);
  };

  if (paramsId && webServiceList.length) {
    WebServiceDefData = webServiceList.find(
      (element) => element.id === paramsId
    );
    console.log(
      'WebServiceDefData',
      WebServiceDefData,
      webServiceList.find((element) => element.id === paramsId)
    );
    const { wsName, rmiWSContentType, wsDescription } = WebServiceDefData;
    console.log('rmiWSContentType', rmiWSContentType);
    form.setFieldsValue({
      wsName,
      rmiWSContentType: rmiWSContentType.contentTypeName,
      wsDescription
    });
    form.setFieldsValue({
      rmiWSContentType: rmiWSContentType.contentTypeName
    });
  }

  useEffect(() => {
    if (metaData && metaData.operationTypes) {
      let options = {
        operationTypeOptions: metaData.operationTypes,
        bodyTypeOptions: metaData.bodyTypes,
        contentTypeOptions: metaData.contentTypes,
        authTypeOptions: metaData.authenticationTypes
      };
      setState({ ...state, ...options });
    }
  }, [metaData]);

  const extractObject = (array, find = { key: '', value: '' }) => {
    const found = _.find(array, (val) => val[find.key] == find.value);
    if (!found) {
      return null;
    }
    return found;
  };
  const handleSubmit = async (valData) => {
    const {
      dataSource,
      description,
      bodyTypeOptions,
      operationTypeOptions,
      contentTypeOptions,
      authTypeOptions,
      searchLabelOptions
    } = state;
    let isHeaders = false;
    dataSource.forEach((item) => {
      if (item.headerKey == '' || item.headerDefaultValue == '') {
        isHeaders = true;
        return;
      }
    });
    if (isHeaders) {
      notification.error({
        message: 'Invalid Headers'
      });
      return;
    }
    valData.clientId = clientId;
    valData.id = uuid();
    valData.rmiWSAuthenticationInfos = rmiWSAuthenticationInfos.filter(
      (item) =>
        valData.rmiWSAuthenticationInfos.findIndex((val) => val == item.id) > -1
    );
    valData.rmiWSAuthenticationType = extractObject(authTypeOptions, {
      key: 'authenticationTypeName',
      value: valData.rmiWSAuthenticationType
    });
    valData.rmiWSBodyType = valData.rmiWSBodyType
      ? extractObject(bodyTypeOptions, {
          key: 'bodyTypeName',
          value: valData.rmiWSBodyType
        })
      : { bodyTypeName: 'none', id: '8' };
    valData.rmiWSContentType = extractObject(contentTypeOptions, {
      key: 'contentTypeName',
      value: valData.rmiWSContentType
    });
    valData.rmiWSHeaderInfos = dataSource;
    valData.rmiWSOperationType = extractObject(operationTypeOptions, {
      key: 'operationName',
      value: valData.rmiWSOperationType
    });
    valData.wsDescription = description == 'description' ? '' : description;
    dispatch(updateWebService(valData));

    // setState({...state,description:'Description'})
  };

  const handleDelete = (key) => {
    let dataSource = [...state.dataSource];
    dataSource = dataSource.filter((item) => item.id !== key);
    dataSource.forEach((item, index) => {
      item.id = `${index + 1}`;
    });
    setState({
      ...state,
      dataSource: dataSource
    });
  };

  const handleTableEdit = (row) => {
    let dataSource = [...state.dataSource];
    const index = dataSource.findIndex((item) => row.id === item.id);
    const item = dataSource[index];
    dataSource.splice(index, 1, {
      ...item,
      ...row
    });
    setState({ ...state, dataSource: [...dataSource] });
  };
  const addNewDataSource = () => {
    const dataSource = [...state.dataSource];
    dataSource.push({
      headerKey: 'Key',
      headerDefaultValue: 'value',
      editable: false,
      required: true,
      id: `${dataSource.length + 1}`
    });
    setState({ ...state, dataSource: dataSource });
  };

  const generalLeftInputTypes = {
    fields: [
      {
        label: 'Web Service Name',
        key: 'wsName',
        required: true
      },
      {
        label: 'Content Type',
        key: 'rmiWSContentType',
        type: 'select',
        required: true,
        keyAccessor: (x) => x.contentTypeName,
        valueAccessor: (x) => `${x.contentTypeName}`,
        options: state.contentTypeOptions,
        onChange: (rmiWSContentType) => {
          console.log('rmiWSContentType', rmiWSContentType);
          form.setFieldsValue({ rmiWSContentType });
        }
      },
      {
        label: 'Operation Type',
        key: 'rmiWSOperationType',
        name: 'rmiWSOperationType',
        required: true,
        type: 'select',
        keyAccessor: (x) => x.operationName,
        valueAccessor: (x) => `${x.operationName}`,
        options: state.operationTypeOptions,
        onChange: (rmiWSOperationType) => {
          form.setFieldsValue({ rmiWSOperationType });
          if (rmiWSOperationType == 'GET') {
            fieldUpdate(true);
            form.setFieldsValue({ rmiWSBodyType: 'application/json' });
          } else {
            form.setFieldsValue({ rmiWSBodyType: '' });
            fieldUpdate(false);
          }
        }
      }
    ]
  };
  const generalRightInputTypes = {
    fields: [
      {
        label: 'Document Links',
        key: 'documentLinks',
        type: 'select',
        mode: 'tags',
        tokenSeparators: [','],
        options: [],
        required: true,
        onChange: (documentLinks) => {
          form.setFieldsValue({ documentLinks });
        }
      },
      {
        label: 'Authentication Type',
        key: 'rmiWSAuthenticationType',
        type: 'select',
        required: true,
        keyAccessor: (x) => x.authenticationTypeName,
        valueAccessor: (x) => `${x.authenticationTypeName}`,
        options: state.authTypeOptions,
        onChange: (rmiWSAuthenticationType) => {
          form.setFieldsValue({ rmiWSAuthenticationType });
        }
      },
      {
        label: 'Supported Feature Id',
        key: 'supportedFeatureIdList',
        type: 'select',
        mode: 'multiple',
        required: true,
        keyAccessor: (x) => x,
        valueAccessor: (x) => `${x}`,
        options: [
          'Email Alerts',
          'SMS Alerts',
          'Discount Enroll',
          'Discount Unenroll',
          'Other'
        ],
        onChange: (supportedFeatureIdList) => {
          form.setFieldsValue({ supportedFeatureIdList });
        }
      },
      {
        label: 'Rmi AuthenticationInfos',
        key: 'rmiWSAuthenticationInfos',
        mode: 'multiple',
        required: true,
        type: 'select',
        options: _.map(rmiWSAuthenticationInfos, (item) => ({
          id: item.id,
          display: item.authenticationKey
        })),
        onChange: (rmiWSAuthenticationInfos) => {
          form.setFieldsValue({ rmiWSAuthenticationInfos });
        }
      }
    ]
  };

  const headerColumns = [
    {
      title: 'Key',
      key: 'headerKey',
      dataIndex: 'headerKey',
      width: '20%',
      editable: true
    },
    {
      title: 'Default Value',
      key: 'headerDefaultValue',
      dataIndex: 'headerDefaultValue',
      width: '20%',
      editable: true
    },
    {
      title: 'Editable',
      key: 'editable',
      dataIndex: 'editable',
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(value) => {
              record.editable = value;
              handleTableEdit(record);
            }}
          />
        );
      }
    },
    {
      title: 'Required',
      key: 'required',
      dataIndex: 'required',
      render: (val, record) => {
        return (
          <Switch
            checked={val}
            onChange={(value) => {
              record.required = value;
              handleTableEdit(record);
            }}
          />
        );
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) =>
        state.dataSource.length >= 1 ? (
          <Button
            shape="circle"
            // size="small"
            icon={<DeleteOutlined />}
            type="danger"
            onClick={() => {
              handleDelete(record.id);
            }}
          />
        ) : // <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
        // </Popconfirm>
        null
    }
  ];

  const columns = headerColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleTableEdit
      })
    };
  });

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 12 }
    }
  };
  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  };

  return (
    <Card title="Update Web Service">
      <Form
        form={form}
        name="control-hooks"
        onFinish={handleSubmit}
        {...formItemLayout}
        onFinishFailed={() => {
          notification.error({
            message: 'Fill All Required Fields'
          });
          setState({ ...state, checkField: true });
        }}>
        <Spin spinning={false} delay={500} tip={'Loading...'}>
          <Tabs type="card">
            <TabPane
              tab={
                <div style={{ color: state.checkField ? 'red' : '' }}>
                  General
                </div>
              }
              key="1">
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '2%',
                  justifyContent: 'space-around'
                }}>
                <div style={{ width: '50%' }}>
                  <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={generalLeftInputTypes}
                    formItemLayout={formItemLayout}
                  />

                  {!bodyFieldCheck && (
                    <Form.Item
                      name="rmiWSBodyType"
                      key="rmiWSBodyType"
                      label="Body Type"
                      rules={[{ required: true }]}>
                      <Select
                        placeholder="Body Type"
                        keyAccessor={(x) => x.bodyTypeName}
                        valueAccessor={(x) => `${x.bodyTypeName}`}
                        onChange={(rmiWSBodyType) => {
                          form.setFieldsValue({ rmiWSBodyType });
                        }}
                        allowClear>
                        {state.bodyTypeOptions &&
                          state.bodyTypeOptions.map((d) => {
                            return (
                              <Option value={d.bodyTypeName} id={d.id}>
                                {d.bodyTypeName}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
                </div>
                <div style={{ width: '50%' }}>
                  <GetAllFormFields
                    apiurl={apiUrl}
                    inputSchema={generalRightInputTypes}
                    formItemLayout={formItemLayout}
                  />
                </div>
              </div>

              <Table
                id={'headers-table'}
                title={() => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                    <div>Headers</div>
                    <Button
                      type="default"
                      onClick={() => {
                        addNewDataSource();
                      }}>
                      Add a Row
                    </Button>
                  </div>
                )}
                components={components}
                rowClassName={() => 'editable-row'}
                rowKey={(record) => record.id}
                bordered
                pagination={false}
                loading={false}
                dataSource={state.dataSource}
                columns={columns}
              />
            </TabPane>

            <TabPane tab="Documentation" key="2">
              <ReactQuill
                theme="snow"
                style={{ height: '200px', marginTop: '2%', marginBottom: '5%' }}
                value={state.description}
                onChange={(value) => {
                  setState({ ...state, description: value });
                }}
              />
              <Form.Item {...submitFormLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  UPDATE
                </Button>
              </Form.Item>
            </TabPane>
          </Tabs>
        </Spin>
      </Form>
    </Card>
  );
};

export default addWebService;
