import React, { useEffect, useState } from 'react'
import Highlighter from 'react-highlight-words'
import {
    Button,
    Input,
    Form,
    Drawer,
    Card,
    Tag,
    List,
    Menu,
    Dropdown
} from 'antd'
import {
    SettingOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import _ from 'lodash'
import { timeStampFormat, dateFormat } from '../../settings'
import moment from 'moment'
import styles from './styles.less'
import 'ant-design-pro/dist/ant-design-pro.css' // Import whole style
import { apiUrl } from '../../settings'
import { formItemLayout } from '../../modules/utils'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils4'
import { getPushPathWrapper } from '../../routes'
import { useSelector, useDispatch } from 'react-redux';
import { getBusinessCapabilityList } from '../../BusinessCapability/actions/businessCapabilityActions'
import { getCustomerApplicationListData } from '../../CustomerApplications/actions/CustomerApplicationsListAction'
import { getWebServiceList } from '../../WebServices/actions/WebServiceActions'

const BusinessApplication = (props) => {
    const dispatch = useDispatch()
    const [visible, setState] = useState(false)
    const [form] = Form.useForm();

    const showDrawer = () => {
        setState(true)
    }
    const onClose = () => {
        setState(false)
    }
    const onFinish = (valData) => {
        console.log(valData.associateCapabilityName)

        valData.associateApplicationName = candidateApplicationDataList.filter(item => valData.associateApplicationName.findIndex((val) => val == item.id) > -1)

        valData.associateWebserviceName = webServiceList.filter(item => valData.associateWebserviceName.findIndex((val) => val == item.id) > -1)

        valData.associateComponentName = webServiceList.filter(item => valData.associateComponentName.findIndex((val) => val == item.id) > -1)

        console.log(valData,candidateApplicationDataList)

    }
    const { loader, candidateApplicationDataList, webServiceList } = useSelector((state) => ({
        loader: state.businessFeatures.businessFeatureLoader,
        candidateApplicationDataList: state.customerServices.candidateApplicationDataList,
        webServiceList: state.webServices.webServiceList


    }))
    useEffect(() => {
        dispatch(getCustomerApplicationListData())
        dispatch(getWebServiceList())
    }, [])

    const epicsColoumn = [
        {
            label: 'Epic Name',
            key: 'epicName',
            dataIndex: 'epicName'
        },
        {
            label: 'Associated User Stories',
            key: 'associatedUserStories',
            dataIndex: 'associatedUserStories'
        },
        {
            label: 'Project Name',
            key: 'projectName',
            dataIndex: 'projectName'
        },
        {
            label: 'Status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            label: 'Associated Dependent On Features',
            key: 'associatedDependentOnFeatures',
            dataIndex: 'associatedDependentOnFeatures'
        },
        {
            label: 'Associated Dependent On User Stories',
            key: 'associatedDependentOnUserStories',
            dataIndex: 'associatedDependentOnUserStories'
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="0" onClick={() => {
                            // dispatch(
                            //   getPushPathWrapper('updateBusinessFeatures', { id: record.id })
                            // )

                        }}>
                            Disassociate Epics
                  </Menu.Item>
                        <Menu.Divider />
                    </Menu>
                )
                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <SettingOutlined />
                    </Dropdown>
                )
            }
            // fixed: 'right'
        }


    ]
    const applicationColoumnDrawer = [
        {
            label: 'Epic Name',
            key: 'epicName',
            dataIndex: 'epicName'
        },
        {
            label: 'Associated User Stories',
            key: 'associatedUserStories',
            dataIndex: 'associatedUserStories',
           
        },
        {
            label: 'Associated Web Services',
            key: 'associatedMiniWebSeriveList',
            dataIndex: 'associatedMiniWebSeriveList',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.wsName}

                            />
                        </List.Item>
                    )}
                />
            }
        },
        {
            label: 'Associated Components',
            key: 'associatedComponents',
            dataIndex: 'associatedComponents',
            render: (record) => {
                return record && record.length > 0 && <List
                    bordered
                    size='small'
                    dataSource={record}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.componentName}

                            />
                        </List.Item>
                    )}
                />
            }
        },

    ]
    const inputFields = {
        fields: [
            
            {

                label: 'Feature Name',
                key: 'featureName',
                type: 'string',
                disable: true,
                onChange: () =>{
                    form.setFieldsValue({featureName: props.featureName})
                }

            },
            {

                label: 'Feature Description',
                key: 'featureDescription',
                type: 'string',
                disable: true,
                onChange: () =>{
                    form.setFieldsValue({featureName: props.featureName})
                }

            },
            
            {
                label: 'Associate Features/Epics',
                key: 'associateFeatureName',
                dataIndex:'associateFeatureName',
                type: 'select',
                mode:'multiple',
                options: _.map(candidateApplicationDataList, (item) => ({ id: item.id, display: item.applicationName })),
                onChange: (associateFeatureName) => {
                    console.log("called",associateFeatureName, form.getFieldsValue({associateFeatureName}))
                    form.setFieldsValue({ associateFeatureName })
                }

            },
           
        ]
    }
    const submitFormLayout = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 10, offset: 7 },
            md: { span: 12, offset: 8 }
        }
    }
    return (
        <Card
            id={'associatedEpicList'}
            loading={loader}
            title={'Associative Epic List'} extra={<Button type='primary' onClick={showDrawer}>{'Associate other Epics'}</Button>}
            style={{marginBottom:'25px'}}
        >
            <TableComp
                columns={epicsColoumn}
                bordered
                dataSource={props.associatedBusinessEpics}
                loading={loader}

            />
            <Drawer
                title={'Associate Other Epics'}
                placement={'right'}
                width={520}
                closable={false}
                onClose={onClose}
                visible={visible}
                key={'right'}
            >
                <Form {...formItemLayout} onFinish={onFinish} form={form}>
                    <GetAllFormFields
                        apiUrl={apiUrl}
                        inputSchema={inputFields}
                    />
                    <Form.Item {...submitFormLayout}>
                        <Button loading={loader} type='primary' htmlType='submit'>Associate</Button>
                    </Form.Item>
                </Form>
                <TableComp
                    columns={epicsColoumn}
                    bordered
                    dataSource={props.associatedBusinessEpics}
                    loading={loader}

                />
            </Drawer>


        </Card>
    )
}
export default BusinessApplication
